import React from "react";
import styles from "./unauthorized.module.scss";
import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <img className={styles.image} src="./752733.png" alt="" srcset="" />
        <h1 className={styles.heading}>403 Access Forbidden</h1>
        <p className={styles.para}>Sorry, you don't have permission to access this page.</p>
        <Link to={'http://hmcatest.dealer-portal-ap.net:51000/'} className={styles.button}>Go Back To Homepage</Link>
      </div>
    </div>
  );
};

export default Unauthorized;
