import { useEffect, useState } from "react";
import { webService } from "../../Services/web.service";

const useGetUser = () => {
  const [user, setUser] = useState({});
  const [dealer, setDealer] = useState({});


  useEffect(() => {
    webService.get("user").then((response) => {
      setUser(response?.data?.user);
      setDealer(response?.data?.dealership);
    });
  }, []);

  return {user, dealer};
};

export default useGetUser;
