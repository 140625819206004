import React, { useEffect, useState, useRef, useContext } from "react";
import "../../Assets/Styles/global-styles.css";
import CommonTable from "../../Components/Table";
import {
  CaretDownOutlined,
  SearchOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import CustomizedButton from "../../Components/Button";
import Upload from "../../Assets/Images/Upload.svg";
import {
  importFilesApi,
  rebateAllowanceApi,
  updateRebateAllowance,
} from "../../Services/userEndpoints";
import { Typography, Select, Form, Col, Row, Input, Pagination } from "antd";
import "../../Assets/Styles/fonts.css";
import CommonMessage, { message } from "../../Components/Message";
import { DateRangePicker } from "../../Components/Datepicker";
import { paginationConfig } from "../../Components/Pagination";
import { AllowanceRegionComponent } from "../../Constants/commonRegion";
import { useFormData } from "../../Constants/commonFormdata";
import { QuoteContext } from "../Quote-Calculator/quoteContext";
import CommonModal from "../../Components/Modal";
import { commonSorter, render, renderDollar } from "../../Constants/constants";
import ImportRebate from "../../Components/Modal/ImportRebate/ImportRebate";
import { webService } from "../../Services/web.service";
import store from "../../store/store";
import { isExecuting } from "../../store/isHTTPRequestSlice";
import { monthConverter } from "../../Constants/dateConverter";
const { Title } = Typography;

const RebateAllowance = () => {
  const fileInputRef = useRef(null);
  const [pageSize, setPageSize] = useState(paginationConfig?.defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const { allowanceRegion, dealershipDetails } = useContext(QuoteContext);
  const [isSaveModalVisible, setisSaveModalVisible] = useState(false);
  const [rebateRetailList, setRebateRetailList] = useState([]);
  const [importedFile, setImportedFile] = useState(null);
  const [isImportPartsModalVisible, setIsImportPartsModalVisible] =
    useState(false);
  const {
    formData,
    isAnyDropdownSelected,
    searchState,
    handleonChange,
    handleSearch,
    setIsAnyDropdownSelected,
    setFormData,
    handleDropdownVisibleChange,
    setSelectedDates,
    selectedDates,
    handleChange,
  } = useFormData();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingValues, setEditingValues] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleEditClick = (id, record) => {
    setEditingRowId(id);
    setEditingValues({
      rebateAllowance: record.rebateAllowance,
      additionalRebateAllowance: record.additionalRebateAllowance,
    });
    setSelectedRecord(record);
  };

  const handleCancelClick = () => {
    setEditingRowId(null);
    setEditingValues({});
    setSelectedRecord(null);
  };

  const handleSaveClick = () => {
    setisSaveModalVisible(true);
  };

  const handleInputChange = (e, fieldName) => {
    setEditingValues({
      ...editingValues,
      [fieldName]: e.target.value,
    });
  };

  const columns = [
    {
      title: "Year",
      dataIndex: "allowanceYear",
      key: "allowanceYear",
      align: "left",
      width: "100px",
      className: "table-sorter font",
      sorter: commonSorter("allowanceYear"),
      render: render,
    },
    {
      title: "Month",
      dataIndex: "allowanceMonth",
      key: "allowanceMonth",
      align: "left",
      width: "130px",
      className: "table-sorter font",
      sorter: commonSorter("allowanceMonth"),
      render: (monthNumber) => monthConverter(monthNumber),
    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      key: "code",
      align: "left",
      width: "146px",
      className: "table-sorter font",
      sorter: commonSorter("code"),
      render: render,
    },
    {
      title: "Dealership Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "259px",
      className: "table-sorter font",
      sorter: commonSorter("name"),
      render: render,
    },
    {
      title: "Rebate Allowance",
      dataIndex: "rebateAllowance",
      key: "rebateAllowance",
      align: "right",
      width: "179px",
      className: "table-sorter font",
      sorter: commonSorter("rebateAllowance"),
      render: (text, record) => {
        return editingRowId === record.id ? (
          <Input
            value={editingValues.rebateAllowance}
            onChange={(e) => handleInputChange(e, "rebateAllowance")}
          />
        ) : (
          `$${record.rebateAllowance}`
        );
      },
    },
    {
      title: "Additional Allowance ",
      dataIndex: "additionalRebateAllowance",
      key: "additionalRebateAllowance",
      align: "right",
      width: "170px",
      className: "table-sorter font",
      sorter: commonSorter("additionalRebateAllowance"),
      render: (text, record) => {
        return editingRowId === record.id ? (
          <Input
            value={editingValues.additionalRebateAllowance}
            onChange={(e) => handleInputChange(e, "additionalRebateAllowance")}
          />
        ) : (
          `$${record.additionalRebateAllowance}`
        );
      },
    },
    {
      title: "Total Allowance ",
      dataIndex: "totalAllowance",
      key: "totalAllowance",
      align: "right",
      width: "170px",
      className: "table-sorter font",
      sorter: commonSorter("totalAllowance"),
      render: renderDollar,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: "119px",
      className: "table-sorter font",
      render: (_, record) => {
        return editingRowId === record.id ? (
          <span className="quote-action-btn">
            <CustomizedButton type="danger" onClick={handleCancelClick}>
              <Typography.Link>
                <CloseOutlined className="closeIcon" />
              </Typography.Link>
            </CustomizedButton>
            <CustomizedButton
              type="danger"
              onClick={() => {
                handleSaveClick();
              }}
            >
              <Typography.Link>
                <CheckOutlined className="checkIcon" />
              </Typography.Link>
            </CustomizedButton>
          </span>
        ) : (
          <>
            <span className="quote-action-btn">
              <CustomizedButton
                type="danger"
                onClick={() => {
                  handleEditClick(record.id, record);
                }}
              >
                <Typography.Link>
                  <EditOutlined className="quote-edit-btn quote-edit-mar" />
                </Typography.Link>
              </CustomizedButton>
            </span>
          </>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setisSaveModalVisible(false);
  };

  const handleUpdateSingleRow = async () => {
    if (!selectedRecord) return;
    const dealershipId = selectedRecord?.id;
    const payload = {
      rebateAllowance: editingValues.rebateAllowance,
      additionalRebateAllowance: editingValues.additionalRebateAllowance,
    };

    webService
      .put(`${updateRebateAllowance}/${dealershipId}`, payload)
      .then((response) => {
        message.success(response.message);
        setisSaveModalVisible(false);
        setEditingRowId(null);
        setEditingValues({});
        getRebateAllowance();
      })
      .catch((e) => {
        console.error(e)
      });
  };

  useEffect(() => {
    if (!isAnyDropdownSelected) {
      // getRebateAllowance();
    } else {
      filterRebateAllowance();
    }
  }, [pageSize, currentPage]);

  const handleFilterNewReport = async () => {
    currentPage > 1 ? setCurrentPage(1) : filterRebateAllowance();
  };

  // Fetch Data
  const fetchRebateAllowance = async (payload) => {
    try {
      store.dispatch(isExecuting(true));
      webService
        .post(rebateAllowanceApi, payload)
        .then((response) => {
          const data = response.data || {};
          const rebateRetail = data.rows || [];
          const totalAllRecords = data.total || 0;
          setRebateRetailList(rebateRetail);
          setTotalRecords(totalAllRecords);
        })
        .catch(() => {
          setRebateRetailList([]);
          setTotalRecords(0);
        }).finally(()=>{
          store.dispatch(isExecuting(false));
        })
    } catch (error) {
      console.error("Error fetching rebate allowance data:", error);
    }
  };

  //  Get List
  const getRebateAllowance = async () => {
    const payload = generatePayload(selectedDates, true);
    await fetchRebateAllowance(payload);
  };
  //  Filter Api
  const filterRebateAllowance = async () => {
    const payload = generatePayload(selectedDates, true);
    await fetchRebateAllowance(payload);
  };

  const generatePayload = (selectedDates, includePageData = false) => {
    const { region, dealershipId } = formData;
    const payload = {};
    const reportTypesId = allowanceRegion.find(
      (report) => report.name === region
    );
    
    if (region) payload.regionId = reportTypesId?.id;
    if (dealershipId) payload.dealershipId = dealershipId;
    if (selectedDates && selectedDates[0]) {
      payload.monthYearStart = selectedDates[0].format("MM/YYYY");
    }
    if (selectedDates && selectedDates[1]) {
      payload.monthYearEnd = selectedDates[1].format("MM/YYYY");
    }
    if (includePageData) {
      payload.pageNumber = currentPage;
      payload.pageSize = pageSize;
    }
    return payload;
  };

  // Pagination
  const onShowSizeChange = (current, size) => {
    setCurrentPage(current);
    setPageSize(size);
  };

  const onChange = (page, size) => {
    setEditingRowId(null);
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleClearFilter = () => {
    // if (!isAnyDropdownSelected) {
    //   return;
    // }
    setRebateRetailList([]);
    setTotalRecords(0);
    setSelectedDates([null, null]);
    setFormData({ region: 'Dealer Specific Report',});
    setCurrentPage(1);
    // getRebateAllowance();
    setIsAnyDropdownSelected(false);
  };

  const reportType = allowanceRegion.find(
    (report) => report?.name === formData.region
  );
  const dropdownRender = (menu) => (
    <div>
      <div className=" dropdown-container">
        <p className="dropdown-partnumber-heading">Code</p>
        <p className="dropdown-description-heading">Dealership Name</p>
      </div>
      {menu}
    </div>
  );
  // Inputs
  const handleImportFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setImportedFile(e.target.files[0]);
    setIsImportPartsModalVisible(true);
  };

  const handleHideImportPartsModal = () => {
    setIsImportPartsModalVisible(false);
    fileInputRef.current.value = "";
  };
  const convertToPayload = (data) => {
    return {
      items: data
        .filter((item) => {
          // Check if all required keys have a non-empty, non-null, non-undefined value
          return (
            item.allowanceYear !== undefined &&
            item.allowanceYear !== null &&
            item.allowanceYear !== "" &&
            item.allowanceMonth !== undefined &&
            item.allowanceMonth !== null &&
            item.allowanceMonth !== "" &&
            item.code !== undefined &&
            item.code !== null &&
            item.code !== "" &&
            item.rebateAllowance !== undefined &&
            item.rebateAllowance !== null &&
            item.rebateAllowance !== "" &&
            item.additionalRebateAllowance !== undefined &&
            item.additionalRebateAllowance !== null &&
            item.additionalRebateAllowance !== ""
          );
        })
        .map((item) => {
          return {
            allowanceYear: item.allowanceYear,
            allowanceMonth: item.allowanceMonth,
            dealerCode: item.code,
            rebateAllowance: item.rebateAllowance,
            additionalRebateAllowance: item.additionalRebateAllowance,
          };
        }),
    };
  };
  

  const importRebateAllowance = async (payload) => {
    try {
      // Await the response of the async request
      const response = await webService.post(importFilesApi, payload);
      const data = response?.data || [];
      CommonMessage("success", "Allowance File Imported successfully.");
    } catch (error) {
      // This will catch any errors from the API call
      console.error("Error making API call:", error);
  
      // You can also show an error message if needed
      CommonMessage("error", "Failed to import Allowance File.");
    }
  };

  const handleGetImportedRecords = (data) => {
    const payload = convertToPayload(data);
    importRebateAllowance(payload);
    setTimeout(() => {
      getRebateAllowance();
    }, 1000);
  };
  return (
    <div>
      <div className="main-container">
        <div className="box">
          <div className="row-col-container">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={8}>
                <Title className="customer-id summary-dropdown" level={5}>
                  Report Type
                </Title>
                <Form.Item>
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    value={formData.region}
                    onChange={(value) => handleonChange("region", value)}
                    onSearch={(value) => handleSearch("region", value)}
                    onDropdownVisibleChange={(open) =>
                      handleDropdownVisibleChange("region", open)
                    }
                    suffixIcon={
                      searchState.region.onSearch ? (
                        <SearchOutlined className="dropdown-icon" />
                      ) : (
                        <CaretDownOutlined className="dropdown-icon" />
                      )
                    }
                    placeholder="Select Report Type"
                  >
                    {allowanceRegion?.map((item) => (
                      <Select.Option key={item.id} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Title className="customer-id summary-dropdown" level={5}>
                  Dealer Code / Name
                </Title>
                <Form.Item>
                  {reportType?.name?.trim() === "Dealer Specific Report" ? (
                    <Select
                      showSearch
                      className="custom-select custom-placeholder-text"
                      value={formData.dealershipId}
                      onChange={(value) => {
                        handleonChange("dealershipId", value);
                      }}
                      onSearch={(value) => handleSearch("dealerCode", value)}
                      onDropdownVisibleChange={(open) =>
                        handleDropdownVisibleChange("dealerCode", open)
                      }
                      suffixIcon={
                        searchState.dealerCode.onSearch ? (
                          <SearchOutlined className="dropdown-icon" />
                        ) : (
                          <CaretDownOutlined className="dropdown-icon" />
                        )
                      }
                      placeholder="Select Dealer"
                      filterOption={(input, option) => {
                        const code = option.props.code;
                        const name = option.props.name;
                        return (
                          code?.toLowerCase().includes(input?.toLowerCase()) ||
                          name?.toLowerCase().includes(input?.toLowerCase())
                        );
                      }}
                      optionFilterProp="children"
                      dropdownRender={dropdownRender}
                    >
                      {dealershipDetails?.map((item) => (
                        <Select.Option
                          key={item?.id}
                          value={`${item?.id}`}
                          code={item?.code}
                          name={item?.name}
                        >
                          <div className="options">
                            <p>{item?.code}</p>
                            <p className="elipse-width options-description">
                              {item?.name}
                            </p>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      className="input-style"
                      disabled
                      placeholder="Select Dealer"
                    />
                  )}
                </Form.Item>
              </Col>
              {/* <Col className="gutter-row" span={6}>
                <Title className="customer-id summary-dropdown" level={5}>
                  Dealership Name
                </Title>
                <Form.Item>
                  {reportType?.name?.trim() === "Dealer Specific Report" ? (
                    <Select
                      showSearch
                      className="custom-select custom-placeholder-text"
                      value={formData.dealership}
                      onChange={(value) => {
                        const dealerShip = value.split(/-(.*)/)[1];
                        handleonChange("dealership", dealerShip.trim());
                      }}
                      onSearch={(value) => handleSearch("dealership", value)}
                      onDropdownVisibleChange={(open) =>
                        handleDropdownVisibleChange("dealership", open)
                      }
                      suffixIcon={
                        searchState.dealership.onSearch ? (
                          <SearchOutlined className="dropdown-icon" />
                        ) : (
                          <CaretDownOutlined className="dropdown-icon" />
                        )
                      }
                      placeholder="Select Dealership Name"
                      filterOption={(input, option) => {
                        const code = option.props.code;
                        const name = option.props.name;
                        return (
                          code?.toLowerCase().includes(input?.toLowerCase()) ||
                          name?.toLowerCase().includes(input?.toLowerCase())
                        );
                      }}
                      optionFilterProp="children"
                      dropdownRender={dropdownRender}
                    >
                      {dealershipDetails?.map((item) => (
                        <Select.Option
                          key={item?.id}
                          value={`${item?.code}-${item?.name}`}
                          code={item?.code}
                          name={item?.name}
                        >
                          <div className="options">
                            <p>{item?.code}</p>
                            <p className="elipse-width options-description">
                              {item?.name}
                            </p>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      className="input-style"
                      disabled
                      placeholder="Select Dealership Name"
                    />
                  )}
                </Form.Item>
              </Col> */}
              <Col className="gutter-row" span={8}>
                <Title className="customer-id" level={5}>
                  Invoice Period
                </Title>
                <Form.Item className="summary-report">
                  <DateRangePicker
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                    handleChange={handleChange}
                    format="MM/YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="hr-container">
            <div className="quotes-all-list-container">
              <CustomizedButton
                className="reset-btn"
                onClick={handleClearFilter}
              >
                Reset
              </CustomizedButton>
              <CustomizedButton
                type="primary"
                className={
                  isAnyDropdownSelected ? "apply-btn" : "apply-btn-disabled"
                }
                onClick={handleFilterNewReport}
              >
                Apply
              </CustomizedButton>
            </div>
            <hr className="hr-line" />
          </div>
          <div>
            <Title className="quote-items all-quote-list" level={4}></Title>
            <div className="total-container reports-container">
              <div className="total-text">
                Total:
                <span className="item">
                  {totalRecords > 0 && totalRecords < 10
                    ? `0${totalRecords}`
                    : totalRecords}{" "}
                  item
                  {totalRecords > 1 ? "s" : ""}
                </span>
              </div>
              <div className="apply-btn-container">
                <CustomizedButton
                  className="import-file-btn"
                  icon={Upload}
                  onClick={handleImportFile}
                >
                  Import File
                </CustomizedButton>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".xlsx,.xls"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="table-container">
              <CommonTable
                rowKey="id"
                columns={columns}
                dataSource={rebateRetailList.map((item) => ({
                  ...item,
                  key: item.id,
                  allowanceYear: item.allowanceYear ? item.allowanceYear : "-",
                  allowanceMonth: item.allowanceMonth
                    ? item.allowanceMonth
                    : "-",
                  code: item?.dealership?.code ? item.dealership?.code : "-",
                  name: item?.dealership?.name ? item.dealership?.name : "-",
                  rebateAllowance: item.rebateAllowance
                    ? item.rebateAllowance
                    : "-",
                  additionalRebateAllowance: item.additionalRebateAllowance
                    ? item.additionalRebateAllowance
                    : "-",
                  totalAllowance:
                    (item.rebateAllowance
                      ? parseFloat(item.rebateAllowance)
                      : 0) +
                    (item.additionalRebateAllowance
                      ? parseFloat(item.additionalRebateAllowance)
                      : 0),
                }))}
              />
              {/* {totalRecords > 15 && ( */}
                <div className="quote-pagination">
                  <Pagination
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChange}
                    total={totalRecords}
                    className="custom-pagination"
                    current={currentPage}
                    {...paginationConfig}
                  />
                </div>
              {/* )} */}
            </div>
          </div>
          <CommonModal
            visible={isSaveModalVisible}
            onCancel={()=>{setisSaveModalVisible(false);}}
            onConfirm={handleUpdateSingleRow}
            title="Update Changes"
            showUpdateButton={true}
          >
            <p>Update the changes done in part </p>
          </CommonModal>
          <AllowanceRegionComponent />
          <ImportRebate
            isVisible={isImportPartsModalVisible}
            handleClose={handleHideImportPartsModal}
            file={importedFile}
            handleGetImportedRecords={handleGetImportedRecords}
          />
        </div>
      </div>
    </div>
  );
};
export default RebateAllowance;
