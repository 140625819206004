import { configureStore } from "@reduxjs/toolkit";
import quoteItemsSlice from "./quoteItemsSlice";
import isHTTPRequestSlice from "./isHTTPRequestSlice";

export default configureStore({
  reducer: {
    quoteItems: quoteItemsSlice,
    isHTTPRequest: isHTTPRequestSlice,
  },
});
