import React, { useState } from "react";
import CustomizedButton from "../../Components/Button";
import { Typography, Input, Form, Col, Row, Space } from "antd";
import "../../Assets/Styles/global-styles.css";
import "../../Assets/Styles/fonts.css";
import CommonMessage from "../../Components/Message";
import { CheckCircleTwoTone } from "@ant-design/icons";
import {
  getMaxCharsRule,
  noDecimalsRule,
  onHandleNumericValidation,
  requiredRule,
  zeroValueRule,
} from "../../Constants/formValidation";
import PartNumberSelect from "../../Components/PartNumberSelect/PartNumberSelect";

const { Title } = Typography;

const AddPart = ({
  onAddPartData,
  isUpdate,
  setShowAddNewBtn,
  showAddNew,
  setShowAddPart,
  validateForm,
  customerData,
}) => {
  const [partList, setPartList] = useState([]);

  const [selectedPartNumber, setSelectedPartNumber] = useState(null);
  const [partDescriptionValue, setPartDescriptionVaue] = useState(undefined);
  const [quotePrice, setQuotePrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [form] = Form.useForm();
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedPart, setSelectedPart] = useState(null);
  const [isValueEntered, setIsValueEntered] = useState(true);

  const success = () => {
    CommonMessage(
      "success",
      `Part ${selectedPartNumber} added successfully`,
      2,
      () => {
        setShowSuccess(false);
      },
      "custom-success-message"
    );
  };

  const handleChangePartNumber = (e) => {
    const values = e.split("-");
    setSelectedPartNumber(values[1]);
    setPartDescriptionVaue(values[2]);
    form.setFieldsValue({ partNumber: values[1] });
    setIsValueEntered(false);

    const record = partList.find((obj) => {
      return obj.partNumber == values[1];
    });

    setSelectedPart(record);
    form.setFieldsValue({
      description: record.description,
      minimumSellPrice:
        record.partsBaskets.length > 0
          ? record.partsBaskets[0].minimumSellPrice
          : 0,
      stockPrice: record.stockPrice,
    });
  };

  const handleQuantity = (e) => {
    setQuantity(e.target.value);
    setIsValueEntered(false);
  };

  const handleQuotePrice = (e) => {
    setQuotePrice(e.target.value);
    setIsValueEntered(false);
  };

  const handleOnclick = () => {
    if (validateForm !== undefined) {
      validateForm();
    }
  };

  const handleSubmit = (value) => {
    const { description, quantity, quotedSellPrice, partNumber } = value;

    const values = {
      description: description,
      partNumber: partNumber,
      quantity: quantity,
      quotedSellPrice: parseFloat(quotedSellPrice).toFixed(2),
    };

    if (customerData?.customerId !== undefined) {
      onAddPartData({ values, selectedPart });
      success();
      setTimeout(() => {
        setShowSuccess(false);
        form.resetFields();
        setPartDescriptionVaue(undefined);
      }, 2000);
      setIsValueEntered(true);
    } else if (validateForm === undefined) {
      onAddPartData({ values, selectedPart });
      success();
      setTimeout(() => {
        setShowSuccess(false);
        form.resetFields();
        setPartDescriptionVaue(undefined);
      }, 2000);
    }
  };

  const handleReset = () => {
    form.resetFields();
    setPartDescriptionVaue(undefined);
    setIsValueEntered(true);
  };

  return (
    <>
      <Title className="general-info add-new-part" level={5}>
        ADD NEW PART
      </Title>
      <div className="row-col-container">
        <Form form={form} onFinish={handleSubmit}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="newreq">
            <Col className="gutter-row" span={6}>
              <Title className="dealer-code" level={5}>
                Part Number <span className="required-star">*</span>
              </Title>
              <Form.Item
                name="partNumber"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <PartNumberSelect
                  form={form}
                  name={"partNumber"}
                  handleChangePartNumber={handleChangePartNumber}
                  setPartList={setPartList}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Title className="customer-id" level={5}>
                Part Description
              </Title>
              <Form.Item name="description">
                {partDescriptionValue === undefined ? (
                  <Input
                    className="input-style addPart-input-style"
                    value={partDescriptionValue}
                    disabled
                    placeholder="Part Description"
                  />
                ) : (
                  <div
                    className=" custom-tooltip "
                    style={{ width: "calc(100% - 3px)" }}
                  >
                    <div>
                      <Input
                        className="input-style addPart-input-style"
                        value={partDescriptionValue}
                        disabled
                        placeholder="Part Description"
                      />
                      <span className="tooltip-text">
                        {partDescriptionValue}
                      </span>
                    </div>
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Title className="customer-id" level={5}>
                Quantity<span className="required-star">*</span>
              </Title>
              <Form.Item
                name="quantity"
                onChange={handleQuantity}
                rules={[
                  requiredRule,
                  getMaxCharsRule("Quantity", 15),
                  zeroValueRule,
                  noDecimalsRule,
                ]}
              >
                <Input
                  className="addPart-input-styles"
                  value={quantity}
                  onBlur={() => {
                    form.validateFields(["quantity"]);
                  }}
                  onKeyPress={(e) =>
                    onHandleNumericValidation(e, form, "quantity", false)
                  }
                  placeholder="Enter Quantity"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Title className="dealer-code quote-number" level={5}>
                Quoted Price <span className="required-star">*</span>
              </Title>
              <Form.Item
                name="quotedSellPrice"
                onChange={handleQuotePrice}
                rules={[
                  requiredRule,
                  getMaxCharsRule("Quoted Price", 15),
                  zeroValueRule,
                ]}
              >
                <Input
                  placeholder="Enter Quoted Price"
                  className="input-style"
                  value={quotePrice}
                  addonBefore=<span className="dollor-style">$</span>
                  onBlur={() => {
                    form.validateFields(["quotedSellPrice"]);
                  }}
                  onKeyPress={(e) =>
                    onHandleNumericValidation(e, form, "quotedSellPrice", true)
                  }
                  autoComplete="off"
                  style={{
                    width: "100%",
                    borderRadius: "0px",
                  }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row new-part-margin" span={6}>
              <Title className="customer-id" level={5}>
                Base Price
              </Title>
              <Form.Item name="minimumSellPrice">
                <Input
                  className="input-style addPart-input-style"
                  disabled
                  placeholder="Base Price"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row new-part-margin" span={6}>
              <Title className="customer-id" level={5}>
                Stock Order Price
              </Title>
              <Form.Item name="stockPrice">
                <Input
                  className="input-style addPart-input-style"
                  disabled
                  placeholder="Stock Order Price"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}></Col>
            <Col className="gutter-row new-footer-buttons" span={6}>
              <div className="addPart-modal-footer ">
                <Space>
                  {showAddNew && (
                    <CustomizedButton
                      className="reset-btn"
                      onClick={() => {
                        setShowAddPart(false);
                        setShowAddNewBtn(true);
                      }}
                    >
                      CANCEL
                    </CustomizedButton>
                  )}
                  <CustomizedButton className="reset-btn" onClick={handleReset}>
                    RESET
                  </CustomizedButton>
                  <CustomizedButton
                    type="primary"
                    htmlType="submit"
                    className={
                      isValueEntered
                        ? "addpart-btn apply-btn-disabled"
                        : "addpart-btn apply-btn"
                    }
                    onClick={handleOnclick}
                  >
                    Add Part
                  </CustomizedButton>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
        {showSuccess && (
          <div className="addPart-sucess-message">
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              className="addPart-checkCircle-icon"
            />
            <span>
              {isUpdate ? "Part information edited" : "New Part Added"}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default AddPart;
