export const dealershipsCustomers = "dealerships/customers";
export const allQuotesListApi = "quotes";
export const quoteStatusApi = "quotes/quote-statuses";
export const quoteNumberApi = "quotes/number/list";
export const addPart = "parts/";
export const viewquotes = "quotes";
export const exportApi = "quotes/export/";
export const getParts = "parts";
export const updateGenerateQuoteApi = "quotes";
export const financialMetricsApi = "quotes/financial-metrics";
export const generateNewQuoteApi = "quotes";
export const rebateCustomersApi = "/customers/parts-rebate";
export const rebateCustomerDownload = "customers/invoices/download";
export const rebateCustomerInvoices = "customers/invoices";
export const rebateCustomerInvoiceQuotepatrs = "customers/invoices/download";
export const rebateCustomerInvoiceExport = "customers/invoice/download";
export const reportdealerShipsApi = "dealerships";
export const regionsApi = "regions";
export const quoteConversionApi = "reporting/quote-conversion";
export const quoteConversionExport = "reporting/quote-conversion/download";
export const dealerPaymentReportExport = "reporting/dealer/payments/download";
export const rebateDetailApi = "reporting/parts/rebate-detail";
export const rebateDetailExportApi = "reporting/parts/rebate-detail/download";
export const historicSalesDataApi = "quotes/parts-purchase-history";
export const rebateAllowanceApi = "/dealership/allowance/rebate-allowance";
export const updateRebateAllowance = "/dealership/allowance/rebate-allowance";
export const importFilesApi = "/dealership/allowance";
export const customerInformation = "customers";
export const customerStatusList = "customers/status/list";
export const CustomerUnapprovalReason = "customers/unapproval/reasons";
export const reportPartsSummary = "/reporting/parts/rebate-summary";
export const reportPartsSummaryExport = "/reporting/parts/rebate-summary/download";
export const customertypes = "customers/types";
export const partsExport = "parts/export";
export const manageRebateCustomerListColumn = "/user/setting?key=REBATE_CUSTOMER_ADDITIONAL_COLUMNS";
export const importDealerPayment = "reporting/dealer/payments/import";
export const importRebates = "parts/import/rebate";