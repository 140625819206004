import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "antd";
import { QuoteContext } from "../../Pages/Quote-Calculator/quoteContext";

const { Title } = Typography;

const AdminHeader = () => {
  const { editPartId } = useContext(QuoteContext);
  const location = useLocation();
  const editpartPath = `/edit-part/${editPartId}`;
  const headerContent = {
    "/rebate/management": {
      title: "Rebate Management",
      description: "Information of parts rebate",
    },
    [editpartPath]: {
      title: "Rebate Management",
      description: "Information of parts rebate",
    },
    "/rebate/management/customer": {
      title: "Manage Parts Rebate Customers",
      description: "Review the list of trade/non-trade customer",
    },
    "/reporting/parts/rebate/detail": {
      title: "Reporting - Parts Rebate Detail",
      description: "View the detailed report of parts rebate",
    },
    "/reporting/parts/rebate/summary": {
      title: "Reporting - Parts Rebate Summary",
      description: " View the reports of parts rebate summary",
    },
    "/reporting/dealer/payments": {
      title: "Reporting - Dealer Payments",
      description: "Review the dealers payments",
    },
    "/reporting/quote/conversion": {
      title: "Reporting - Quote Conversion",
      description: "Review the quote conversion report",
    },
    "/rebate/allowance": {
      title: "Manage Dealer Rebate Allowance",
      description: "Add rebate allowances of dealers",
    },
  };
  const content = headerContent[location.pathname];

  return (
    <>
      {content && (
        <div className="rebate-management">
          <Title level={4} className="quoteheader">
            {content.title}
          </Title>
          <Title level={5} className="quote-info">
            {content.description}
          </Title>
        </div>
      )}
    </>
  );
};

export default AdminHeader;
