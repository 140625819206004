import React, { createContext, useState } from "react";

export const QuoteContext = createContext();

export const QuoteProvider = ({ children }) => {
  const [quoteParts, setQuoteParts] = useState([]);
  const [rebateSummary, setRebateSummary] = useState([]);
  const [detailedReport, setDetailedReport] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState([]);
  const [rebateCustomer, setRebateCustomer] = useState([]);
  const [addParts, setAddParts] = useState([]);
  const [tab, setTab] = useState();
  const [viewScreen, setViewScreen] = useState();
  const [historicData, setHistoricData] = useState([]);
  const [regionTypes, setRegionTypes] = useState([]);
  const [dealershipDetails, setDealershipDetails] = useState([]);
  const [allowanceRegion, setAllowanceRegion] = useState([]);
  const [rebateGrandTotal, setRebateGrandTotal] = useState([]);
  const [editPartId, setEditPartId] = useState();
  const [approvalStatus, setApprovalStatus] = useState([]);
  const [unApprovalStatus, setUnApprovalStatus] = useState([]);
  const [customerType, setCostomerType] = useState([]);
  const [summaryGrandTotal, setSummaryGrandTotal] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [managepartTotalInvoice, setManagepartTotalInvoice] = useState([]);
  const [managepartTotalRebate, setManagepartTotalRebate] = useState([]);
  return (
    <QuoteContext.Provider
      value={{
        quoteParts,
        setQuoteParts,
        rebateSummary,
        setRebateSummary,
        detailedReport,
        setDetailedReport,
        totalInvoices,
        setTotalInvoices,
        rebateCustomer,
        setRebateCustomer,
        addParts,
        setAddParts,
        tab,
        setTab,
        viewScreen,
        setViewScreen,
        historicData,
        setHistoricData,
        setRegionTypes,
        regionTypes,
        setDealershipDetails,
        dealershipDetails,
        editPartId,
        setEditPartId,
        setAllowanceRegion,
        allowanceRegion,
        setRebateGrandTotal,
        rebateGrandTotal,
        approvalStatus,
        setApprovalStatus,
        unApprovalStatus,
        setUnApprovalStatus,
        customerType,
        setCostomerType,
        setSummaryGrandTotal,
        summaryGrandTotal,
        setCustomerFilters,
        customerFilters,
        managepartTotalInvoice,
        setManagepartTotalInvoice,
        managepartTotalRebate,
        setManagepartTotalRebate,
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
};
