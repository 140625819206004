import React, { useEffect, useState, useContext, useRef } from "react";
import "../../../Assets/Styles/global-styles.css";
import CommonTable from "../../../Components/Table";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import infoCircle from "../../../Assets/Icons/InfoCircle.svg";
import CustomizedButton from "../../../Components/Button";
import { Typography, Select, Form, Col, Row, Pagination, Input } from "antd";
import "../../../Assets/Styles/fonts.css";
// import Export from "../../../Assets/Images/Download.svg";
import ExportIcon from "../../../Assets/Icons/ExportIcon.svg";
import CommonModal from "../../../Components/Modal";
import { RangeDateRangePicker } from "../../../Components/Datepicker";
import { paginationConfig } from "../../../Components/Pagination";
import { QuoteContext } from "../../Quote-Calculator/quoteContext";
import { sortArray } from "../../../Constants/utils";
import { RegionComponent } from "../../../Constants/commonRegion";
import { showInvoiceCredits } from "../../../Constants/commonObject";
import { useFormData } from "../../../Constants/commonFormdata";
import { cleanObject } from "../../../Constants/constants";
import PartNumberSelect from "../../../Components/PartNumberSelect/PartNumberSelect";
import ManageColumnDrawer from "./../ManageColumnDrawer/ManageColumnDrawer";
import moment from "moment/moment";
import usePartsRebateDetailTableColumns from "../../../shared/hooks/usePartsRebateDetailTableColumns";
import useGetPartsRebateDetails from "../../../shared/hooks/data/useGetPartsRebateDetails";
import useGetPartsRebateDetailTableColumns from "../../../shared/hooks/columns/useGetPartsRebateDetailTableColumns";
import { webService } from "../../../Services/web.service";
import { rebateDetailExportApi } from "../../../Services/userEndpoints";
import { saveAs } from "file-saver";
import { message } from "../../../Components/Message";

const { Title } = Typography;
const { Option } = Select;

const PartsRebateDetail = ({ rebateDetail }) => {
  const [defaultColumns, additionalColumns, selectedTableColumns] =
    usePartsRebateDetailTableColumns(rebateDetail);

  const [listFilter, setListFilter] = useState({
    loadRecords: false,
    pageSize: paginationConfig?.defaultPageSize,
    pageNumber: 1,
    additionalColumns: selectedTableColumns,
    ...(!rebateDetail ? { regionId: 0 } : {}),
  });

  const { partsRebateDetails, setListFilters } =
    useGetPartsRebateDetails(listFilter);

  const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([...defaultColumns]);
  const [showColumns, setShowColumns] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rebateRetailList, setRebateRetailList] = useState([]);

  const [isFiltered, setIsFiltered] = useState(false);

  const {
    regionTypes,
    setRebateGrandTotal,
    dealershipDetails,
    customerFilters,
  } = useContext(QuoteContext);
  const {
    formData,
    isAnyDropdownSelected,
    searchState,
    handleonChange,
    handleSearch,
    handleDropdownVisibleChange,
    selectedDates,
  } = useFormData();

  useEffect(() => {
    console.log("customerFilters", customerFilters);
  }, [customerFilters]);

  const { columns, setSelectedTableColumns } =
    useGetPartsRebateDetailTableColumns(selectedTableColumns, rebateDetail);

  const handleClickExport = () => {
    setExportModalVisible(true);
  };

  const onCancelModal = () => {
    setExportModalVisible(false);
  };

  const handleApplyFilter = () => {
    const values = {
      ...listFilter,
      loadRecords: true,
    };

    setListFilter(values);
    setListFilters(values);
  };

  // Export Rebate Detail Api
  const handleConfirmExport = async () => {
    try {
      webService.post(rebateDetailExportApi, {...listFilter}).then((response) => {
        const { fileName, buffer } = response.data;
        const arrayBuffer = new Uint8Array(buffer.data).buffer;
        const blob = new Blob([arrayBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
        message.success(`Part Rebate Detail Report is exported.`);
        setExportModalVisible(false);
      });
    } catch (error) {
      console.error("Error Download Excel:", error);
    }
  };

  // Update Columns
  const handleUpdateColumns = (selectedColumns = []) => {
    const values = {
      ...listFilter,
      additionalColumns: selectedColumns,
    };
    setListFilter(values);
    setListFilters(values);
    setSelectedTableColumns(selectedColumns);
    if(isAnyFilterSelected){
      const values = {
        ...listFilter,
        additionalColumns: selectedColumns,
        loadRecords: true,
      };
      setListFilter(values);
      setListFilters(values);
    }
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    const anyShowHideChecked = additionalColumns.some((col) =>
      selectedColumns.includes(col)
    );
    setShowColumns(anyShowHideChecked);
  }, [selectedColumns]);

  const onPaginationChange = (page, size) => {
    const values = {
      ...listFilter,
      pageSize: size,
      pageNumber: page,
    };
    setListFilter(values);
    setListFilters(values);
  };

  const handleClearFilter = () => {
    const values = {
      ...listFilter,
      ...(!rebateDetail ? { regionId: 0 } : {}),
      dealershipId: undefined,
      invoicesOrCredits: undefined,
      partNumber: undefined,
      partDescription: undefined,
      dateRange: undefined,
      dateStart: undefined,
      dateEnd: undefined,
      dmsId: undefined,
      customerName: undefined,
      loadRecords: false,
    };

    setListFilter(values);
    setListFilters(values);
  };

  const reportType = regionTypes.find(
    (report) => report?.name === formData.region
  );

  const dropdownRender = (menu) => (
    <div>
      <div className=" dropdown-container">
        <p className="dropdown-partnumber-heading">Code</p>
        <p className="dropdown-description-heading">Dealership Name</p>
      </div>
      {menu}
    </div>
  );

  const isAnyFilterSelectedFun = () => {
    setIsAnyFilterSelected(
      listFilter.regionId > 0 ||
        listFilter.dealershipId ||
        listFilter.invoicesOrCredits ||
        listFilter.partNumber ||
        listFilter.dateRange ||
        listFilter.dmsId ||
        listFilter.customerName
    );
  };

  const handleFilterChange = (name, value) => {
    let values = {};

    if (name === "regionId"){
      if (value !== 0)
        values = {
          ...listFilter,
          regionId: value,
          dealershipId: undefined,
        }
        else{
          values = {
            ...listFilter,
            regionId: value,
          }
        }
        setListFilter(values)
    }
    else if  (name === "partNumber") {
      const partNumber = value.split("-")[1];
      const partDescription = value.split("-")[2];

      values = {
        ...listFilter,
        partNumber: partNumber,
        partDescription: partDescription,
      };
    } else {
      values = {
        ...listFilter,
        [name]: value,
      };
    }

    setListFilter(values);
  };

  const handleDateChange = (value) => {
    const values = {
      ...listFilter,
      dateRange: value,
      dateStart: moment(new Date(value[0])).format("YYYY-MM-DD"),
      dateEnd: moment(new Date(value[1])).format("YYYY-MM-DD"),
    };

    setListFilter(values);
  };

  useEffect(() => {
    setIsFiltered(
      (() => {
        return Object.keys(cleanObject(listFilter)).length > 0;
      })()
    );
    isAnyFilterSelectedFun();
  }, [listFilter]);

  useEffect(() => {
    const data = partsRebateDetails?.data || {};
    const rebateRetail = data.rows || [];
    const totalAllRecords = data.total || 0;

    const grandTotal = {
      totalQuantity: data.totalQuantity || 0,
      totalQuotedPrice: data.totalQuotedPrice || 0,
      totalStockOrderPrice: data.totalStockOrderPrice || 0,
      totalGrossProfit: data.totalGrossProfit || 0,
      totalGrossProfitPercentage: data.totalGrossProfitPercentage || 0,
      totalRebate: data.totalRebate || 0,
    };

    setRebateRetailList(rebateRetail);
    setRebateGrandTotal(grandTotal);
    setTotalRecords(totalAllRecords);
  }, [partsRebateDetails]);

  return (
    <div>
      {rebateDetail && (
        <div className="quote-container">
          <span>
            <Title level={4} className="quoteheader">
              Parts Rebate Detailed Report
            </Title>
            <Title level={5} className="quote-info">
              Review the detailed report of parts rebate
            </Title>
          </span>
        </div>
      )}
      <div className="main-container">
        <div className="box">
          <div className="row-col-container">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {!rebateDetail && (
                <>
                  <Col className="gutter-row" span={8}>
                    <Title className="customer-id summary-dropdown" level={5}>
                      Report Type
                    </Title>
                    <Form.Item>
                      <Select
                        showSearch
                        placeholder="Select Region"
                        className="custom-select custom-placeholder-text"
                        // defaultValue={}
                        value={listFilter.regionId}
                        onChange={(value) =>
                          handleFilterChange("regionId", value)
                        }
                        onSearch={(value) => handleSearch("region", value)}
                        onDropdownVisibleChange={(open) =>
                          handleDropdownVisibleChange("region", open)
                        }
                        suffixIcon={
                          searchState.region.onSearch ? (
                            <SearchOutlined className="dropdown-icon" />
                          ) : (
                            <CaretDownOutlined className="dropdown-icon" />
                          )
                        }
                      >
                        {regionTypes?.map((item) => (
                          <Select.Option key={item.id} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <Title className="customer-id summary-dropdown" level={5}>
                      Dealer Code / Name
                    </Title>
                    <Form.Item>
                    {listFilter.regionId == "0" ? (
                        <Select
                          showSearch
                          className="custom-select custom-placeholder-text"
                          value={listFilter.dealershipId}
                          onChange={(value) => {
                            handleFilterChange("dealershipId", value);
                          }}
                          onSearch={(value) =>
                            handleSearch("dealerCode", value)
                          }
                          onDropdownVisibleChange={(open) =>
                            handleDropdownVisibleChange("dealerCode", open)
                          }
                          suffixIcon={
                            searchState.dealerCode.onSearch ? (
                              <SearchOutlined className="dropdown-icon" />
                            ) : (
                              <CaretDownOutlined className="dropdown-icon" />
                            )
                          }
                          placeholder="Select Dealer"
                          filterOption={(input, option) => {
                            const code = option.props.code;
                            const name = option.props.name;
                            return (
                              code
                                ?.toLowerCase()
                                .includes(input?.toLowerCase()) ||
                              name?.toLowerCase().includes(input?.toLowerCase())
                            );
                          }}
                          optionFilterProp="children"
                          dropdownRender={dropdownRender}
                        >
                          {dealershipDetails?.map((item) => (
                            <Select.Option
                              key={item?.id}
                              value={`${item?.id}`}
                              code={item?.code}
                              name={item?.name}
                            >
                              <div className="options">
                                <p>{item?.code}</p>
                                <p className="elipse-width options-description">
                                  {item?.name}
                                </p>
                              </div>
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <Input
                          className="input-style"
                          disabled
                          placeholder="Select Dealer"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <Title className="customer-id summary-dropdown" level={5}>
                      Show (Invoices and/or credits)
                    </Title>
                    <Form.Item>
                      <Select
                        showSearch
                        className="custom-select custom-placeholder-text text-capitalize"
                        value={listFilter.invoicesOrCredits}
                        onChange={(value) =>
                          handleFilterChange("invoicesOrCredits", value)
                        }
                        suffixIcon={
                          <CaretDownOutlined className="dropdown-icon" />
                        }
                        placeholder="Select Invoices or credits"
                      >
                        {showInvoiceCredits?.map((item) => (
                          <Select.Option
                            className="text-capitalize"
                            key={item.id}
                            value={item?.name}
                          >
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col className="gutter-row" span={8}>
                <Title className="customer-id" level={5}>
                  Part Number
                </Title>
                <Form.Item>
                  <PartNumberSelect
                    selectedValue={listFilter.partNumber}
                    handleChangePartNumber={(value) => {
                      handleFilterChange("partNumber", value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Title className="customer-id" level={5}>
                  Part Description
                </Title>
                <Form.Item name="description">
                  {listFilter.partDescription === undefined ? (
                    <Input
                      className="input-style addPart-input-style"
                      value={listFilter.partDescription}
                      disabled
                      placeholder="Part Description"
                    />
                  ) : (
                    <div
                      className=" custom-tooltip "
                      style={{ width: "calc(100% - 3px)" }}
                    >
                      <div>
                        <Input
                          className="input-style addPart-input-style"
                          value={listFilter.partDescription}
                          disabled
                          placeholder="Part Description"
                        />
                        <span className="tooltip-text">
                          {listFilter.partDescription}
                        </span>
                      </div>
                    </div>
                  )}
                </Form.Item>
              </Col>
              {rebateDetail && (
                <>
                  {" "}
                  <Col className="gutter-row" span={8}>
                    <Title className="customer-id" level={5}>
                      Customer Name
                    </Title>
                    <Form.Item>
                      <Select
                        showSearch
                        className="custom-select custom-placeholder-text"
                        value={listFilter.customerName}
                        onChange={(value) => {
                          handleFilterChange("customerName", value);
                        }}
                        onSearch={(value) =>
                          handleSearch("customerName", value)
                        }
                        onDropdownVisibleChange={(open) =>
                          handleDropdownVisibleChange("customerName", open)
                        }
                        suffixIcon={
                          searchState.customerName.onSearch ? (
                            <SearchOutlined className="dropdown-icon" />
                          ) : (
                            <CaretDownOutlined className="dropdown-icon" />
                          )
                        }
                        placeholder="Select Customer Name"
                      >
                        {customerFilters?.map((item) => (
                          <Option key={item.id} value={item?.name}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <Title className="customer-id" level={5}>
                      DMS ID
                    </Title>
                    <Form.Item>
                      <Select
                        showSearch
                        className="custom-select custom-placeholder-text"
                        value={listFilter.dmsId}
                        onChange={(value) => {
                          handleFilterChange("dmsId", value);
                        }}
                        onSearch={(value) => handleSearch("dmsId", value)}
                        onDropdownVisibleChange={(open) =>
                          handleDropdownVisibleChange("dmsId", open)
                        }
                        suffixIcon={
                          searchState.dmsId.onSearch ? (
                            <SearchOutlined className="dropdown-icon" />
                          ) : (
                            <CaretDownOutlined className="dropdown-icon" />
                          )
                        }
                        placeholder="Select DMS ID"
                      >
                        {sortArray(customerFilters, "id")?.map((item) => (
                          <Option key={item.id} value={item?.id}>
                            {item?.id}{" "}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col className="gutter-row" span={8}>
                <Title className="customer-id" level={5}>
                  Date Range
                </Title>
                <Form.Item className="summary-report">
                  <RangeDateRangePicker
                    selectedDates={listFilter.dateRange}
                    setSelectedDates={handleDateChange}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>
              {rebateDetail && (
                <>
                  <Col className="gutter-row" span={6}></Col>{" "}
                  <Col className="gutter-row" span={6}></Col>
                </>
              )}
            </Row>
            <div>
              <div className="quotes-all-list-container">
                <CustomizedButton
                  className="reset-btn"
                  onClick={handleClearFilter}
                >
                  Reset
                </CustomizedButton>
                <CustomizedButton
                  type="primary"
                  className={isAnyFilterSelected ? "apply-btn" : "apply-btn-disabled"}
                  onClick={handleApplyFilter}
                >
                  Apply
                </CustomizedButton>
              </div>
            </div>
          </div>
          <div className="hr-container">
            <hr className="hr-line" />
          </div>
          <div>
            <Title className="quote-items all-quote-list" level={4}></Title>
            <div className="total-container reports-container">
              <div className="total-text">
                Total:
                <span className="item">
                  {totalRecords > 0 && totalRecords < 10
                    ? `0${totalRecords}`
                    : totalRecords}{" "}
                  item
                  {totalRecords > 1 ? "s" : ""}
                </span>
              </div>
              <div className="apply-btn-container">
                <CustomizedButton
                  className="import-file-btn export-button summary-exportbtn btn-right "
                  type="Outlined"
                  onClick={handleOpenDrawer}
                >
                  Manage Columns
                </CustomizedButton>
                <CustomizedButton
                  className={
                    rebateRetailList?.length > 0
                      ? "import-file-btn export-button summary-exportbtn"
                      : "apply-btn-disabled summary-exportbtn"
                  }
                  type="Outlined"
                  icon={ExportIcon}
                  onClick={handleClickExport}
                >
                  Export Report
                </CustomizedButton>
              </div>
            </div>
            <div className="table-container">
              <CommonTable
                dataSource={rebateRetailList}
                columns={columns.map((column) => ({
                  ...column,
                  // onHeaderCell: () => ({
                  //   onClick: () => {},
                  // }),
                  // sortOrder: columnSortDirections[column.dataIndex],
                }))}
              />
              {/* {30 > 15 && ( */}
              <div className="quote-pagination">
                <Pagination
                  onChange={onPaginationChange}
                  total={totalRecords}
                  className="custom-pagination"
                  current={listFilter.pageNumber}
                  {...paginationConfig}
                />
              </div>
              {/* )} */}
            </div>
            <CommonModal
              id=":rh:"
              visible={exportModalVisible}
              onCancel={onCancelModal}
              onConfirm={handleConfirmExport}
              title={
                <>
                  <img className="infocircle" src={infoCircle} /> Export Report
                </>
              }
              showExportButton={exportModalVisible}
            >
              <p className="export-message info-messages ">
                Export Part Rebate Detail in XLSX format
              </p>
            </CommonModal>
            <ManageColumnDrawer
              isDrawerOpen={openDrawer}
              onCloseDrawer={handleCloseDrawer}
              defaultColumns={defaultColumns}
              additionalColumns={additionalColumns}
              handleUpdateColumns={handleUpdateColumns}
              setOpenDrawer={setOpenDrawer}
            />
          </div>
          <RegionComponent />
        </div>
      </div>
    </div>
  );
};
export default PartsRebateDetail;
