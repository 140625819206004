export const zeroValueRule = {
  validator: (_, value) => {
    const numberValue = parseFloat(value);
    if (numberValue === 0 || value === "") {
      return Promise.reject(new Error("Value must be greater than zero."));
    }
    return Promise.resolve();
  },
};

export const getMaxCharsRule = (fieldName, maxChars = 50) => ({
  max: maxChars,
  message: `Max ${maxChars} characters.`,
});

export const requiredRule = {
  required: true,
  message: "This field is required.",
};

export const onHandleNumericValidation = (
  e,
  form,
  fieldName,
  allowDecimals = true
) => {
  const { value, selectionStart, selectionEnd } = e.target;
  const key = e.key;
  const regex = /^[0-9]+$/;

  // Check if the entire input is selected or if part of it is selected
  const isAllSelected = selectionStart === 0 && selectionEnd === value.length;
  const isPartialSelection = selectionStart !== selectionEnd;

  // Prevent non-numeric input unless the whole value or part is selected
  if (
    !regex.test(key) &&
    key !== "Backspace" &&
    (allowDecimals ? key !== "." : true) &&
    !isAllSelected &&
    !isPartialSelection
  ) {
    e.preventDefault();
    form.setFields([
      {
        name: fieldName,
        errors: ["Numeric values only."],
      },
    ]);
    return;
  }

  // Handle decimal conditions
  if (key === "." && !allowDecimals) {
    e.preventDefault();
    form.setFields([
      {
        name: fieldName,
        errors: ["Decimals are not allowed."],
      },
    ]);
    return;
  }

  if (key === "." && value === "") {
    e.preventDefault();
    form.setFields([
      {
        name: fieldName,
        errors: ["Numeric values only."],
      },
    ]);
    return;
  }

  // Allow only one decimal point unless it's part of a selection
  if (key === "." && value.includes(".") && !isPartialSelection) {
    e.preventDefault();
    return;
  }

  // Prevent more than two decimal places unless it's part of a selection
  const decimalIndex = value.indexOf(".");
  if (
    decimalIndex !== -1 &&
    value.length - decimalIndex > 2 &&
    !isAllSelected &&
    !isPartialSelection
  ) {
    e.preventDefault();
    form.setFields([
      {
        name: fieldName,
        errors: ["Max 2 decimals allowed."],
      },
    ]);
  }
};


// export const onHandleNumericValidation = (
//   e,
//   form,
//   fieldName,
//   allowDecimals = true
// ) => {
//   const { value } = e.target;
//   const key = e.key;
//   const regex = /^[0-9]+$/;

//   // Prevent non-numeric input
//   if (
//     !regex.test(key) &&
//     key !== "Backspace" &&
//     (allowDecimals ? key !== "." : true)
//   ) {
//     e.preventDefault();
//     form.setFields([
//       {
//         name: fieldName,
//         errors: ["Numeric values only."],
//       },
//     ]);
//     return;
//   }
//   if (key === "." && !allowDecimals) {
//     e.preventDefault();
//     form.setFields([
//       {
//         name: fieldName,
//         errors: ["Decimals are not allowed."],
//       },
//     ]);
//     return;
//   }

//   if (key === "." && value === "") {
//     e.preventDefault();
//     form.setFields([
//       {
//         name: fieldName,
//         errors: ["Numeric values only."],
//       },
//     ]);
//     return;
//   }

//   // Allow only two decimal places
//   if (key === "." && value.includes(".")) {
//     e.preventDefault();
//     return;
//   }

//   const decimalIndex = value.indexOf(".");
//   if (decimalIndex !== -1 && value.length - decimalIndex > 2) {
//     e.preventDefault();
//     form.setFields([
//       {
//         name: fieldName,
//         errors: ["Max 2 decimals allowed."],
//       },
//     ]);
//   }
// };

export const onHandleNumericWithDecimalsValidation = (
  e,
  form,
  fieldName
) => {
  const { value } = e.target;
  const key = e.key;
  const regex = /^[0-9]*\.?[0-9]*$/; // Allows numbers with optional decimal point

  // Allow Backspace, Tab, and Arrow keys
  if (key === "Backspace" || key === "Tab" || key === "ArrowLeft" || key === "ArrowRight") {
    return;
  }

  // Prevent non-numeric input, except for the decimal point
  if (!regex.test(value + key) && key !== "Backspace" && key !== "Tab" && key !== ".") {
    e.preventDefault();
    form.setFields([
      {
        name: fieldName,
        errors: ["Numeric values only."],
      },
    ]);
    return;
  }

  // Prevent multiple decimal points
  if (key === "." && value.includes(".")) {
    e.preventDefault();
    return;
  }

  // Prevent entering a decimal point as the first character
  if (key === "." && value === "") {
    e.preventDefault();
    form.setFields([
      {
        name: fieldName,
        errors: ["Numeric values only."],
      },
    ]);
    return;
  }

  // Limit decimal places to two
  const decimalIndex = value.indexOf(".");
  if (decimalIndex !== -1 && value.length - decimalIndex > 2) {
    e.preventDefault();
    form.setFields([
      {
        name: fieldName,
        errors: ["Max 2 decimals allowed."],
      },
    ]);
  }
};


// Rule to restrict decimal values
export const noDecimalsRule = {
  validator: (_, value) => {
    if (value && value.includes(".")) {
      return Promise.reject(new Error("Decimals are not allowed."));
    }
    return Promise.resolve();
  },
};
