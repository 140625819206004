import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Layout, Flex } from "antd";
import { CaretDownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Space } from "antd";
import CustomizedButton from "../../Components/Button";
import "../../Assets/Styles/fonts.css";
import useGetUser from "../../shared/hooks/useGetUser";
import { auth } from "../../Services/auth.service";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

const Headers = () => {

  const {user} = useGetUser();
  const navigate = useNavigate();

  const [isLogoutButtonVisible, setLogoutButtonVisible] = useState(false);
  const containerRef = useRef(null);

  const handleIconClick = () => {
    setLogoutButtonVisible(!isLogoutButtonVisible);
  };
  
  const handleLogoutClick = () => {
    auth.logout();
    window.location.href = 'http://hmcatest.dealer-portal-ap.net:51000/irj/portal';
    setLogoutButtonVisible(false)
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setLogoutButtonVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Header className="header-wrapper">
      <div>
        <Flex gap="small" wrap="wrap" className="user-profile" ref={containerRef}>
          <CustomizedButton
            shape="round"
            onClick={handleIconClick}
            className="custom-button"
          >
            <div className="custom-button-content">
              <Space size={16} align="center">
                <div>
                  <span className="username">{user?.name || 'User'}</span>
                  <div className="dealer-container">
                    <span className="dealer">{user?.role?.name || 'User Role'}</span>
                  </div>
                </div>
              </Space>
              <CaretDownOutlined className="icon" />
            </div>
          </CustomizedButton>
          {isLogoutButtonVisible && (
            <div className="logout">
              <CustomizedButton
                icon={<LogoutOutlined className="logout-icon" />}
                size="small"
                onClick={handleLogoutClick}
                className="logout-btn"
              >
                <span className="logout-name">Logout</span>
              </CustomizedButton>
            </div>
          )}
        </Flex>
      </div>
    </Header>
  );
};
export default Headers;
