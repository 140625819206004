export const dealerTypeList = [
  { id: 1, name: "Trade Dealer" },
  { id: 2, name: "Retail Dealer" },
];

export const sortDirections = {
  ascend: "ASC",
  descend: "DESC",
  null: null,
};

export const showInvoiceCredits = [
  { id: 1, name: "invoices" },
  { id: 2, name: "credits" },
];
