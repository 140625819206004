import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import "./index.css";
import "../../Assets/Styles/global-styles.css";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BarChartOutlined,
  IdcardOutlined,
  HomeOutlined,
  CalculatorOutlined,
  ReconciliationOutlined,
  PercentageOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, Tooltip } from "antd";
import Headers from "../Header";
import { Link, useLocation, matchPath, Outlet } from "react-router-dom";
import HyundaiLogo from "../../Assets/Images/HyundaiLogo.svg";
import HyundaiPartsLogo from "../../Assets/Images/HyundaiPartsLogo.svg";
import "../../Assets/Styles/fonts.css";
import Footer from "../Footer";
import AdminHeader from "../Header/adminHeader";
import { QuoteContext } from "../../Pages/Quote-Calculator/quoteContext";
import {
  useIsAdmin,
  useIsRetailDealer,
  useIsTradeDealer,
} from "../../shared/hooks/role";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [textVisible, setTextVisible] = useState(!collapsed);
  const [selectedKey, setSelectedKey] = useState([]);
  const [inTransition, setInTransition] = useState(true);
  const location = useLocation();
  const { editPartId } = useContext(QuoteContext);
  const timeoutId = useRef(null);

  const isAdmin = useIsAdmin();
  const isTradeDealer = useIsTradeDealer();
  const isRetailDealer = useIsRetailDealer();

  const dealerMenu = [
    {
      key: "1",
      icon: <HomeOutlined />,
      link: "/",
      text: "Home",
    },
    {
      key: "2",
      icon: <CalculatorOutlined />,
      link: "/quote/list",
      text: "Quote Calculator",
    },
    ...(isRetailDealer
      ? []
      : [
          {
            key: "3",
            icon: <IdcardOutlined />,
            link: "/parts/rebate/customer",
            text: "Parts Rebate Customers",
          },
          {
            key: "4",
            icon: <BarChartOutlined />,
            link: "/parts/rebate/summary",
            text: "Parts Rebate Summary",
          },
          {
            key: "5",
            icon: <ReconciliationOutlined />,
            link: "/parts/rebate/detail",
            text: "Parts Rebate Detailed Report",
          },
        ]),
  ];

  const adminMenu = [
    {
      key: "6",
      icon: <HomeOutlined />,
      link: "/",
      text: "Home",
    },
    {
      key: "7",
      icon: <CalculatorOutlined />,
      link: "/quote/list",
      text: "Quote Calculator",
    },
    {
      key: "8",
      icon: collapsed ? <DollarOutlined /> : <PercentageOutlined />,
      link: "/rebate/management",
      text: "Rebate Management",
    },
    {
      key: "9",
      icon: <IdcardOutlined />,
      link: "/rebate/management/customer",
      text: "Manage Parts Rebate Customer",
    },
    {
      key: "10",
      icon: <ReconciliationOutlined />,
      link: "/rebate/allowance",
      text: "Manage Dealer Rebate Allowance",
    },
    {
      key: "11",
      icon: <BarChartOutlined />,
      text: "Reporting",
      subMenu: [
        {
          key: "11-1",
          link: "/reporting/parts/rebate/summary",
          text: "Parts Rebate Summary",
        },
        {
          key: "11-2",
          link: "/reporting/parts/rebate/detail",
          text: "Parts Rebate Detail",
        },
        {
          key: "11-3",
          link: "/reporting/quote/conversion",
          text: "Quote Conversion",
        },
        {
          key: "11-4",
          link: "/reporting/dealer/payments",
          text: "Dealer Payments",
        },
      ],
    },
  ];

  const menuItems = useMemo(
    () => [
      ...(isTradeDealer || isRetailDealer ? dealerMenu : []),
      ...(isAdmin ? adminMenu : []),
    ],
    [collapsed, isAdmin, isTradeDealer, isRetailDealer]
  );


  const handleMenuClick = (key) => {
    setSelectedKey(key);
    // if (collapsed === false) {
    //   setCollapsed(true);
    // }
  };
  const toggleCollapse = () => {
    setInTransition(true);
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);

    timeoutId.current = setTimeout(() =>{
      setInTransition(false);
    },400 );
  
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  
    if (newCollapsedState) {
      setTextVisible(false);
      timeoutId.current = setTimeout(() => {
        // Optional additional logic after collapsing
      }, 350);
    } else {
      timeoutId.current = setTimeout(() => {
        setTextVisible(true);
      }, 350);
    }
  };
  useEffect(() => {
    const path = location.pathname;
    const selectedItem = menuItems.find(
      (item) =>
        item.link === path ||
        (item.subMenu && item.subMenu.some((subItem) => subItem.link === path))
    );
    if (selectedItem) {
      setSelectedKey(selectedItem.key);
    }
  }, [location.pathname, menuItems]);

  const showFooter = ![
    "/quote/list",
    "/quote/create",
    "/reporting/quote/conversion",
    "/reporting/dealer/payments",
    "/rebate/management",
    "/rebate/allowance",
    "/admin-home",
    "/",
  ].some((path) => matchPath(path, location.pathname)) && 
  !matchPath({ path: "/rebate/management/edit/*" }, location.pathname);

  const renderMenuItem = (item) => {
    return (
      <Menu.Item
        key={item.key}
        icon={item.icon}
        onClick={() => handleMenuClick(item.key)}
      >
        <Tooltip title={item.text} placement="right" visible={collapsed}>
          <Link className="hyundai-font" to={item.link}>
            {item.text}
          </Link>
        </Tooltip>
      </Menu.Item>
    );
  };

  const renderSubMenu = (item) => (
    <SubMenu key={item.key} icon={item.icon} title={item.text}>
      {item.subMenu.map((subItem) => renderMenuItem(subItem))}
    </SubMenu>
  );

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={264}
        collapsedWidth={80}
        onCollapse={(isCollapsed) => setCollapsed(isCollapsed)}
        className="sidebar-sticky-container"
      >
        <div className="demo-logo-vertical">
          {!textVisible ? (
            <img
              src={HyundaiLogo}
              alt="Logo"
              className="hyundai-logo-collapse"
            />
          ) : (
            <img
              src={HyundaiPartsLogo}
              alt="Logo"
              className="hyundai-logo-expand"
            />
          )}
        </div>
        <div className="menu-btn">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={toggleCollapse}
            style={{
              fontSize: "16px",
              color: "white",
              marginTop: collapsed ? 13 : 0,
            }}
          />
        </div>
        <div className={collapsed && "sider-menu-container-collapsed"}>
          <Menu
            className={`sidebar-wrapper ${
              collapsed ? "collapsed" : "expanded"
            }`}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedKey]}
          >
            {menuItems.map((item) =>
              item.subMenu && inTransition ? (
                <SubMenu key={item.key} icon={item.icon} title={item.text}>
                  {item.subMenu.map((subItem) => (
                    <Menu.Item
                      key={subItem.key}
                      onClick={() => handleMenuClick(item.key)}
                    >
                      <Link className="hyundai-font" to={subItem.link}>
                        {inTransition && subItem.text}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                    onClick={() => handleMenuClick(item.key)}
                  >
                    {collapsed ? (
                      // Render Tooltip when the sidebar is collapsed
                      <Tooltip
                        title={item.text}
                        placement="right"
                        style={{marginTop:'50px'}}
                      >
                        <Link className="hyundai-font" to={item.link}>
                          {textVisible && item.text}
                        </Link>
                      </Tooltip>
                    ) : (
                      // Render text directly when the sidebar is not collapsed
                      <Link className="hyundai-font" to={item.link}>
                        {textVisible && item.text}
                      </Link>
                    )}
                  </Menu.Item>
              )
            )}
          </Menu>
        </div>
      </Sider>
      <Layout>
        <Headers />
        <div>
          <AdminHeader />
        </div>
        <Layout.Content className="main">
          {/* <Router setSelectedKey={setSelectedKey} /> */}
          <Outlet />
        </Layout.Content>
        {showFooter && (
          <Layout.Footer className="footer-container">
            <Footer />
          </Layout.Footer>
        )}
      </Layout>
    </Layout>
  );
};
export default React.memo(Sidebar);
