import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/en";
dayjs.extend(customParseFormat);

const CustomDatePicker = ({
  value,
  onChange,
  format = "DD/MM/YYYY",
  ...rest
}) => {
  const dateOnly = value ? dayjs(value).format("YYYY-MM-DD") : null;
  return (
    <DatePicker
      {...rest}
      className="date-picker-style"
      value={
        dateOnly && dayjs(dateOnly, "YYYY-MM-DD").isValid()
          ? dayjs(dateOnly, "YYYY-MM-DD")
          : null
      }
      format={format}
      onChange={(date, dateString) => {
        const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : null;
        onChange(formattedDate);
      }}
    />
  );
};
const DateRangePicker = ({
  selectedDates,
  setSelectedDates,
  handleChange,
  format,
}) => {
  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const formattedDates = dates.map((date) =>
        date ? date.format(format) : null
      );
      setSelectedDates(dates);
      if (handleChange) {
        handleChange(dates, formattedDates);
      }
    } else {
      setSelectedDates([null, null]);
    }
  };

  return (
    <DatePicker.RangePicker
      className="report-datepicker"
      picker="month"
      format={format}
      value={selectedDates}
      placeholder={["mm/yyyy", "mm/yyyy"]}
      onChange={handleDateChange}
      style={{ width: "100%" }}
    />
  );
};
const RangeDateRangePicker = ({
  selectedDates,
  setSelectedDates,
  handleChange,
  format,
}) => {
  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const formattedDates = dates.map((date) =>
        date ? date.format(format) : null
      );
      setSelectedDates(dates);
      if (handleChange) {
        handleChange(dates, formattedDates);
      }
    } else {
      setSelectedDates([null, null]);
    }
  };

  return (
    <DatePicker.RangePicker
      className="report-datepicker"
      // picker="month"
      format={format}
      value={selectedDates}
      placeholder={["dd/mm/yyyy", "dd/mm/yyyy"]}
      onChange={handleDateChange}
      style={{ width: "100%" }}
    />
  );
};

const MonthRangePicker = ({
  selectedDates,
  setSelectedDates,
  handleChange,
  format = "MM/YYYY",
}) => {
  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const formattedDates = dates.map((date) =>
        date ? date.format(format) : null
      );
      setSelectedDates(dates);
      if (handleChange) {
        handleChange(dates, formattedDates);
      }
    } else {
      setSelectedDates([null, null]);
    }
  };

  return (
    <DatePicker.RangePicker
      className="report-datepicker"
      picker="month" // Specify month picker
      format={format}
      value={selectedDates}
      placeholder={["mm/yyyy", "mm/yyyy"]}
      onChange={handleDateChange}
      style={{ width: "100%" }}
    />
  );
};

export { CustomDatePicker, DateRangePicker, RangeDateRangePicker, MonthRangePicker };
