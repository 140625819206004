//BasePrice
export const CalculateBasePrice = (props) => {
  const { quantity, minimumSellPrice } = props;

  const initialBasePrice = parseFloat(minimumSellPrice);
  const quantityFloat = parseFloat(quantity);
  const updatedBasePrice = initialBasePrice * quantityFloat;

  return updatedBasePrice.toFixed(2);
};
//stockOrderPrice
export const CalculateStockPrice = (props) => {
  const { stockOrderPrice, quantity } = props;
  const initialStockOrderPrice = parseFloat(stockOrderPrice);
  const quantityFloat = parseFloat(quantity);
  const updatedStockOrderPrice = initialStockOrderPrice * quantityFloat;

  return updatedStockOrderPrice.toFixed(2);
};
//GP$
export const CalculateDealerGP = (props) => {
  const { quotedSellPrice, stockOrderPrice } = props;
  let dealerGP;
  if (quotedSellPrice > stockOrderPrice) {
    dealerGP = parseFloat(quotedSellPrice) - parseFloat(stockOrderPrice);
  } else {
    dealerGP = parseFloat(stockOrderPrice) - parseFloat(quotedSellPrice);
  }

  return parseFloat(dealerGP).toFixed(2);
};
//GP%
export const CalculateGpPercentage = (props) => {
  const { dealerGP, stockOrderPrice } = props;
  const gpPercentage = (dealerGP / stockOrderPrice) * 100;
  return gpPercentage.toFixed(2);
};

//total Rebate, this fun not using kept it for ref.
export const CalculateTotalRebate = (props) => {
  const {
    minimumSellPrice,
    calDealerGP,
    quotedSellPrice,
    stockOrderPrice,
    calGPRecovery,
  } = props;
  const gpPercentage = (calDealerGP / stockOrderPrice) * 100;

  let totalRebate;
  switch (true) {
    case 2.5 < gpPercentage < 12.5:
      const stockPricePercentage = 1.125 * stockOrderPrice;
      totalRebate = stockPricePercentage - quotedSellPrice;
      return totalRebate.toFixed(2);
    case gpPercentage > 12.5:
      return (totalRebate = 0);
    case gpPercentage < 2.5:
      totalRebate = (10 * quotedSellPrice) / 100;
      return totalRebate.toFixed(2);
    case minimumSellPrice < quotedSellPrice < stockOrderPrice:
      const handlingfee = (10 / 100) * quotedSellPrice;
      totalRebate = handlingfee + calGPRecovery;
      return totalRebate.toFixed(2);
    case quotedSellPrice < minimumSellPrice &&
      quotedSellPrice < stockOrderPrice:
      const handlingFee = (10 / 100) * quotedSellPrice;
      totalRebate = handlingFee + calGPRecovery;
      return totalRebate.toFixed(2);
  }
};

//gpRecovery
export const CalculateGpRecovery = (props) => {
  const { stockOrderPrice, quotedSellPrice, minimumSellPrice } = props;
  const initialStockOrderPrice = parseFloat(stockOrderPrice);
  const intialQuotedSellPrice = parseFloat(quotedSellPrice);
  const intialSellPrice = parseFloat(minimumSellPrice);

  let gpRecovery;
  if (intialQuotedSellPrice > initialStockOrderPrice) {
    gpRecovery = 0;
  } else if (
    initialStockOrderPrice > intialQuotedSellPrice &&
    intialSellPrice > intialQuotedSellPrice
  ) {
    gpRecovery = initialStockOrderPrice - intialSellPrice;
  } else {
    gpRecovery = initialStockOrderPrice - quotedSellPrice;
  }
  return gpRecovery.toFixed(2);
};

//hanlding fee, kept it for ref
export const CalculateHandlingFee = (props) => {
  const { region, quotedSellPrice, stockOrderPrice } = props;
  const initialStockOrderPrice = parseFloat(stockOrderPrice);
  const intialQuotedSellPrice = parseFloat(quotedSellPrice);
  let handlingFee;
  const handlingFeeRate = 0.1;

  if (intialQuotedSellPrice > initialStockOrderPrice) {
    handlingFee = "rebate value";
  } else {
    handlingFee = intialQuotedSellPrice * handlingFeeRate;
  }
  return parseFloat(handlingFee).toFixed(2);
};

export const CalculateHandlingFeeAndRebate = (props) => {
  const {
    minimumSellPrice,
    calDealerGP,
    quotedSellPrice,
    stockOrderPrice,
    calGPRecovery,
  } = props;

  const gpPercentage = ((calDealerGP / quotedSellPrice) * 100).toFixed(2);

  let totalRebate;
  let handlingFee;
  let ruleApplied;
  if (quotedSellPrice > stockOrderPrice) {
    if (gpPercentage > 2.5 && gpPercentage < 12.5) {
      const stockPricePercentage = 1.125 * stockOrderPrice;
      totalRebate = stockPricePercentage - quotedSellPrice;
      handlingFee = parseFloat(totalRebate).toFixed(2);
      parseFloat(totalRebate).toFixed(2);
      ruleApplied =
        "Part sold at above stock order price with margin above 2.5% and Maximum handling of 10% capped at total GP of 12.5%";
    } else if (gpPercentage > 12.5) {
      totalRebate = 0;
      handlingFee = totalRebate;
      ruleApplied =
        "Part sold at above stock order price with margin above 12.5% in Total";
    } else if (gpPercentage < 2.5) {
      totalRebate = (10 * quotedSellPrice) / 100;
      parseFloat(totalRebate).toFixed(2);
      handlingFee = parseFloat(totalRebate).toFixed(2);
      ruleApplied =
        "Part sold at above stock order price but with margin below 2.5% and Maximum handling of 10%";
    }
  } else {
    if (
      quotedSellPrice > minimumSellPrice &&
      quotedSellPrice < stockOrderPrice
    ) {
      handlingFee = (10 / 100) * quotedSellPrice;
      totalRebate = handlingFee + parseInt(calGPRecovery);
      parseFloat(totalRebate).toFixed(2);
      ruleApplied =
        "Parts sold at below stock order price but at higher value than HMCA base price with 10% maximum handling";
    } else if (
      quotedSellPrice < minimumSellPrice &&
      quotedSellPrice < stockOrderPrice
    ) {
      handlingFee = (10 / 100) * quotedSellPrice;
      totalRebate = handlingFee + parseInt(calGPRecovery);
      parseFloat(totalRebate).toFixed(2);
      ruleApplied =
        "Part sold at or below HMCA base price with Max GP Recovery and 10% maximum handling";
    }
  }
  return { handlingFee, totalRebate, ruleApplied };
};

//rule applied, kept it for ref.
export const CalculateRuledApplied = (props) => {
  const { minimumSellPrice, quotedSellPrice, stockOrderPrice } = props;

  const percentageOfStockOrderPrice = (2.5 / 100) * stockOrderPrice;
  const totalQuotesSellPriceOfPercentage =
    stockOrderPrice + percentageOfStockOrderPrice;

  const percentageOfStockOrderPriceMargin = 12.5 * stockOrderPrice;
  const totalQuotesSellPriceOfPercentageMargin =
    percentageOfStockOrderPriceMargin + stockOrderPrice;

  let ruleAppliedSentence;

  switch (true) {
    case quotedSellPrice <= minimumSellPrice &&
      quotedSellPrice < stockOrderPrice:
      ruleAppliedSentence =
        "Part sold at or below HMCA base price with Max GP Recovery and 10% maximum handling";
      break;

    case minimumSellPrice < quotedSellPrice &&
      quotedSellPrice < stockOrderPrice:
      ruleAppliedSentence =
        "Parts sold at below stock order price but at higher value than HMCA base price with 10% maximum handling";
      break;

    case stockOrderPrice < quotedSellPrice &&
      quotedSellPrice < totalQuotesSellPriceOfPercentage:
      ruleAppliedSentence =
        "Part sold at above stock order price but with margin below 2.5% and Maximum handling of 10%";
      break;

    case quotedSellPrice > totalQuotesSellPriceOfPercentageMargin:
      ruleAppliedSentence =
        "Part sold at above stock order price with margin above 12.5% in Total";
      break;

    default:
      ruleAppliedSentence = " - ";
  }
};

//View-Quote Grand Total

export const calculateDealerGpPercentage = (
  totalQuotedPriceNum,
  totalRebateAmountNum
) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const daysInCurrentMonth = today.getDate();

  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

  let past12MonthsDays = 0;
  for (let i = 0; i < 12; i++) {
    const year = currentYear - (currentMonth - i < 0 ? 1 : 0);
    const month = (currentMonth - i + 12) % 12;
    past12MonthsDays += daysInMonth(year, month);
  }
  const daysGoneInMonth = daysInCurrentMonth;
  if (totalQuotedPriceNum === 0 || totalRebateAmountNum === 0) {
    return "-";
  }
  const dealerGpPercentage =
    1 -
    (past12MonthsDays + daysGoneInMonth - totalRebateAmountNum) /
      totalQuotedPriceNum;
  return `${(dealerGpPercentage * 100).toFixed(2)}%`;
};

// financial convert

export const formatPercentage = (value) => {
  return value !== undefined && value !== null ? `${value}%` : "0%";
};

export const formatCurrency = (value) => {
  if (value === undefined || value === null || isNaN(Number(value)))
    return "-";
  return `$${Number(value).toLocaleString()}`;
};

export const absolute = (value) => {
  return Math.abs(value);
}

export const formatValue = (
  value,
  prefix = "",
  suffix = "",
  fallback = "-",
  options = {}
) => {
  if (value === undefined || value === null) {
    return fallback;
  }
  const numberValue = Number(value);
  const formattedNumber = numberValue.toLocaleString(undefined, options);
  return `${prefix}${formattedNumber}${suffix}`;
};
