import React, { useState, useEffect, useRef} from "react";
import "../../Assets/Styles/global-styles.css";
import "../../Assets/Styles/fonts.css";
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ArrowLeft from "../../Assets/Images/leftArrowIcon.svg";
import {
  Space,
  Layout,
  Row,
  Col,
  Typography,
  Form,
  DatePicker,
  Input,
} from "antd";
import CustomizedButton from "../../Components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import CommonTable from "../../Components/Table";
import { commonSorter } from "../../Constants/constants";
import { addPart } from "../../Services/userEndpoints";
import { message } from "../../Components/Message";
import CommonModal from "../../Components/Modal";
import moment from "moment";
import {
  getMaxCharsRule,
  onHandleNumericValidation,
  requiredRule,
  zeroValueRule,
} from "../../Constants/formValidation";
import { webService } from "../../Services/web.service";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const EditPart = () => {
  const location = useLocation();
  const { record } = location.state || {};
  const [form] = Form.useForm();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(null);
  const [tableData, setTableData] = useState([record]);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const navigate = useNavigate();
  const initialValuesRef = useRef(record);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        basePrice: record.minimumSellPrice,
        maxRebatePrice: record.maxRebatePrice,
        standardHandlingFeePercentage: record.standardHandlingFeePercentage,
        westernHandlingFeePercentage: record.westernHandlingFeePercentage,
        effectiveStartDate: [
          dayjs(record.effectiveStartDate),
          dayjs(record.effectiveEndDate)
        ],
        effectiveEndDate: dayjs(record.effectiveEndDate),
        partNumber: record.partNumber,
        description: record.description
      });
    }
  }, [record, form]);

  const hasFormChanged = (currentValues) => {
    return JSON.stringify(currentValues) !== JSON.stringify(initialValuesRef.current);
  };
  
  const handleInputChange = (value, key, column) => {
    // form.setFieldsValue({
    //   [column]: value
    // });
    const updatedData = tableData.map((item) => {
      if (item.key === key) {
        return { ...item, [column]: value };
      }
      return item;
    });
    setTableData(updatedData);
  };

  const showDeleteModal = (record) => {
    setDeleteItemIndex(record.id);
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleNavigateBack = () => {
    navigate("/rebate/management");
  }

  const showSaveModal = (record) => {
    setDeleteItemIndex(record.id);
    setIsSaveModalVisible(true);
  };

  const hideSaveModal = () => {
    setIsSaveModalVisible(false);
  };

  const handleDeleteItem = async () => {
    try {
      webService.destroy(`${addPart}${deleteItemIndex}`).then((response) => {
        message.success(response.message);
      }).catch(() => {}).finally(() => {
        setIsDeleteModalVisible(false);
        handleNavigateBack();
      })
    } catch (error) {
      console.error("Error making API call:", error);
      setIsDeleteModalVisible(false);
    }
  };

  const render = (text) => text || "-";
  const columns = [
    {
      title: "List Price",
      dataIndex: "listPrice",
      key: "listPrice",
      align: "right",
      width: "145.57px",
      fixed: "left",
      sorter: commonSorter("listPrice"),
      className: "table-sorter font",
      render: render,
    },
    {
      title: "Stock Order Price",
      dataIndex: "stockPrice",
      key: "stockPrice",
      align: "right",
      width: "145.57px",
      fixed: "left",
      sorter: commonSorter("stockPrice"),
      className: "table-sorter font",
      render: render,
    },
    {
      title: "Base Price",
      dataIndex: "minimumSellPrice",
      key: "minimumSellPrice",
      align: "right",
      width: "145.57px",
      sorter: commonSorter("minimumSellPrice"),
      className: "table-sorter font",
      render: (_, record) => (
        <Form.Item
          className="custom-nomargin"
          name="basePrice"
          
          rules={[requiredRule, zeroValueRule]}
        >
          <Input
            className="edit-fiedls"
            onBlur={() => {
              form.validateFields(["basePrice"]);
            }}
            onKeyPress={(e) =>
              onHandleNumericValidation(e, form, "basePrice", true)
            }
            onChange={(e) =>
              handleInputChange(e.target.value, record.key, "basePrice")
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "Max Rebate Price",
      dataIndex: "maxRebatePrice",
      key: "maxRebatePrice",
      align: "right",
      width: "145.57px",
      sorter: commonSorter("maxRebatePrice"),
      className: "table-sorter font",
      render: (_, record) => (
        <Form.Item
          className="custom-nomargin"
          name="maxRebatePrice"
          
          rules={[
            getMaxCharsRule("Quoted Price", 15),
            requiredRule,
            zeroValueRule
          ]}
        >
          <Input
            defaultValue={record.maxRebatePrice}
            className="edit-fiedls"
            onBlur={() => {
              form.validateFields(["maxRebatePrice"]);
            }}
            onKeyPress={(e) =>
              onHandleNumericValidation(e, form, "maxRebatePrice", true)
            }
            onChange={(e) =>
              handleInputChange(e.target.value, record.key, "maxRebatePrice")
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "Handling Fee (STD)",
      dataIndex: "standardHandlingFeePercentage",
      key: "standardHandlingFeePercentage",
      align: "left",
      width: "145.57px",
      sorter: commonSorter("standardHandlingFeePercentage"),
      className: "table-sorter font",
      render: (_, record) => (
        <Form.Item
          className="custom-nomargin"
          name="standardHandlingFeePercentage"
          
          rules={[requiredRule, zeroValueRule]}
        >
          <Input
            defaultValue={record.standardHandlingFeePercentage}
            className="edit-fiedls"
            onBlur={() => {
              form.validateFields(["standardHandlingFeePercentage"]);
            }}
            onKeyPress={(e) =>
              onHandleNumericValidation(
                e,
                form,
                "standardHandlingFeePercentage",
                true
              )
            }
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                record.key,
                "standardHandlingFeePercentage"
              )
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "Handling Fee (WST)",
      dataIndex: "westernHandlingFeePercentage",
      key: "westernHandlingFeePercentage",
      align: "left",
      width: "145.57px",
      sorter: commonSorter("westernHandlingFeePercentage"),
      className: "table-sorter font",
      render: (_, record) => (
        <Form.Item
          className="custom-nomargin"
          name="westernHandlingFeePercentage"
          
          rules={[requiredRule, zeroValueRule]}
        >
          <Input
            defaultValue={record.westernHandlingFeePercentage}
            className="edit-fiedls"
            onBlur={() => {
              form.validateFields(["westernHandlingFeePercentage"]);
            }}
            onKeyPress={(e) =>
              onHandleNumericValidation(
                e,
                form,
                "westernHandlingFeePercentage",
                true
              )
            }
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                record.key,
                "westernHandlingFeePercentage"
              )
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "Effective Date",
      dataIndex: "effectiveStartDate",
      key: "effectiveStartDate",
      align: "left",
      width: "180px",
      sorter: commonSorter("effectiveStartDate"),
      className: "table-sorter font",
      render: (_, record) => {
        return (
          <Form.Item
            className="custom-nomargin"
            name="effectiveStartDate"
            rules={[
              {
                required: true,
                message: "Please select the effective date range.",
              },
            ]}
          >
            <RangePicker
              defaultValue={[
                dayjs(record.effectiveStartDate, "YYYY-MM-DD"),
                dayjs(record.effectiveEndDate, "YYYY-MM-DD"),
              ]}
              format="YYYY-MM-DD"
              className="edit-fiedls"
              onChange={(dates, dateStrings) => {
                handleInputChange(
                  dateStrings,
                  record.key,
                  "effectiveStartDate"
                );
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "effectiveEndDate",
      key: "effectiveEndDate",
      align: "left",
      width: "145.57px",
      sorter: commonSorter("effectiveEndDate"),
      className: "table-sorter font",
      render: (_, record) => (
        <Form.Item
          className="custom-nomargin"
          name="effectiveEndDate"
          rules={[
            {
              required: true,
              message: "Please select the effective date.",
            },
          ]}
        >
          <DatePicker
            defaultValue={dayjs(record.effectiveEndDate, "YYYY-MM-DD")}
            format="YYYY-MM-DD"
            className="edit-fiedls"
            disabledDate={(current) =>
              current && current.isBefore(dayjs().startOf('day'))
            }
            // disabledDate={(current) =>
            //   current &&
            //   current.isBefore(
            //     moment(record.effectiveEndDate, "YYYY-MM-DD"),
            //     "day"
            //   )
            // }
            onChange={(date, dateString) => {
              handleInputChange(dateString, record.key, "effectiveEndDate");
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "partNumber",
      align: "center",
      width: "73px",
      fixed: "right",
      className: "table-sorter font",
      render: (_, record) => (
        <>
          <span className="">
            <CustomizedButton
              type="danger"
              icon={
                <DeleteOutlined
                  className="quote-delete-btn"
                  onClick={() => {
                    showDeleteModal(record);
                  }}
                />
              }
            ></CustomizedButton>
          </span>
        </>
      ),
    },
  ];

  const convertPayload = (obj) => {
    const compareDateKey =
      record.effectiveStartDate === tableData[0].effectiveStartDate;
    if (compareDateKey) {
      return {
        partNumber: obj.partNumber,
        minimumSellPrice: obj.basePrice ? parseFloat(obj.basePrice) : parseFloat(obj.minimumSellPrice),
        effectiveStartDate: obj.effectiveStartDate,
        effectiveEndDate: obj.effectiveStartDate,
        standardHandlingFeePercentage: parseFloat(
          obj.standardHandlingFeePercentage
        ),
        westernHandlingFeePercentage: parseFloat(
          obj.westernHandlingFeePercentage
        ),
        maxRebatePrice: parseFloat(obj.maxRebatePrice),
      };
    } else {
      return {
        partNumber: obj.partNumber,
        minimumSellPrice: parseFloat(obj.minimumSellPrice),
        effectiveStartDate: obj.effectiveStartDate[0],
        effectiveEndDate: obj.effectiveStartDate[1],
        standardHandlingFeePercentage: parseFloat(
          obj.standardHandlingFeePercentage
        ),
        westernHandlingFeePercentage: parseFloat(
          obj.westernHandlingFeePercentage
        ),
        maxRebatePrice: parseFloat(obj.maxRebatePrice),
      };
    }
  };

  const handleSave = async () => {
    try {
      // Validate all form fields
      const values = await form.validateFields();
      const payload = convertPayload(tableData[0]);
      webService
        .put(`${addPart}${tableData[0].id}`, payload)
        .then((response) => {
          message.success(response?.message);
          handleNavigateBack();
        })
        .catch(() => { })
        .finally(() => {
          setIsSaveModalVisible(false);
        });
    } catch (error) {
      message.error('Please fix the validation errors before saving');
      setIsSaveModalVisible(false);
    }
  };

  return (
    <Form 
    form={form} 
    onFinish={handleSave} 
    onValuesChange={()=>{
      const currentValues = form.getFieldsValue();
      setIsFormChanged(hasFormChanged(currentValues));
    }}
    >
    <div>
      <div className="main-container container">
        <div className="headerforButtons">
          <Space className="header-buttons-export-save" size={16}>
            <img
              className="left-arrow"
              src={ArrowLeft}
              alt=""
              onClick={handleNavigateBack}
            />
            <span className="quote-genrate-number">Edit Part</span>
          </Space>
        </div>
        <div className="">
          <div className="row-col-container">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={8}>
                <Title className="dealer-code lable-width" level={5}>
                  Part Number
                </Title>
                <Form.Item>
                  <Input
                    placeholder="part Number"
                    disabled
                    className="addPart-input-styles disabled-input-fields"
                    value={record.partNumber}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Title className="dealer-code lable-width" level={5}>
                  Part Description
                </Title>
                <Form.Item>
                  <Input
                    placeholder="part Number"
                    disabled
                    className="addPart-input-styles disabled-input-fields"
                    value={record.description}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <div className="table-container">
            <CommonTable dataSource={tableData} columns={columns} />
          </div>
        </div>
      </div>
      <div>
        <Layout.Footer className="footer-container">
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            className="values-container"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Col className="gutter-row new-footer-buttons" span={6}>
              <div className="addPart-modal-footer ">
                <Space>
                  <CustomizedButton
                    className="reset-btn"
                    onClick={handleNavigateBack}
                  >
                    CANCEL
                  </CustomizedButton>
                  <CustomizedButton
                    type="primary"
                    className={isFormChanged ? "addpart-btn apply-btn" : "addpart-btn apply-btn-disabled"}
                    // disabled={!isFormChanged}
                    onClick={showSaveModal}
                  >
                    SAVE
                  </CustomizedButton>
                </Space>
              </div>
            </Col>
          </Row>
        </Layout.Footer>
      </div>
      <CommonModal
        visible={isDeleteModalVisible}
        onCancel={hideDeleteModal}
        onConfirm={handleDeleteItem}
        title="Delete Part"
        showDeleteButton={true}
      >
        <p>Do want to delete the part? </p>
      </CommonModal>

      <CommonModal
        visible={isSaveModalVisible}
        onCancel={hideSaveModal}
        onConfirm={handleSave}
        title="Save the changes"
        showSaveButton={true}
      >
        <p>Do want to save the changes in the part. </p>
      </CommonModal>
    </div>
    </Form>
  );
};
export default EditPart;
