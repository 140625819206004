import { Outlet } from "react-router-dom";
import Dashboard from "../Components/Dashboard/Dashboard";
import QuoteCalculator from "../Pages/Quote-Calculator";
import CreateNewQuote from "../Pages/Quote-Calculator/createNewQuote";
import ViewQuote from "../Pages/Quote-Calculator/viewQuote";
// import RebateSummary from "../Pages/Rebate-Summary";
import RebateSummary from "./../Pages/Parts-Rebate-Summary"
import RebateDetailedReport from "../Pages/Rebate-Detailed-Report";
// import RebateCustomers from "../Pages/Rebate-Customers";
import RebateManagement from "../Pages/Rebate-Management";
import RebateAllowance from "../Pages/Rebate-Allowance";
// import RebateManagementCustomer from "../Pages/Rebate-Management-Customer/rebateManagementCustomer";
import RebateManagementCustomer from "../Pages/RebateManagementCustomer/rebateManagementCustomer";
import QuoteConversion from "../Pages/Reporting/quoteConversion";
import DealerPayments from "../Pages/Reporting/dealerPayments";
import QuoteGenerator from "../Pages/Quote-Calculator/quoteGenerator";
import PartsRebateDetail from "../Pages/Reporting/PartsRebateDetailReport/partsRebateDetail";
import Home from "../Components/Home/Home";
import EditPart from "../Pages/Rebate-Management/editPartScreen";
import AuthRoute from "./Auth";
import Login from "../Pages/Login/Login";
import IsTradeDealer from "./IsTradeDealer";
import IsAdmin from "./IsAdmin";
import Unauthorized from "../Pages/Unauthorized/Unauthorized";
import PartsRebateSummary from "../Pages/Reporting/PartsRebateSummaryReport/PartsRebateSummary";
import DealerRebateManageCustomer from "../Pages/DealerRebateManageCustomer";

const routes = [
  {
    path: "/unauthorized",
    component: <Unauthorized/>
  },
  {
    path: "/app/login/:token",
    component: <Login/>
  },
  {
    path: "/",
    component: <AuthRoute/>,
    routes: [
      {
        path: "/",
        component: <Dashboard />,
        routes: [
          {
            path: "/",
            component: <Home/>,
            exact: true,
          },
          {
            path: "/quote",
            component: <Outlet />,
            routes: [
              {
                path: "list",
                component: <QuoteCalculator />,
              },
              {
                path: "create",
                component: <CreateNewQuote />,
              },
              {
                path: "view/:id",
                component: <ViewQuote />,
              },
              {
                path: "review",
                component: <QuoteGenerator />,
              },
            ],
          },
          {
            path: "/parts/rebate",
            component: <IsTradeDealer />,
            routes: [
              {
                path: "summary",
                component: <RebateSummary />,
              },
              {
                path: "detail",
                component: <RebateDetailedReport />,
                // component: <PartsRebateDetail/>,
              },
              {
                path: "customer",
                // component: <RebateCustomers />,
                component: <DealerRebateManageCustomer />,
              },
            ],
          },
          {
            path: "/rebate",
            component: <IsAdmin />,
            routes: [
              {
                path: "management",
                component: <RebateManagement />,
              },
              {
                path: "management/edit/:id",
                component: <EditPart />,
              },
              {
                path: "allowance",
                component: <RebateAllowance />,
              },
              {
                path: "management/customer",
                component: <RebateManagementCustomer />,
              },
            ],
          },
          {
            path: "reporting",
            component: <IsAdmin />,
            routes: [
              {
                path: "parts/rebate",
                component: <Outlet />,
                routes: [
                  {
                    path: "summary",
                    component: <PartsRebateSummary />,
                  },
                  {
                    path: "detail",
                    component: <PartsRebateDetail />,
                  },
                ],
              },
              {
                path: "quote/conversion",
                component: <QuoteConversion />,
              },
              {
                path: "dealer/payments",
                component: <DealerPayments />,
              },
            ],
          },
        ],
      },
    ]
  }
];

export default routes;
