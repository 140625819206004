import React, { useState, useEffect, useRef } from "react";
import CustomizedButton from "../../Components/Button";
import { Typography, Form, Col, Select, Row } from "antd";
import "../../Assets/Styles/global-styles.css";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import AllQuoteList from "./allQuote";
import {
  allQuotesListApi,
  quoteNumberApi,
  dealershipsCustomers,
} from "../../Services/userEndpoints";
import { paginationConfig } from "../../Components/Pagination";
import { useNavigate } from "react-router-dom";
import "../../Assets/Styles/fonts.css";
import { getQuoteStatus } from "../../Constants/utils";
import CustomerSelect from "../../Components/CustomerSelect/CustomerSelect";
import CustomerIDSelect from "../../Components/CustomerSelect/CustomerIDSelect";
import { webService } from "../../Services/web.service";
import store from "../../store/store";
import { isExecuting } from "../../store/isHTTPRequestSlice";

const { Title } = Typography;
const { Option } = Select;

const QuoteCalculator = () => {
  const navigate = useNavigate();
  const [isAnyDropdownSelected, setIsAnyDropdownSelected] = useState(false);
  const [filteredQuotes, setFilteredQuotes] = useState([]);
  const [quoteStatusValue, setQuoteStatusValue] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [quoteNumberFilter, setQuoteNumberFilter] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const listFilter = useRef({});

  const [listFilterState, setListFilterState] = useState({
    pageNumber: 1,
    pageSize: paginationConfig?.defaultPageSize,
  });

  const [searchState, setSearchState] = useState({
    quoteNumber: {
      onSearch: false,
      dropdownOpen: false,
    },
    customerName: {
      onSearch: false,
      dropdownOpen: false,
    },
    customerId: {
      onSearch: false,
      dropdownOpen: false,
    },
    quoteStatus: {
      onSearch: false,
      dropdownOpen: false,
    },
  });

  const handleCreateNewQuote = () => {
    navigate("/quote/create", { state: { customerFilters } });
  };

  const handlePageChange = (page, size) => {
    const data = {
      ...listFilterState,
      pageNumber: page,
      pageSize: size,
    };

    setListFilterState(data);
    listFilter.current = data;

    getList();
  };

  const handleFilterApply = async () => {
    const data = {
      ...listFilterState,
      pageNumber: 1,
    };

    setListFilterState(data);
    listFilter.current = data;

    getList();
  };

  const handleClearFilter = () => {
    const data = {
      ...listFilterState,
      pageNumber: 1,
      quoteNumber: undefined,
      customerId: undefined,
      quoteStatus: undefined,
    };

    setListFilterState(data);
    listFilter.current = data;

    setIsAnyDropdownSelected(false);

    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    fetchQuoteStatus();
    getQuoteNumberFilters();
    getCustomerFilters();
  }, []);

  const getList = async () => {
    try {
      store.dispatch(isExecuting(true));
      webService
        .get(allQuotesListApi, listFilter.current)
        .then((response) => {
          const quotesData = response?.data?.rows || [];
          const totalAllRecords = response?.data?.total || [];
          setFilteredQuotes(quotesData);
          setTotalRecords(totalAllRecords);
        }).finally(()=>{
          store.dispatch(isExecuting(false));
        })
        .catch(() => {
          setFilteredQuotes([]);
          setTotalRecords([]);
        });
    } catch (error) {
      console.error("Error fetching quoteslist:", error);
    }
  };

  const handleSort = async (columnKey, sortOrder) => {
    listFilter.current = {
      ...listFilter.current,
      orderBy: `${columnKey}:${sortOrder}`,
      pageNumber: 1,
    };

    getList();
  };

  const getCustomerFilters = async () => {
    webService
      .get(`${dealershipsCustomers}`)
      .then((response) => {
        const customerFilter = response?.data?.rows || [];
        setCustomerFilters(customerFilter);
      })
      .catch(() => {
        setCustomerFilters([]);
      });
  };

  const getQuoteNumberFilters = async () => {
    try {
      webService
        .get(quoteNumberApi)
        .then((response) => {
          const getQuoteNumber = response?.data || [];
          setQuoteNumberFilter(getQuoteNumber);
        })
        .catch(() => {
          setQuoteNumberFilter([]);
        });
    } catch (error) {
      console.error("Error fetching QuoteNumber:", error);
    }
  };

  const fetchQuoteStatus = async () => {
    try {
      const quoteStatusDetails = await getQuoteStatus();
      if (quoteStatusDetails) {
        setQuoteStatusValue(quoteStatusDetails);
      } else {
        setQuoteStatusValue([]);
      }
    } catch (error) {
      console.error("Error fetching report types", error);
    }
  };

  const handleOnChange = (name, value) => {
    const data = {
      ...listFilterState,
      [name]: value,
    };

    setListFilterState(data);
    listFilter.current = data;

    const isAnySelected = Object.values(data).some(
      (val) => val !== undefined && val !== ""
    );
    setIsAnyDropdownSelected(isAnySelected);
  };

  const handleSearch = (key, value) => {
    setSearchState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        onSearch: value !== "" || prevState[key].dropdownOpen,
      },
    }));
  };

  const handleDropdownVisibleChange = (key, open) => {
    setSearchState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        dropdownOpen: open,
        onSearch: open || false,
      },
    }));
  };

  return (
    <div>
      <div className="quote-container">
        <span>
          <Title level={4} className="quoteheader">
            Quote Calculator
          </Title>
          <Title level={5} className="quote-info">
            Input information to generate the quote
          </Title>
        </span>
        <div className="generate-container">
          <CustomizedButton
            type="primary"
            className="create-new-quote"
            onClick={handleCreateNewQuote}
          >
            Create new quote
          </CustomizedButton>
        </div>
      </div>
      <div className="main-container">
        <div className="box">
          <div className="row-col-container">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={6}>
                <Title className="customer-id" level={5}>
                  Quote Number
                </Title>
                <Form.Item>
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    value={listFilterState.quoteNumber}
                    onChange={(value) => handleOnChange("quoteNumber", value)}
                    onSearch={(value) => handleSearch("quoteNumber", value)}
                    onDropdownVisibleChange={(open) =>
                      handleDropdownVisibleChange("quoteNumber", open)
                    }
                    suffixIcon={
                      searchState.quoteNumber.onSearch ? (
                        <SearchOutlined className="dropdown-icon" />
                      ) : (
                        <CaretDownOutlined className="dropdown-icon" />
                      )
                    }
                    placeholder="Enter Quote Number"
                    title={false}
                  >
                    {quoteNumberFilter?.map((item) => (
                      <Option key={item.id} value={item?.quoteNumber}>
                        {item?.quoteNumber}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Title className="customer-id" level={5}>
                  Customer Name
                </Title>
                <Form.Item>
                  <CustomerSelect
                    records={customerList}
                    setCustomerList={setCustomerList}
                    handleSelectChange={(value) =>
                      handleOnChange("customerId", value)
                    }
                    selectedValue={listFilterState.customerId}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Title className="customer-id" level={5}>
                  DMS ID
                </Title>
                <Form.Item>
                  <CustomerIDSelect
                    records={customerList}
                    setCustomerList={setCustomerList}
                    handleSelectChange={(value) =>
                      handleOnChange("customerId", value)
                    }
                    selectedValue={listFilterState.customerId}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Title className="customer-id" level={5}>
                  Quote Status
                </Title>
                <Form.Item>
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    value={listFilterState.quoteStatus}
                    onChange={(value) => handleOnChange("quoteStatus", value)}
                    onSearch={(value) => handleSearch("quoteStatus", value)}
                    onDropdownVisibleChange={(open) =>
                      handleDropdownVisibleChange("quoteStatus", open)
                    }
                    suffixIcon={
                      searchState.quoteStatus.onSearch ? (
                        <SearchOutlined className="dropdown-icon" />
                      ) : (
                        <CaretDownOutlined className="dropdown-icon" />
                      )
                    }
                    placeholder="Select Quote Status"
                  >
                    {quoteStatusValue.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}{" "}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="hr-container">
            <div className="quotes-all-list-container">
              <CustomizedButton
                className="reset-btn"
                onClick={handleClearFilter}
              >
                Reset
              </CustomizedButton>
              <CustomizedButton
                type="primary"
                className={
                  isAnyDropdownSelected ? "apply-btn" : "apply-btn-disabled"
                }
                onClick={handleFilterApply}
              >
                Apply
              </CustomizedButton>
            </div>
            <hr className="hr-line" />
          </div>
          <div>
            <Title className="quote-items all-quote-list" level={4}>
              ALL QUOTES LIST
            </Title>
            <div className="total-container">
              <div className="total-text">
                Total:
                <span className="item">
                  {totalRecords > 0 && totalRecords < 10
                    ? `0${totalRecords}`
                    : totalRecords}{" "}
                  item
                  {totalRecords > 1 ? "s" : ""}
                </span>
              </div>
            </div>
            <div className="table-container">
              {filteredQuotes.length >= 0 && (
                <AllQuoteList
                  dataSource={filteredQuotes}
                  getAllList={getList}
                  quoteStatus={quoteStatusValue}
                  onPageChange={handlePageChange}
                  onSort={handleSort}
                  totalRecords={totalRecords}
                  currentPage={listFilterState.pageNumber}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuoteCalculator;
