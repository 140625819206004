import { Drawer, Switch } from "antd";
import React, { useEffect, useState } from "react";
import CustomizedButton from "../../../Components/Button";

const ManageColumnDrawer = ({
  isDrawerOpen = false,
  onCloseDrawer,
  defaultColumns = [],
  additionalColumns = [],
  handleUpdateColumns,
  setOpenDrawer,
}) => {
  const [isOpen, setIsOpen] = useState(isDrawerOpen);
  // const [selectedColumns, setSelectedColumns] = useState(
  //   defaultColumns.map((column) => column.key)
  // );
  const [selectedColumns, setSelectedColumns] = useState([
    ...defaultColumns.map((column) => column.key),
    ...additionalColumns.map((column) => column.key),
  ]);
  const [initialColumns, setInitialColumns] = useState(selectedColumns);
  const [showColumns, setShowColumns] = useState(false);

  const handleCloseDrawer = () => {
    setIsOpen(false);
    setSelectedColumns(initialColumns);
    onCloseDrawer(false);
  };

  const handleSwitchChange = (columnName, checked) => {
    setSelectedColumns((prev) =>
      checked
        ? [...prev, columnName.key]
        : prev.filter((col) => col !== columnName.key)
    );
    setShowColumns(checked ? areAllAdditionalSelected() : false);
  };

  const areAllAdditionalSelected = () => {
    return additionalColumns.every((col) => selectedColumns.includes(col.key));
  };

  const renderColumns = (columns, isDefault = false) => {
    return columns.map((item, index) => (
      <div key={index} className="columns-container">
        <p className="default-columns">{item.title}</p>
        <Switch
          className="switch-styles"
          checked={selectedColumns.includes(item.key)}
          onChange={(checked) => handleSwitchChange(item, checked)}
          disabled={isDefault}
        />
      </div>
    ));
  };

  const handleShowHideSwitch = (checked) => {
    if (checked) {
      setSelectedColumns((prev) => [
        ...prev,
        ...additionalColumns
          .map((col) => col.key)
          .filter((key) => !prev.includes(key)),
      ]);
    } else {
      setSelectedColumns((prev) =>
        prev.filter((col) => !additionalColumns.map((col) => col.key).includes(col))
      );
    }
    setShowColumns(checked);
  };

  const updateColumns = () => {
    const additionalSelectdColumns = [];

    additionalColumns.forEach((column) => {
      if (selectedColumns.includes(column.key)) {
        additionalSelectdColumns.push(column.key);
      }
    });

    handleUpdateColumns(additionalSelectdColumns);
    setInitialColumns(selectedColumns);
    setOpenDrawer(false)
  };

  useEffect(() => {
    // Update the "Select All" switch based on whether all additional columns are selected
    setShowColumns(areAllAdditionalSelected());
  }, [selectedColumns]);

  useEffect(() => {
    setIsOpen(isDrawerOpen);
    if (isDrawerOpen) {
      setInitialColumns([...selectedColumns]); // Capture initial state on open
    }
  }, [isDrawerOpen]);

  return (
    <>
      <Drawer
        title={<span className="manage-column">Manage Columns</span>}
        onClose={handleCloseDrawer}
        open={isOpen}
        className="drawer-container"
      >
        <p className="default-font">Default Columns</p>
        {renderColumns(defaultColumns, true)}
        <hr className="hr-line" />
        <div className="columns-container">
          <p className="showhide-columns">Columns</p>
          <Switch
            checkedChildren={<span className="showhide">Show</span>}
            unCheckedChildren={
              showColumns ? null : <span className="hideshow">Hide</span>
            }
            className="showhide-switch width-height"
            checked={showColumns}
            onChange={handleShowHideSwitch}
          />
        </div>
        {renderColumns(additionalColumns)}
        <hr className="hr-line" />
        <CustomizedButton
          className="update-columns-styles"
          type="primary"
          onClick={updateColumns}
        >
          <span className="updatename-mar"> UPDATE COLUMNS</span>
        </CustomizedButton>
      </Drawer>
    </>
  );
};

export default ManageColumnDrawer;
