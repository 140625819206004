import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Space, Tag, Row, Col, Tabs, Radio, ConfigProvider } from "antd";
import MonthPurchaseIcon from "../../Assets/Images/12 Months Purchase Icon.svg";
import ConversionRateIcon from "../../Assets/Images/Conversion Rate Icon.svg";
import CreditandReturnsIcon from "../../Assets/Images/Credit & Returns Icon.svg";
import ROIGPIcon from "../../Assets/Images/RGPIcon.svg";
import PPRAllowanceMTDIcon from "../../Assets/Images/PPR Allowance MTD Icon.svg";
import ArrowLeft from "../../Assets/Images/leftArrowIcon.svg";
import Export from "../../Assets/Images/Download.svg";
import CustomizedButton from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import AddPart from "./addPart";
import "./index.css";
import "../../Assets/Styles/global-styles.css";
import "../../Layouts/Sidebar/index.css";
import "../../Assets/Styles/fonts.css";
import CommonModal from "../../Components/Modal";
import { message } from "../../Components/Message";
import CustomBreadcrumb from "../../Components/Breadcrumb";
import {
  financialMetricsApi,
  generateNewQuoteApi,
  updateGenerateQuoteApi,
  viewquotes,
} from "../../Services/userEndpoints";
import { formatDate } from "../../Constants/constants";
import { CustomDatePicker } from "../../Components/Datepicker";
import { exportQuotesApi } from "../../Constants/utils";
import Upload from "../../Assets/Images/Upload.svg";
import ImportParts from "../../Components/Modal/ImportParts/ImportParts";
import { QuoteContext } from "./quoteContext";
import EditableTable from "./editableTable";
import dayjs from "dayjs";
import { formatPercentage, formatCurrency } from "../../Constants/calculations";
import { useDispatch, useSelector } from "react-redux";
import {
  add,
  remove,
  insertBulk,
  removeAll,
} from "../../store/quoteItemsSlice";
import HistoricalSalesData from "./historicalSalesData";
import { webService } from "../../Services/web.service";
import { useIsAdmin } from "../../shared/hooks/role";

const QuoteGenerator = ({ view }) => {
  const isAdmin = useIsAdmin();
  const quoteItemsStore = useSelector((state) => state.quoteItems);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    quoteNumber,
    quoteStatusName,
    quoteId,
    quoteItemsData,
    customerSingleRecord,
    selectedCustomerID,
    quoteFormData
  } = location.state || {};
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [backModalVisible, setBackModalVisible] = useState(false);
  const [discardModalVisible, setDiscardModalVisible] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [hasInputChanges, setHasInputChanges] = useState(false);
  const [viewQuoteVal, setViewQuoteVal] = useState([]);
  const [mode, setMode] = useState("quoteDetails");
  const { setTab } = useContext(QuoteContext);
  const [quoteAllItems, setQuoteAllItems] = useState(quoteItemsData);
  const [isImportPartsModalVisible, setIsImportPartsModalVisible] =
    useState(false);
  const [importedFile, setImportedFile] = useState(null);
  const [showAddNewBtn, setShowAddNewBtn] = useState(true);
  const [showAddNew, setShowAddNew] = useState(false);
  const { quoteParts, setQuoteParts, setViewScreen } = useContext(QuoteContext);
  const [dateOnly, setDateOnly] = useState(null);
  const [customerID, setCustomerID] = useState("");
  const [showAddPart, setShowAddPart] = useState(false);
  const [metricsVal, setMetricsVal] = useState([]);
  setViewScreen(view);

  setTab(mode);
  // CANCEL
  const onCancelModal = () => {
    setExportModalVisible(false);
    setDiscardModalVisible(false);
    setBackModalVisible(false);
    setSaveModalVisible(false);
  };
  // MODALOPEN
  const handleClickExport = () => {
    setExportModalVisible(true);
  };
  const handleClickDiscard = () => {
    setDiscardModalVisible(true);
  };
  const handleInputChanges = (e) => {
    setHasInputChanges(true);
  };
  const handleClickLeftArrow = () => {
    if (isSaveEnabled === true) {
      setBackModalVisible(true);
    } else if (hasInputChanges) {
      setBackModalVisible(true);
    } else {
      navigate(-1);
    }
  };

  const handleClickSave = () => {
    setSaveModalVisible(true);
  };

  // CONFIRM
  const handleConfirmExport = async () => {
    try {
      if (view) {
        await exportQuotesApi(quoteId);
      }
      message.success(`Quote Number: ${quoteNumber} is exported.`);
      setTimeout(() => {
        setExportModalVisible(false);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleConfirmDiscard = () => {
    message.success("Quote discarded");
    setDiscardModalVisible(false);
    setTimeout(() => {
      setDiscardModalVisible(false);
      dispatch(removeAll());
      navigate(-1);
    }, 1000);
  };

  const handleConfirmSave = async () => {
    // debugger
    const payload = {
      items: quoteItemsStore.map((part) => ({
        partNumber: part?.partNumber ? String(part.partNumber) : "",
        quantity: part?.quantity,
        quotedPrice: part?.quotedSellPrice === null ? 0 : part?.quotedSellPrice,
      })),
    };
    if (dateOnly) {
      payload.dueDate = dayjs(dateOnly).format("YYYY-MM-DD");
    }
    if (!view) {
      payload.customerId = customerSingleRecord?.id;
    }
    
    if(isAdmin) {
      payload.dealershipId = quoteFormData?.dealershipId;
    }

    try {
      if (view) {
        webService
          .put(`${updateGenerateQuoteApi}/${quoteId}`, payload)
          .then((response) => {
            message.success(response?.message);
            navigate("/quote/list");
          })
          .catch(() => { });
      } else {
        webService
          .post(generateNewQuoteApi, payload)
          .then((response) => {
            message.success(response?.message);
            navigate("/quote/list");
          })
          .catch(() => { });
      }
    } catch (error) {
      console.error("Error fetching quoteslist:", error);
    } finally {
      setSaveModalVisible(false);
      setIsSaveEnabled(false);
    }
  };

  useEffect(() => {
    if (viewQuoteVal?.dueDate) {
      setDateOnly(viewQuoteVal.dueDate.split("T")[0]);
    } else {
      setDateOnly(null);
    }
  }, [viewQuoteVal]);

  const handleDateChange = (newDate) => {
    setDateOnly(newDate);
    setIsSaveEnabled(true);
  };

  const formatDateToday = () => {
    return formatDate(new Date());
  };
  const generateQuoteValues = [
    ...(view
      ? [
        {
          name: "Quote Number",
          value: viewQuoteVal?.quoteNumber || "-",
        },
      ]
      : []),
    {
      name: "Quote Creator",
      value:
        (view
          ? viewQuoteVal?.createdBy?.name
          : customerSingleRecord?.userName) || "-",
    },
    {
      name: "Customer Name",
      value:
        (view ? viewQuoteVal?.customer?.name : customerSingleRecord?.name) ||
        "-",
    },
    {
      name: "ABN",
      value:
        (view ? viewQuoteVal?.customer?.ABN : customerSingleRecord?.ABN) || "-",
    },
    {
      name: "Quote Date",
      value:
        (view ? formatDate(viewQuoteVal?.createdOn) : formatDateToday()) || "-",
    },
    {
      name: "Due Date",
      value: (
        <>
          <span className="quote-generate-datePicker">
            <CustomDatePicker
              value={dateOnly}
              format="DD/MM/YYYY"
              onChange={handleDateChange}
            />
          </span>
        </>
      ),
    },
  ];
  const cardData = [
    {
      label: "12 MONTHS PURCHASE",
      amount: formatCurrency(metricsVal?.twelveMonthsPurchase),
      icon: MonthPurchaseIcon,
    },
    {
      label: "CONVERSION RATE",
      amount: formatPercentage(metricsVal?.conversionRate),
      icon: ConversionRateIcon,
    },
    {
      label: "CREDITS & RETURNS",
      amount: formatPercentage(metricsVal?.credits_and_returns),
      icon: CreditandReturnsIcon,
    },
    {
      label: "ROI GP%",
      amount: formatPercentage(metricsVal?.ROIGP),
      icon: ROIGPIcon,
    },
    {
      label: "ALLOWANCE MTD",
      amount: formatCurrency(metricsVal?.allowanceMTD),
      icon: PPRAllowanceMTDIcon,
    },
  ];

  const getQuoteStatusClassName = () => {
    switch (quoteStatusName) {
      case "Pending":
        return "pending-clr";
      case "Approved":
        return "approved-clr";
      case "Deleted":
        return "delete-clr";
      case "Invoiced":
        return "invoiced-color";
      case "Lost Sales":
        return "lostsale-clr";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (quoteId && view) {
      dispatch(removeAll());
      getViewQuoteItem(quoteId);
    }
  }, [quoteId, view]);

  useEffect(() => {
    if (customerID && view) {
      getFinancialMetricsValues(customerID);
    } else if (!view) {
      getFinancialMetricsValues(selectedCustomerID);
    }
  }, [view, customerID, selectedCustomerID]);

  useEffect(() => {
    return () => {
      dispatch(removeAll());
    };
  }, []);

  const getViewQuoteItem = async (quoteId) => {
    try {
      webService
        .get(`${viewquotes}/${quoteId}`)
        .then((response) => {
          const viewQuoteDetails = response?.data || [];
          const viewQuoteParts = response?.data.quoteParts || [];
          viewQuoteParts.forEach((part) => {
            part.partNumber = part.part.partNumber;
            part.description = part.part.description;

            part["part"].partNumber = part?.part.partNumber;
            part["part"].description = part?.part.description;

            dispatch(add({ data: part, selectedPart: part.part }));
          });
          const viewQuoteCustomerId = response?.data?.customerId_FK || [];
          setViewQuoteVal(viewQuoteDetails);
          setQuoteParts(viewQuoteParts);
          setCustomerID(viewQuoteCustomerId);
        })
        .catch(() => {
          setViewQuoteVal([]);
        });
    } catch (error) {
      console.error("Error fetching quoteslist:", error);
    }
  };

  const getFinancialMetricsValues = async (customerId) => {
    const payload = {
      customerId
    };
    try {
      webService
        .post(financialMetricsApi, payload)
        .then((response) => {
          setMetricsVal(response?.data);
        })
        .catch(() => {
          setMetricsVal([]);
        });
    } catch (error) {
      console.error("Error fetching financial metrics:", error);
    }
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  // breadcrumb
  const items = [
    {
      title: (
        <span className="bread-crumb-main" onClick={handleClickLeftArrow}>
          {view ? "Quote Calculator" : "All Quotes List"}
        </span>
      ),
    },
    {
      title: (
        <span className="bread-crumb-currentpage">
          {view ? "View Quote" : "Create New Quote"}
        </span>
      ),
    },
  ];

  // Actions Buttons
  const handleDeleteRow = (index) => {
    dispatch(remove(index));
    setIsSaveEnabled(true);
  };

  const handleImportFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setImportedFile(e.target.files[0]);
    setIsImportPartsModalVisible(true);
  };

  const handleHideImportPartsModal = () => {
    setIsImportPartsModalVisible(false);
    fileInputRef.current.value = "";
  };

  const handleGetImportedRecords = (data) => {
    if (Array.isArray(data)) {
      if (view) {
        setQuoteParts([...data, ...quoteParts]);
        dispatch(insertBulk(data));
        setIsSaveEnabled(true);
      } else {
        setQuoteAllItems([...data, ...quoteItemsData]);
        dispatch(insertBulk(data));
        setIsSaveEnabled(true);
      }
    } else {
      console.error(
        "Received non-iterable data in handleGetImportedRecords:",
        data
      );
    }
  };

  const handleAddPartModalData = ({ values, selectedPart }) => {
    if (view) {
      const updatedQuoteParts = [values, ...quoteParts];
      setQuoteParts(updatedQuoteParts);
      dispatch(add({ data: values, selectedPart }));
      setIsSaveEnabled(true);
    } else {
      dispatch(add({ data: values, selectedPart }));
      setIsSaveEnabled(true);
    }
  };
  useEffect(() => {
    setQuoteAllItems(quoteItemsStore);
  }, [quoteItemsStore]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === "/quote/review") {
      setIsSaveEnabled(true);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const deleteQuotePart = (index) => {
    dispatch(remove(index));
    setIsSaveEnabled(true);
  };

  const handleBtnShow = () => {
    setShowAddNewBtn(false);
    setShowAddPart(true);
    setShowAddNew(true);
  };

  return (
    <>
      <div>
        <CustomBreadcrumb items={items} />
        <div className="headerforButtons">
          <Space className="header-buttons-export-save" size={16}>
            <img
              className="left-arrow"
              src={ArrowLeft}
              alt=""
              onClick={handleClickLeftArrow}
            />
            {view && (
              <span className="quote-genrate-number">
                Quote Number: <> {quoteNumber}</>
              </span>
            )}
            {view && (
              <Tag
                bordered
                className={`displays-alls-status ${getQuoteStatusClassName()}`}
              >
                <span className="displays-centers-alls">{quoteStatusName}</span>
              </Tag>
            )}
          </Space>
          <Space className="header-buttons-export-save" size={16}>
            {view && (
              <CustomizedButton
                className="export-button"
                type="Outlined"
                icon={Export}
                onClick={handleClickExport}
              >
                Export
              </CustomizedButton>
            )}
            {!view && (
              <div className="discard-wrapper ">
                <CustomizedButton
                  className="discard-button"
                  type="Outlined"
                  onClick={handleClickDiscard}
                >
                  Discard
                </CustomizedButton>
              </div>
            )}

            {view ? (
              <CustomizedButton
                type="primary"
                className={
                  view && isSaveEnabled && quoteItemsStore
                    ? "save-button"
                    : "save-button-disabled"
                }
                onClick={handleClickSave}
              >
                Save
              </CustomizedButton>
            ) : (
              <CustomizedButton
                type="primary"
                className="save-button"
                onClick={handleClickSave}
              >
                Save
              </CustomizedButton>
            )}
          </Space>
        </div>
        <div className="quote-generate-container">
          <div className="cards-container">
            {cardData.map((data, index) => (
              <div key={index} className="each-card">
                <div className="cards-inside-data">
                  <span className="label-inside-card">{data.label}</span>
                  <span className="label-amount-card">{data.amount}</span>
                </div>
                <div className="icon-container">
                  <img src={data.icon} alt={data.label} />
                </div>
              </div>
            ))}
          </div>
          <div style={{ marginTop: "10px" }} className="container-styles">
            <ConfigProvider wave={{ disabled: true }}>
              <Radio.Group onChange={handleModeChange} value={mode}>
                <Radio.Button
                  value="quoteDetails"
                  className="quotedetails-tab-button"
                  style={{ width: "147px" }}
                >
                  Quote Details
                </Radio.Button>
                {!view && (
                  <Radio.Button value="historical" style={{ width: "207px" }}>
                    Historical Sales Data
                  </Radio.Button>
                )}
              </Radio.Group>
            </ConfigProvider>
            <Tabs defaultActiveKey="1" tabPosition="top">
              {mode === "quoteDetails" && (
                <div className="quote-details-table">
                  <div className="metrics-wrapper">
                    <div className="financial-metrics">
                      <Row
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        className="horizontal-panel-wrapper"
                      >
                        {generateQuoteValues.map((item, index) => (
                          <Col
                            key={index}
                            className="gutter-row"
                            span={4}
                            style={{
                              marginTop:
                                index !== generateQuoteValues.length - 1
                                  ? "10px"
                                  : "0",
                            }}
                          >
                            <div className="quote-panel-data">
                              <span className="quote-genrate-metrics-names">
                                {item.name}
                              </span>
                              <span className="quote-genrate-metrics-values">
                                {item.value}
                              </span>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    {showAddPart && (
                      <div style={{ marginTop: "5px" }}>
                        <AddPart
                          setShowAddNewBtn={setShowAddNewBtn}
                          setShowAddPart={setShowAddPart}
                          showAddNew={showAddNew}
                          onAddPartData={handleAddPartModalData}
                        />
                      </div>
                    )}
                  </div>

                  <div className="total-container">
                    <div className="total-text">
                      {view ? (
                        <>
                          Total:
                          <span className="item">
                            {`${quoteItemsStore.length} item(s)`}
                          </span>
                        </>
                      ) : (
                        <>
                          Total:
                          <span className="item">
                            {`${quoteItemsStore.length} item(s)`}
                          </span>
                        </>
                      )}
                    </div>
                    {showAddNewBtn && (
                      <div className="action-btn-container">
                        <CustomizedButton
                          className="import-file-btn import-save-quote "
                          icon={Upload}
                          onClick={handleImportFile}
                        >
                          Import File
                        </CustomizedButton>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept=".xlsx,.xls"
                          onChange={handleFileChange}
                        />
                        <CustomizedButton
                          type="primary"
                          className="addpart-btn apply-btn"
                          onClick={handleBtnShow}
                        >
                          Add Part
                        </CustomizedButton>
                      </div>
                    )}
                  </div>
                  <div className="quote-table-container">
                    {view ? (
                      <EditableTable
                        // viewQuote={retrievedQuoteParts}
                        viewScreen={view}
                        deleteRecord={deleteQuotePart}
                        setIsSaveEnabled={setIsSaveEnabled}
                        handleInputChanges={handleInputChanges}
                        setHasInputChanges={setHasInputChanges}
                      />
                    ) : (
                      <EditableTable
                        quoteItemsData={quoteAllItems}
                        onDeleteRow={handleDeleteRow}
                        generateQuote={true}
                        setIsSaveEnabled={setIsSaveEnabled}
                        handleInputChanges={handleInputChanges}
                        setHasInputChanges={setHasInputChanges}
                      />
                    )}
                  </div>
                </div>
              )}
              {mode === "historical" && (
                <div className="history-content">
                  <HistoricalSalesData
                    selectedCustomerID={selectedCustomerID}
                  />
                </div>
              )}
            </Tabs>
          </div>
        </div>
      </div>
      <ImportParts
        isVisible={isImportPartsModalVisible}
        handleClose={handleHideImportPartsModal}
        file={importedFile}
        handleGetImportedRecords={handleGetImportedRecords}
      />
      <CommonModal
        id=":rh:"
        visible={
          exportModalVisible ||
          discardModalVisible ||
          backModalVisible ||
          saveModalVisible
        }
        onCancel={onCancelModal}
        onConfirm={
          exportModalVisible
            ? handleConfirmExport
            : saveModalVisible
              ? handleConfirmSave
              : handleConfirmDiscard
        }
        title={
          exportModalVisible
            ? "Export Report"
            : discardModalVisible
              ? "Discard Quote"
              : saveModalVisible
                ? "Save Quote"
                : "Cancel Quote"
        }
        showExportButton={exportModalVisible}
        showDiscardButton={discardModalVisible}
        showBackButton={backModalVisible}
        showSaveButton={saveModalVisible}
      >
        {exportModalVisible ? (
          <p className="export-message">
            Export Part Rebate Detail in XLSX format
          </p>
        ) : discardModalVisible ? (
          <p className="export-message">Would you like to discard the Quote.</p>
        ) : saveModalVisible ? (
          <p className="export-message">
            Do you want to save the changes in the Quote {view && quoteNumber}?
          </p>
        ) : (
          <p className="export-message">
            Do you want to exit without saving the quote.
          </p>
        )}
      </CommonModal>
    </>
  );
};
export default QuoteGenerator;
