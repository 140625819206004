import { useState, useContext } from "react";
import { QuoteContext } from "../Pages/Quote-Calculator/quoteContext";

export const useFormData = () => {
  const { dealershipDetails, customerFilters } = useContext(QuoteContext);
  const [isAnyDropdownSelected, setIsAnyDropdownSelected] = useState(false);
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const [formData, setFormData] = useState({
    region: 'Dealer Specific Report',
    dealerCode: undefined,
    dealership: undefined,
    dealershipId: undefined,
    dealerType: undefined,
    quoteStatus: undefined,
    partNumber: undefined,
    showValues: undefined,
    customerName: undefined,
    dmsId: undefined,
  });
  const [searchState, setSearchState] = useState({
    region: {
      onSearch: false,
      dropdownOpen: false,
    },
    dealerCode: {
      onSearch: false,
      dropdownOpen: false,
    },
    dealership: {
      onSearch: false,
      dropdownOpen: false,
    },
    dealerType: {
      onSearch: false,
      dropdownOpen: false,
    },
    quoteStatus: {
      onSearch: false,
      dropdownOpen: false,
    },
    partNumber: {
      onSearch: false,
      dropdownOpen: false,
    },
    customerName: {
      onSearch: false,
      dropdownOpen: false,
    },
    dmsId: {
      onSearch: false,
      dropdownOpen: false,
    },
  });

  const handleonChange = (fieldName, value) => {
    let selectedDealer = null;
    let selectedCustomer = null;

    

    if (fieldName === "dealership") {
      selectedDealer = dealershipDetails.find(
        (dealer) => dealer.name === value
      );
    } else if (fieldName === "dealerCode") {
      selectedDealer = dealershipDetails.find(
        (dealer) => dealer.code === value
      );
    }

    if (fieldName === "customerName") {
      selectedCustomer = customerFilters.find(
        (customer) => customer.name === value
      );
    } else if (fieldName === "dmsId") {
      selectedCustomer = customerFilters.find(
        (customer) => customer.id === value
      );
    }

    let formDataUpdate = {
      ...formData,
      [fieldName]: value,
      ...(selectedDealer &&
        fieldName === "dealership" && {
        dealerCode: selectedDealer.code,
      }),
      ...(selectedDealer &&
        fieldName === "dealerCode" && {
        dealership: selectedDealer.name,
      }),
      ...(selectedCustomer &&
        fieldName === "customerName" && {
        dmsId: selectedCustomer.id,
      }),
      ...(selectedCustomer &&
        fieldName === "dmsId" && {
        customerName: selectedCustomer.name,
      }),
    };

    if (fieldName === "region" && value !== "Dealer Specific Report") {
      formDataUpdate = {
        ...formDataUpdate,
        dealershipId: undefined,
      };
    }

    setFormData(formDataUpdate);

    const isOnlyRegionSelected =
      formDataUpdate.region === "Dealer Specific Report" &&
      Object.entries(formDataUpdate).every(
        ([key, val]) => key === "region" || (val === undefined || val === "")
      );

    const isAnySelected = !isOnlyRegionSelected &&
      Object.values(formDataUpdate).some(
        (val) => val !== undefined && val !== ""
      );

    setIsAnyDropdownSelected(isAnySelected);
  };

  const handleChange = (dates, formattedDates) => {
    const isAnySelected = Object.values(dates, formattedDates).some(
      (val) => val !== undefined && val !== ""
    );
    setIsAnyDropdownSelected(isAnySelected);
    setSelectedDates(dates);
  };

  const handleSearch = (key, value) => {
    setSearchState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        onSearch: value !== "" || prevState[key].dropdownOpen,
      },
    }));
  };

  const handleDropdownVisibleChange = (key, open) => {
    setSearchState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        dropdownOpen: open,
        onSearch: open || false,
      },
    }));
  };

  return {
    formData,
    isAnyDropdownSelected,
    searchState,
    selectedDates,
    setSelectedDates,
    setFormData,
    setIsAnyDropdownSelected,
    handleonChange,
    handleChange,
    handleSearch,
    handleDropdownVisibleChange,
  };
};
