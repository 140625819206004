import React, { useEffect, useState, useContext } from "react";
import "../../Assets/Styles/global-styles.css";
import CommonTable from "../../Components/Table";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import ExportIcon from "../../Assets/Icons/ExportIcon.svg";
import infoCircle from "../../Assets/Icons/InfoCircle.svg";
import CustomizedButton from "../../Components/Button";
import {
  quoteConversionApi,
  quoteConversionExport,
} from "../../Services/userEndpoints";
import { Typography, Select, Form, Col, Row, Pagination, Input } from "antd";
import "../../Assets/Styles/fonts.css";
import CommonModal from "../../Components/Modal";
import { message } from "../../Components/Message";
import { RangeDateRangePicker } from "../../Components/Datepicker";
import { commonSorter } from "../../Constants/constants";
import { render, renderDollar } from "../../Constants/constants";
import { paginationConfig } from "../../Components/Pagination";
import { getQuoteStatus, exportData } from "../../Constants/utils";
import { RegionComponent } from "../../Constants/commonRegion";
import { dealerTypeList, sortDirections } from "../../Constants/commonObject";
import { QuoteContext } from "../Quote-Calculator/quoteContext";
import { useFormData } from "../../Constants/commonFormdata";
import { webService } from "../../Services/web.service";
import store from "../../store/store";
import { isExecuting } from "../../store/isHTTPRequestSlice";
const { Title } = Typography;

const QuoteConversion = () => {
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [quoteConversionList, setQuoteConversionList] = useState([]);
  const [pageSize, setPageSize] = useState(paginationConfig?.defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const { regionTypes, dealershipDetails } = useContext(QuoteContext);
  const [quoteStatusValue, setQuoteStatusValue] = useState([]);
  const [columnSortDirections, setColumnSortDirections] = useState({});

  const {
    formData,
    isAnyDropdownSelected,
    searchState,
    setSelectedDates,
    selectedDates,
    handleChange,
    handleonChange,
    handleSearch,
    setIsAnyDropdownSelected,
    setFormData,
    handleDropdownVisibleChange,
  } = useFormData();

  const columns = [
    {
      title: "Dealer Code",
      dataIndex: "code",
      key: "code",
      align: "left",
      width: "150px",
      sorter: commonSorter("code"),
      className: "table-sorter font",
      render: render,
    },
    {
      title: "Dealership Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "262px",
      sorter: commonSorter("name"),
      className: "table-sorter font",
      render: render,
    },
    {
      title: "Dealer Type",
      dataIndex: "dealerType",
      key: "dealerType",
      align: "left",
      width: "233.33px",
      className: "table-sorter font",
      render: (text, record) => {
        const getDealerType = (dealer) => {
          const dealershipType = dealerTypeList?.find(
            (type) => type.id === dealer
          );
          return dealershipType ? dealershipType?.name : "-";
        };
        const rebateDealerType = getDealerType(record?.dealerType);
        return <span>{rebateDealerType}</span>;
      },
    },
    {
      title: "Quote Status",
      dataIndex: "quoteStatus",
      key: "quoteStatus",
      align: "left",
      width: "160px",
      className: "table-sorter font",
      render: (text, record) => {
        const getStatusName = (statusId) => {
          const status = quoteStatusValue.find(
            (status) => status.id === statusId
          );
          return status ? status.name : "-";
        };
        const quoteStatusName = getStatusName(record.quoteStatus);
        return <span>{quoteStatusName}</span>;
      },
    },
    {
      title: "Total Sales",
      dataIndex: "totalSales",
      key: "totalSales",
      align: "right",
      width: "233.33px",
      sorter: commonSorter("totalSales"),
      className: "table-sorter font",
      render: renderDollar,
    },
    {
      title: "Total Rebate Sales",
      dataIndex: "totalRebateSales",
      key: "totalRebateSales",
      align: "right",
      width: "233.33px",
      sorter: commonSorter("totalRebateSales"),
      className: "table-sorter font",
      render: renderDollar,
    },
  ];

  const handleClickExport = () => {
    setExportModalVisible(true);
  };
  const onCancelModal = () => {
    setExportModalVisible(false);
  };
  useEffect(() => {
    if (!isAnyDropdownSelected) {
      getQuoteConversion();
    } else {
      filterQuoteConversion();
    }
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchQuoteStatus();
  }, []);

  // Quote Status
  const fetchQuoteStatus = async () => {
    try {
      const quoteStatusDetails = await getQuoteStatus();
      if (quoteStatusDetails) {
        setQuoteStatusValue(quoteStatusDetails);
      } else {
        setQuoteStatusValue([]);
      }
    } catch (error) {
      console.error("Error fetching report types", error);
    }
  };
  const handleFilterNewReport = async () => {
    setQuoteConversionList([]);
    setTotalRecords(0);
    currentPage > 1 ? setCurrentPage(1) : filterQuoteConversion();
  };

  const generatePayload = (selectedDates, includePageData = false) => {
    const { region, dealerType, quoteStatus, dealershipId } = formData;
    const payload = {};
    const statusId = quoteStatusValue.find(
      (status) => status.name === quoteStatus
    );
    const reportTypesId = regionTypes.find((report) => report.name === region);
    const dealerTypeId = dealerTypeList.find(
      (type) => type.name === dealerType
    );
    if (region) payload.regionId = reportTypesId?.id;
    if (quoteStatus) payload.quoteStatus = statusId?.id;
    if (dealerType) payload.dealerType = dealerTypeId?.id;
    if (dealershipId) payload.dealershipId = dealershipId;
    if (selectedDates && selectedDates[0]) {
      payload.dateStart = selectedDates[0].format("YYYY-MM-DD");
    }
    if (selectedDates && selectedDates[1]) {
      payload.dateEnd = selectedDates[1].format("YYYY-MM-DD");
    }
    if (includePageData) {
      payload.pageNumber = currentPage;
      payload.pageSize = pageSize;
    }
    return payload;
  };
  // Fetch Data
  const fetchQuoteConversion = async (payload) => {
    try {
      store.dispatch(isExecuting(true));
      webService.post(quoteConversionApi, payload).then((response) => {
        const data = response.data || {};
        const quoteConversion = data.rows || [];
        const totalAllRecords = data.total || 0;
        setQuoteConversionList(quoteConversion);
        setTotalRecords(totalAllRecords);
      }).catch(() => {
        setQuoteConversionList([]);
        setTotalRecords(0);
      }).finally(() => {
        store.dispatch(isExecuting(false));
      })
    } catch (error) {
      console.error("Error fetching quote conversion data:", error);
    }
  };
  //  Get List
  const getQuoteConversion = async () => {
    const payload = {
      pageNumber: currentPage,
      pageSize: pageSize,
    };
    await fetchQuoteConversion(payload);
  };
  //  Filter Api
  const filterQuoteConversion = async () => {
    const payload = generatePayload(selectedDates, true);
    await fetchQuoteConversion(payload);
  };
  // Sort
  const handleSort = async (columnKey, sortOrder) => {
    const tableSort = `${columnKey}:${sortOrder}`;
    const payload = {
      orderBy: tableSort,
      pageNumber: currentPage,
      pageSize: pageSize,
    };
    await fetchQuoteConversion(payload);
  };
  const handleColumnSort = (column) => {
    if (["quoteStatus", "dealerType"].includes(column.dataIndex)) {
      return;
    }
    const currentSortDirection = columnSortDirections[column.dataIndex] || null;
    const nextSortDirection =
      currentSortDirection === "ascend"
        ? "descend"
        : currentSortDirection === "descend"
        ? null
        : "ascend";
    const newColumnSortDirections = {
      ...columnSortDirections,
      [column.dataIndex]: nextSortDirection,
    };
    setColumnSortDirections(newColumnSortDirections);
    nextSortDirection === null
      ? getQuoteConversion()
      : handleSort(column.dataIndex, sortDirections[nextSortDirection]);
  };
  // Export QuoteConversion Api
  const handleConfirmExport = async () => {
    const payload = isAnyDropdownSelected
      ? generatePayload(selectedDates)
      : null;
    await exportData(quoteConversionExport, payload);
    message.success("Quote Conversion Report is exported.");
    setTimeout(() => {
      setExportModalVisible(false);
    }, 2000);
  };

  // Pagination
  const onShowSizeChange = (current, size) => {
    setCurrentPage(current);
    setPageSize(size);
  };

  const onChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleClearFilter = () => {
    if (!isAnyDropdownSelected) {
      return;
    }
    setSelectedDates([null, null]);
    setFormData({ 
      region: 'Dealer Specific Report',
      dealerCode: undefined,
      dealership: undefined,
      dealershipId: undefined,
      dealerType: undefined,
      quoteStatus: undefined,
      partNumber: undefined,
      showValues: undefined,
      customerName: undefined,
      dmsId: undefined, });
    setCurrentPage(1);
    getQuoteConversion();
    setIsAnyDropdownSelected(false);
  };
  const reportType = regionTypes.find(
    (report) => report?.name === formData.region
  );
  const dropdownRender = (menu) => (
    <div>
      <div className=" dropdown-container">
        <p className="dropdown-partnumber-heading">Code</p>
        <p className="dropdown-description-heading">Dealership Name</p>
      </div>
      {menu}
    </div>
  );
  return (
    <div>
      <div className="main-container">
        <div className="box">
          <div className="row-col-container">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={8}>
                <Title className="customer-id summary-dropdown" level={5}>
                  Report Type
                </Title>
                <Form.Item>
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    value={formData.region}
                    onChange={(value) => handleonChange("region", value)}
                    onSearch={(value) => handleSearch("region", value)}
                    onDropdownVisibleChange={(open) =>
                      handleDropdownVisibleChange("region", open)
                    }
                    suffixIcon={
                      searchState.region.onSearch ? (
                        <SearchOutlined className="dropdown-icon" />
                      ) : (
                        <CaretDownOutlined className="dropdown-icon" />
                      )
                    }
                    placeholder="Select Region"
                  >
                    {regionTypes?.map((item) => (
                      <Select.Option key={item.id} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Title className="customer-id summary-dropdown" level={5}>
                  Dealer Code / Name
                </Title>
                <Form.Item>
                  {reportType?.name === "Dealer Specific Report" ? (
                    <Select
                      showSearch
                      className="custom-select custom-placeholder-text"
                      value={formData.dealershipId}
                      onChange={(value) => {
                        const dealershipId = value.split("-")[0];
                        handleonChange("dealershipId", dealershipId);
                      }}
                      onSearch={(value) => handleSearch("dealerCode", value)}
                      onDropdownVisibleChange={(open) =>
                        handleDropdownVisibleChange("dealerCode", open)
                      }
                      suffixIcon={
                        searchState.dealerCode.onSearch ? (
                          <SearchOutlined className="dropdown-icon" />
                        ) : (
                          <CaretDownOutlined className="dropdown-icon" />
                        )
                      }
                      placeholder="Select Dealer"
                      filterOption={(input, option) => {
                        const code = option.props.code;
                        const name = option.props.name;
                        return (
                          code?.toLowerCase().includes(input?.toLowerCase()) ||
                          name?.toLowerCase().includes(input?.toLowerCase())
                        );
                      }}
                      optionFilterProp="children"
                      dropdownRender={dropdownRender}
                    >
                      {dealershipDetails?.map((item) => (
                        <Select.Option
                          key={item?.id}
                          value={`${item?.id}`}
                          code={item?.code}
                          name={item?.name}
                        >
                          <div className="options">
                            <p>{item?.code}</p>
                            <p className="elipse-width options-description">
                              {item?.name}
                            </p>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      className="input-style"
                      disabled
                      placeholder="Select Dealer"
                    />
                  )}
                </Form.Item>
              </Col>
              {/* <Col className="gutter-row" span={8}>
                <Title className="customer-id summary-dropdown" level={5}>
                  Dealership Name
                </Title>
                <Form.Item>
                  {reportType?.name === "Dealer Specific Report" ? (
                    <Select
                      showSearch
                      className="custom-select custom-placeholder-text"
                      value={formData.dealership}
                      onChange={(value) => {
                        const dealerShip = value.split(/-(.*)/)[1];
                        handleonChange("dealership", dealerShip.trim());
                      }}
                      onSearch={(value) => handleSearch("dealership", value)}
                      onDropdownVisibleChange={(open) =>
                        handleDropdownVisibleChange("dealership", open)
                      }
                      suffixIcon={
                        searchState.dealership.onSearch ? (
                          <SearchOutlined className="dropdown-icon" />
                        ) : (
                          <CaretDownOutlined className="dropdown-icon" />
                        )
                      }
                      placeholder="Select Dealership Name"
                      filterOption={(input, option) => {
                        const code = option.props.code;
                        const name = option.props.name;
                        return (
                          code?.toLowerCase().includes(input?.toLowerCase()) ||
                          name?.toLowerCase().includes(input?.toLowerCase())
                        );
                      }}
                      optionFilterProp="children"
                      dropdownRender={dropdownRender}
                    >
                      {dealershipDetails?.map((item) => (
                        <Select.Option
                          key={item?.id}
                          value={`${item?.code}-${item?.name}`}
                          code={item?.code}
                          name={item?.name}
                        >
                          <div className="options">
                            <p>{item?.code}</p>
                            <p className="elipse-width options-description">
                              {item?.name}
                            </p>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      className="input-style"
                      disabled
                      placeholder="Select Dealership Name"
                    />
                  )}
                </Form.Item>
              </Col> */}
              <Col className="gutter-row" span={8}>
                <Title className="customer-id summary-dropdown" level={5}>
                  Dealer Type
                </Title>
                <Form.Item>
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    value={formData.dealerType}
                    onChange={(value) => handleonChange("dealerType", value)}
                    onSearch={(value) => handleSearch("dealerType", value)}
                    onDropdownVisibleChange={(open) =>
                      handleDropdownVisibleChange("dealerType", open)
                    }
                    suffixIcon={
                      searchState.dealerType.onSearch ? (
                        <SearchOutlined className="dropdown-icon" />
                      ) : (
                        <CaretDownOutlined className="dropdown-icon" />
                      )
                    }
                    placeholder="Select Dealer Type"
                  >
                    {dealerTypeList?.map((item) => (
                      <Select.Option key={item.id} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Title className="customer-id summary-dropdown" level={5}>
                  Quote Status
                </Title>
                <Form.Item>
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    value={formData.quoteStatus}
                    onChange={(value) => handleonChange("quoteStatus", value)}
                    onSearch={(value) => handleSearch("quoteStatus", value)}
                    onDropdownVisibleChange={(open) =>
                      handleDropdownVisibleChange("quoteStatus", open)
                    }
                    suffixIcon={
                      searchState.quoteStatus.onSearch ? (
                        <SearchOutlined className="dropdown-icon" />
                      ) : (
                        <CaretDownOutlined className="dropdown-icon" />
                      )
                    }
                    placeholder="Select Quote Status"
                  >
                    {quoteStatusValue.map((item) => (
                      <Select.Option key={item.id} value={item.name}>
                        {item.name}{" "}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Title className="customer-id" level={5}>
                  Date Range
                </Title>
                <Form.Item className="summary-report">
                  <RangeDateRangePicker
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                    handleChange={handleChange}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="hr-container">
            <div className="quotes-all-list-container">
              <CustomizedButton
                className="reset-btn"
                onClick={handleClearFilter}
              >
                Reset
              </CustomizedButton>
              <CustomizedButton
                type="primary"
                className={
                  isAnyDropdownSelected ? "apply-btn" : "apply-btn-disabled"
                }
                onClick={handleFilterNewReport}
              >
                Apply
              </CustomizedButton>
            </div>
            <hr className="hr-line" />
          </div>
          <div>
            <Title className="quote-items all-quote-list" level={4}></Title>
            <div className="total-container reports-container">
              <div className="total-text">
                Total:
                <span className="item">
                  {totalRecords > 0 && totalRecords < 10
                    ? `0${totalRecords}`
                    : totalRecords}{" "}
                  item
                  {totalRecords > 1 ? "s" : ""}
                </span>
              </div>
              <div className="apply-btn-container">
                <CustomizedButton
                  className="export-button export-file "
                  type="Outlined"
                  icon={ExportIcon}
                  onClick={handleClickExport}
                >
                  Export File
                </CustomizedButton>
              </div>
            </div>
            <div className="table-container">
              <CommonTable
                dataSource={quoteConversionList}
                columns={columns.map((column) => ({
                  ...column,
                  onHeaderCell: () => ({
                    onClick: () => handleColumnSort(column),
                  }),
                  sortOrder: columnSortDirections[column.dataIndex],
                }))}
                scrollable={false}
              />
              {/* {totalRecords > 15 && ( */}
                <div className="quote-pagination">
                  <Pagination
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChange}
                    total={totalRecords}
                    className="custom-pagination"
                    current={currentPage}
                    {...paginationConfig}
                  />
                </div>
              {/* )} */}
            </div>
            <CommonModal
              id=":rh:"
              visible={exportModalVisible}
              onCancel={onCancelModal}
              onConfirm={handleConfirmExport}
              title={
                <>
                  <img
                    className="infocircle-quoteconversion"
                    src={infoCircle}
                  />{" "}
                  Export Report
                </>
              }
              showExportButton={exportModalVisible}
            >
              <p className="export-message info-messages-quoteconversion">
                Export Quote Conversion Report in XLSX format
              </p>
            </CommonModal>
          </div>
          <RegionComponent />
        </div>
      </div>
    </div>
  );
};
export default QuoteConversion;
