import React, { useEffect } from 'react';
import QuoteGenerator from './quoteGenerator';

const ViewQuote = ({ setSelectedKey }) => {

  // useEffect(() => {
  //   setSelectedKey("2");
  // }, [setSelectedKey]);

  return (
    <>
      <QuoteGenerator view={true} />
    </>
  );
};
export default ViewQuote;
