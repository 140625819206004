import moment from "moment";

export const convertExcelDateToJSDate = (serial) => {
  // Excel's base date is 01-01-1900, we need to add the serial to this base date
  const excelStartDate = new Date(1900, 0, 1); // January 1, 1900
  const jsDate = new Date(excelStartDate.getTime() + (serial - 1) * 86400000); // 86400000 = milliseconds in one day
  const day = String(jsDate.getDate()).padStart(2, "0"); // pad with leading zero if necessary
  const month = String(jsDate.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-indexed
  const year = jsDate.getFullYear();
  
  // Return in DD-MM-YYYY format
  return `${day}-${month}-${year}`;
};


export const monthYear = (dateString) => {
const [month, year] = dateString.split(" ");
const formattedDate = moment([year, month - 1]).format('MMM YYYY');
return formattedDate
}

export const monthConverter = (dateString) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthIndex = dateString - 1;

  return monthIndex >= 0 && monthIndex < 12 ? monthNames[monthIndex] : "Unknown";
};
