import "../../Assets/Styles/fonts.css";
import React, { useEffect, useState } from "react";
import { Typography, Select, Form, Col, Row, Input, Space, Modal } from "antd";
import "../../Assets/Styles/global-styles.css";
import CustomizedButton from "../../Components/Button";
import { customerInformation } from "../../Services/userEndpoints";
import { useContext } from "react";
import { QuoteContext } from "../Quote-Calculator/quoteContext";
import {
  GetCustomerApprovalStatus,
  GetCustomerTypes,
  GetCustomerUnapprovalReason,
} from "../../Constants/commonRegion";
import { webService } from "../../Services/web.service";
const { Title } = Typography;
const { Option } = Select;
const RebateManagementEditt = ({
  isModalVisible,
  setIsModalVisible,
  handleModalCancel,
  editingRecord,
  updateValues,
  dmsId,
  rebateDetail,
}) => {
  const [form] = Form.useForm();
  const { approvalStatus, unApprovalStatus, customerType } =
    useContext(QuoteContext);
  const [approvalId, setApprovalId] = useState(null);
  const [reasonId, setReasonId] = useState(null);
  const [formValues, setFormValues] = useState({
    customerName: "",
    email: "",
    contactNumber: "",
    businessName: "",
    customerType: "",
    approvalStatus: "",
    reason: "",
  });
  useEffect(() => {
    if (dmsId) {
      getFormData();
    }
  }, [dmsId]);

  const getFormData = async () => {
    try {
      webService
        .get(`${customerInformation}/${dmsId}`)
        .then((response) => {
          const responseData = response.data;
          const selectedStatusObj = approvalStatus.find(
            (item) =>
              parseInt(item.id) === parseInt(responseData.approvalStatus)
          );
          const selectedReaconObj = unApprovalStatus.find(
            (item) => parseInt(item.id) === parseInt(responseData.reason)
          );
          const selectedCustomerTypeObj = customerType.find(
            (item) => parseInt(item.id) === parseInt(responseData.customerType)
          );
          form.setFieldsValue({
            customerName: responseData?.name || "",
            email: responseData?.email || "",
            contactNumber: responseData?.contact || "",
            businessName: responseData?.businessName || "",
            customerType: responseData?.customerType || "",
            approvalStatus: selectedStatusObj ? selectedStatusObj?.status : "",
            reason: selectedReaconObj ? selectedReaconObj?.reason : "",
          });
          setApprovalId(responseData?.approvalStatus);
          setReasonId(responseData?.reason);
        })
        .catch(() => {});
    } catch (error) {
      console.log("Error :", error);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const findIdByStatusAndReason = (data, value) => {
    for (let item of data) {
      if (item.status === value) {
        return item.id;
      } else if (item.reason === value) {
        return item.id;
      }
    }
    return null;
  };

  const handleSubmit = (values) => {
    let payload = {};
    if (approvalId === null || reasonId || null) {
      const selectedStatusObj = findIdByStatusAndReason(
        approvalStatus,
        values.approvalStatus
      );
      const selectedReaconObj = findIdByStatusAndReason(
        unApprovalStatus,
        values.reason
      );
      payload = {
        name: values.customerName,
        email: values.email,
        contact: values.contactNumber,
        approvalStatus: selectedStatusObj,
        reason: selectedReaconObj,
        businessName: values.businessName,
        customerType: values.customerType
      };
    } else {
      payload = {
        name: values.customerName,
        email: values.email,
        contact: values.contactNumber,
        approvalStatus: approvalId,
        reason: reasonId,
        businessName: values.businessName,
        customerType: values.customerType
      };
    }
    updateValues({ ...editingRecord, ...payload });
    setIsModalVisible(false);
  };
  const handleChange = (key, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  return (
    <>
      <Modal
        title={
          <>
            Edit Customer Information
            <div
              style={{ borderBottom: "1px solid #e8e8e8", margin: "16px 0" }}
            />
          </>
        }
        centered
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={750}
        className="no-border-radius-modal"
      >
        <div>
          <Form form={form} onFinish={handleSubmit} className="custom-no-margin">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12}>
                <Title className="customer-id summary-dropdown">
                  Customer Name
                </Title>
                <Form.Item
                  className="custom-no-margin"
                  name="customerName"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Input
                    showSearch
                    // value={selectedRecordData.customerName}
                    value={formValues.customerName}
                    onBlur={() => {
                      form.validateFields(["customerName"]);
                    }}
                    onChange={(e) =>
                      handleChange("customerName", e.target.value)
                    }
                    className="custom-select custom-placeholder-text"
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Title className="customer-id summary-dropdown" level={1}>
                  Customer Email ID
                </Title>
                <Form.Item
                  className="custom-no-margin"
                  name="email"
                  rules={[
                    { required: true, message: "This field is required." },
                    { type: "email", message: "Please enter a valid email" },
                  ]}
                >
                  <Input
                    showSearch
                    value={formValues.email}
                    onBlur={() => {
                      form.validateFields(["email"]);
                    }}
                    onChange={(e) => handleChange("email", e.target.value)}
                    // value={selectedRecordData.email}
                    className="custom-select custom-placeholder-text"
                    style={{ borderRadius: "0px" }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Title className="customer-id summary-dropdown" level={1}>
                  Customer Contact Number
                </Title>
                <Form.Item
                  className="custom-no-margin"
                  name="contactNumber"
                  rules={[
                    { required: true, message: "This field is required." },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Contact number must be numeric",
                    },
                  ]}
                >
                  <Input
                    showSearch
                    // value={selectedRecordData.contactNumber}
                    value={formValues.contactNumber}
                    onBlur={() => {
                      form.validateFields(["contactNumber"]);
                    }}
                    onChange={(e) =>
                      handleChange("contactNumber", e.target.value)
                    }
                    className="custom-select custom-placeholder-text"
                    style={{ borderRadius: "0px" }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Title className="customer-id summary-dropdown" level={1}>
                  Business Name
                </Title>
                <Form.Item
                  className="custom-no-margin"
                  name="businessName"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Input
                    showSearch
                    // value={selectedRecordData.businessName}
                    value={formValues.businessName}
                    onBlur={() => {
                      form.validateFields(["businessName"]);
                    }}
                    onChange={(e) =>
                      handleChange("businessName", e.target.value)
                    }
                    className="custom-select custom-placeholder-text"
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Title className="customer-id summary-dropdown" level={1}>
                  Customer Type
                </Title>
                <Form.Item name="customerType"
                className="custom-no-margin"
                >
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    // defaultValue={selectedRecordData.ApprovalStatus}
                    value={formValues.customerType}
                    onBlur={() => {
                      form.validateFields(["customerType"]);
                    }}
                    onChange={(value) => handleChange("customerType", value)}
                  >
                    <Option value="Trade" key="trade">
                      Trade
                    </Option>
                    <Option value="Non Trade" key="non-trade">
                      Non Trade
                    </Option>
                  </Select>
                  {/* <Input
                    showSearch
                    disabled
                    // value={selectedRecordData.customerType}
                    value={formValues.customerType}
                    className="custom-select custom-placeholder-text"
                    style={{ borderRadius: "0px", color: "black" }}
                  /> */}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Title className="customer-id summary-dropdown" level={1}>
                  Approval Status
                </Title>
                <Form.Item
                  className="custom-no-margin"
                  name="approvalStatus"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    // defaultValue={selectedRecordData.ApprovalStatus}
                    value={formValues.approvalStatus}
                    onBlur={() => {
                      form.validateFields(["approvalStatus"]);
                    }}
                    onChange={(value) => handleChange("approvalStatus", value)}
                  >
                    {approvalStatus.map((item) => {
                      return (
                        <Option value={item.status} key={item.id}>
                          {item.status}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Title className="customer-id summary-dropdown" level={1}>
                  Reason
                </Title>
                <Form.Item
                  className="custom-no-margin"
                  name="reason"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    showSearch
                    className="custom-select custom-placeholder-text"
                    // defaultValue={selectedRecordData.reason}
                    onBlur={() => {
                      form.validateFields(["reason"]);
                    }}
                    value={formValues.reason}
                    onChange={(value) => handleChange("reason", value)}
                  >
                    {unApprovalStatus.map((item) => {
                      return (
                        <Option value={item.reason} key={item.id}>
                          {item.reason}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <hr className="hr-line" />
            <Row className="rebate-edit-modal">
              <Col
                className="gutter-row new-footer-buttons rebate-edit-modal"
                span={8}
              >
                <div>
                  <Space>
                    <CustomizedButton
                      className="reset-btn"
                      onClick={handleCancel}
                    >
                      CANCEL
                    </CustomizedButton>
                    <CustomizedButton
                      type="primary"
                      htmlType="submit"
                      className="addpart-btn apply-btn"
                    >
                      UPDATE
                    </CustomizedButton>
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {!rebateDetail ? (
        <>
          <GetCustomerUnapprovalReason />
          <GetCustomerApprovalStatus />
          <GetCustomerTypes />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default RebateManagementEditt;
