import React from "react";
import "../../Assets/Styles/global-styles.css";
import { Card, Typography } from "antd";
import rebateCalculaterSvg from "../../Assets/Icons/Quote Calculator.svg";
import rebateReportDetailedIllustration from "../../Assets/Icons/Parts Rebate Detailed Report.svg";
import managePartsRebateCustomers from "../../Assets/Icons/Manage Parts Rebate Customers.svg";
import homeRebateSummary from "../../Assets/Icons/homeRebateSummary.svg";
import homeDealerPayments from "../../Assets/Icons/homeDealerPayments.svg";
import homeQuoteConversion from "../../Assets/Icons/homeQuoteConversion.svg";
import homePartsRebateDetails from "../../Assets/Icons/homePartsRebateDetails.svg";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import "../../Assets/Styles/fonts.css";
import useGetUser from "../../shared/hooks/useGetUser";
const { Title } = Typography;

const mainCardlabels = [
  {
    id: 1,
    description:
      "Create and manage parts rebate basket",
    label: "Rebate Management",
    icon: rebateCalculaterSvg,
    route: "/rebate/management",
  },
  {
    id: 2,
    description:
      "Every trade sale is recorded at the part number level, including and excluding rebates",
    label: "Manage Parts Rebate Customers",
    icon: rebateReportDetailedIllustration,
    route: "/rebate/management/customer",
  },
  {
    id: 3,
    description:
      "Display a list of customers who are approved or rejected for the parts rebate",
    label: "Manage Dealer Rebate Allowance",
    icon: managePartsRebateCustomers,
    route: "/rebate/allowance",
  },
];

const ReportCardlabels = [
  {
    id: 1,
    label: "Parts Rebate Summary",
    icon: homeRebateSummary,
    route: "/reporting/parts/rebate/summary",
  },
  {
    id: 2,
    label: "Parts Rebate Details",
    icon: homePartsRebateDetails,
    route: "/reporting/parts/rebate/detail",
  },
  {
    id: 3,
    label: "Dealer Payments",
    icon: homeDealerPayments,
    route: "/reporting/dealer/payments",
  },
  {
    id: 4,
    label: "Quote Conversion",
    icon: homeQuoteConversion,
    route: "/reporting/quote/conversion",
  },
];

const AdminHome = (props) => {

  const {user} = useGetUser();

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="name-container">
        <Title level={4} className="name-header">
          Hi, {user?.name || 'User'}
        </Title>
        <p className="name-info">Welcome to Dealer Parts Portal</p>
      </div>
      <div>
        <Row
          gutter={[14, 14]}
          className="row-gutter"
          style={{ marginTop: "20px" }}
        >
          {mainCardlabels.map((eachObject) => (
            <Col xs={24} sm={8} lg={8} xl={8} key={eachObject.id}>
              <Link to={eachObject.route}>
                <Card className="card-container">
                  <div>
                    <h2 className="card-title">{eachObject.label}</h2>
                    <p className="cards-description">
                      {eachObject.description}
                    </p>
                  </div>
                  <img src={eachObject.icon} alt="icon" className="card-img" />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <Row gutter={[12, 12]} justify="start">
          <div className="report-container">
            <div>
              <h1 className="report-heading">Reporting</h1>
            </div>
            <div className="report-card-container">
              {ReportCardlabels.map((eachObject) => (
                <Col key={eachObject.id}>
                  <Link to={eachObject.route}>
                    <div className="report-card">
                      <img
                        src={eachObject.icon}
                        alt="icon"
                        className="report-icons char-icon-color"
                      />
                      <div>
                        <h2 className="report-card-heading">
                          {eachObject.label}
                        </h2>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default AdminHome;
