import React, { useEffect, useState } from "react";
import "./style.css";
import { Modal, Pagination, Table } from "antd";
import CustomizedButton from "../../Button";
import { CloseOutlined } from "@ant-design/icons";
import CommonMessage from "../../Message";
import { loadFileData } from "../../../Constants/fileLoader";

const ImportRebate = ({
  isVisible,
  handleClose,
  file,
  handleGetImportedRecords,
}) => {
  const [filename, setFilename] = useState(null);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const handleTransformToCamelCase = (inputString) => {
    switch (inputString) {
      case "Year":
        return "allowanceYear";
      case "Month":
        return "allowanceMonth";
      case "Dealer Code":
        return "code";
      case "Dealership Name":
        return "name";
      case "Rebate Allowance":
        return "rebateAllowance";
      case "Additional Allowance":
        return "additionalRebateAllowance";
      case "Total Allowance":
        return "additionalRebateAllowance";
      default:
        return inputString
          .toLowerCase()
          .replace(/\s+(.)/g, function (match, group) {
            return group.toUpperCase();
          });
    }
  };
  const loadFile = async (file) => {
    const expectedHeaders = [
      "Year",
      "Month",
      "Dealer Code",
      "Dealership Name",
      "Rebate Allowance",
      "Additional Allowance",
      "Total Allowance",
    ];

    try {
      const { filename, columns, dataSource } = await loadFileData(
        file,
        expectedHeaders,
        handleTransformToCamelCase
      );
      setFilename(filename);
      setColumns(columns);
      setDataSource(dataSource);
    } catch (error) {
      CommonMessage("error", error.message);
    }
  };

  const handleImport = () => {
    console.log(dataSource);
    handleGetImportedRecords(dataSource);
    // CommonMessage("success", "Allowance File Imported successfully.");
    closeModal();
  };

  const closeModal = () => {
    setDataSource([]);
    handleClose();
  };

  useEffect(() => {
    if (file) {
      loadFile(file);
    }
  }, [file]);
  const onChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const startIndex = currentPage;
  const endIndex = startIndex + pageSize;
  const tableData = dataSource.slice(startIndex, endIndex);
  return (
    <Modal
      visible={isVisible}
      className="import-modal"
      closable={false}
      footer={[
        <span className="footer">
          <CustomizedButton className="reset-btn" onClick={closeModal}>
            Cancel
          </CustomizedButton>
          <CustomizedButton
            type="primary"
            className="import-btn"
            onClick={handleImport}
          >
            Import
          </CustomizedButton>
        </span>,
      ]}
    >
      <div className="header">
        <h3>{filename}</h3>
        <span className="close-icon">
          <CloseOutlined className="close-icon" onClick={closeModal} />
        </span>
      </div>
      <div className="content ">
        <div className="modal-container">
          <Table dataSource={tableData} columns={columns} pagination={false} />
        </div>
      </div>
      <div className="parts-pagination">
        <Pagination
          defaultPageSize={15}
          pageSizeOptions={["15", "25", "50", "100"]}
          showSizeChanger={true}
          onChange={onChange}
          total={dataSource.length}
          defaultCurrent={1}
          className="custom-pagination"
        />
      </div>
    </Modal>
  );
};
export default ImportRebate;
