import React, { useEffect, useState } from "react";
import { Modal, Pagination, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import CustomizedButton from "../../Components/Button";
import CommonMessage from "../../Components/Message";
import "../../Assets/Styles/global-styles.css";
import "../../Components/Modal/ImportRebate/style.css";
import { loadFileData } from "../../Constants/fileLoader";
import moment from "moment";
import { format } from "date-fns";
import { convertExcelDateToJSDate } from "../../Constants/dateConverter";

const ImportFiles = ({
  isVisible,
  handleClose,
  file,
  handleGetImportedRecords,
}) => {
  const [filename, setFilename] = useState(null);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const handleTransformToCamelCase = (inputString) => {
    switch (inputString) {
      case "Part Number":
        return "partNumber";
      case "Effective Date":
        return "effectiveDate";
      case "Expiry Date":
        return "expiryDate";
      case "Base Price":
        return "basePrice";
      case "Stock Price":
        return "stockPrice";
      case "Max Rebate Price":
        return "maxRebatePrice";
      case "Handeling Fee STD":
        return "handelingFeeStd";
      case "Handeling Fee WST":
        return "handelingFeeWst";
      default:
        return inputString
          .toLowerCase()
          .replace(/\s+(.)/g, function (match, group) {
            return group.toUpperCase();
          });
    }
  };
  const loadFile = async (file) => {
    const expectedHeaders = [
      "Part Number",
      "Effective Date",
      "Expiry Date",
      "Base Price",
      "Stock Price",
      "Max Rebate Price",
      "Handeling Fee STD",
      "Handeling Fee WST"
    ];
    try {
      const fixedColumns = false;
      const { filename, columns, dataSource } = await loadFileData(
        file,
        expectedHeaders,
        handleTransformToCamelCase,
        fixedColumns
      );
      setFilename(filename);
      setColumns(columns);
      setDataSource(dataSource);
    } catch (error) {
      CommonMessage("error", error.message);
    }
  };
  const handleImport = () => {
    handleGetImportedRecords(dataSource);
    closeModal();
  };

  useEffect(() => {
    if (file) {
      loadFile(file);
    }
  }, [file]);
  const closeModal = () => {
    handleClose();
  };
  const onChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const startIndex = currentPage -1;
  const endIndex = startIndex + pageSize;
  const tableData = dataSource
  .slice(startIndex, endIndex)
  .map((record) => {
    const effDate = convertExcelDateToJSDate(record.effectiveDate);
    return {
      ...record,
      effectiveDate: effDate,
      expiryDate: record.expiryDate.toLowerCase() === "current" ? "31-12-9999" : record.expiryDate,
    };
  });

  return (
    <>
      <Modal
        open={isVisible}
        className="import-modal"
        closable={false}
        footer={[
          <span className="footer">
            <CustomizedButton className="reset-btn" onClick={closeModal}>
              Cancel
            </CustomizedButton>
            <CustomizedButton
              type="primary"
              className="import-btn"
              onClick={handleImport}
            >
              Import
            </CustomizedButton>
          </span>,
        ]}
      >
        <div className="header">
          <h3>{filename}</h3>
          <span className="close-icon">
            <CloseOutlined className="close-icon" onClick={closeModal} />
          </span>
        </div>
        <div className="content ">
          <div className="modal-container">
            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
              style={{ maxWidth: "77vw" }}
              scroll={{ x: 1000 }}
            />
          </div>
        </div>
        <div className="parts-pagination">
          <Pagination
            defaultPageSize={15}
            pageSizeOptions={["15", "25", "50", "100"]}
            showSizeChanger={true}
            onChange={onChange}
            total={dataSource.length}
            defaultCurrent={1}
            className="custom-pagination"
          />
        </div>
      </Modal>
    </>
  );
};
export default ImportFiles;
