import axios from "axios";
import { message } from "../Components/Message";
import { cleanObject } from "../Constants/constants";
import { auth } from "./auth.service";
import store from "../store/store";
import { isExecuting } from "../store/isHTTPRequestSlice";
import { useEffect } from "react";

export let BASE_URL = process.env.REACT_APP_API_URL;

export const webService = {
  get: async (route, params = undefined) => {
    return webService.execute("GET", route, undefined, params);
  },

  post: async (route, data = undefined, params = undefined) => {
    return webService.execute("POST", route, data, params);
  },

  put: async (route, data = undefined, params = undefined) => {
    return webService.execute("PUT", route, data, params);
  },

  patch: async (route, data = undefined, params = undefined) => {
    return webService.execute("PATCH", route, data, params);
  },

  destroy: async (route, data = undefined, params = undefined) => {
    return webService.execute("DELETE", route, data, params);
  },

  execute: async (method, url, data = undefined, params = undefined) => {

    const accessToken = auth.getToken();

    // Configure the request
    let config = {
      method,
      url: `${BASE_URL}/${url}`,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    };

    if (params) {
      config.params = cleanObject(params);
    }

    if (data) {
      config.data = cleanObject(data);
    }

    // store.dispatch(isExecuting(true));

    return axios(config)
      .then((response) => response.data) // Extract data from response
      .catch((error) => {
        console.error("API call error:", error); // Improved error logging
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        }
        if (
          error?.response?.data?.errors &&
          error?.response?.data?.errors?.length > 0
        ) {
          for (const errorMessage of error?.response?.data?.errors) {
            message.error(errorMessage);
          }
        }
        return Promise.reject(error); // Pass the error to the caller
      })
      .finally(() => {
        // store.dispatch(isExecuting(false));
      });
  },
};
