import { Outlet } from "react-router-dom";
import { useIsAdmin } from "../shared/hooks/role";

const IsAdmin = () => {

  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    return <></>
  }
  return <Outlet />;
};

export default IsAdmin;
