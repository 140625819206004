import React, { useState } from "react";
import "../../Assets/Styles/global-styles.css";
import CommonTable from "../../Components/Table";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import CustomizedButton from "../../Components/Button";
import { Tag, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { paginationConfig } from "../../Components/Pagination";
import "../../Assets/Styles/fonts.css";
import { exportQuotesApi } from "../../Constants/utils";
import { formatDate } from "../../Constants/constants";
import { sortDirections } from "../../Constants/commonObject";
import { formatCurrency } from "../../Constants/calculations";

const QuoteStatus = {
  1: "pending-clr",
  2: "approved-clr",
  3: "delete-clr",
  4: "invoiced-color",
  5: "lostsale-clr",
};
const AllQuoteList = ({
  dataSource,
  quoteStatus,
  onPageChange,
  currentPage,
  getAllList,
  onSort,
  totalRecords,
}) => {
  const [columnSortDirections, setColumnSortDirections] = useState({});
  const navigate = useNavigate();
  const { differenceInDays, isToday, isPast, addDays } = require("date-fns");

  const handleColumnSort = (column) => {
    if (["quote_Status", "action"].includes(column.dataIndex)) {
      return;
    }
    const currentSortDirection = columnSortDirections[column.dataIndex] || null;
    const nextSortDirection =
      currentSortDirection === "ascend"
        ? "descend"
        : currentSortDirection === "descend"
        ? null
        : "ascend";
    const newColumnSortDirections = {
      ...columnSortDirections,
      [column.dataIndex]: nextSortDirection,
    };
    setColumnSortDirections(newColumnSortDirections);
    nextSortDirection === null
      ? getAllList()
      : onSort(column.dataIndex, sortDirections[nextSortDirection]);
  };

  function getQuoteStatusClassName(quoteStatusId) {
    return QuoteStatus[quoteStatusId] || "";
  }

  const handleViewIconClick = (record) => {
    const { quoteNumber, quote_Status, id } = record;
    const quoteStatusName =
      quoteStatus.find((status) => status.id === quote_Status)?.name ||
      "Unknown";
    navigate(`/quote/view/${record.id}`, {
      state: {
        quoteNumber: quoteNumber,
        quoteStatusName: quoteStatusName,
        quoteId: id,
      },
    });
  };

  const handleExportQuotesItems = async (record) => {
    try {
      const response = await exportQuotesApi(record.id);
      console.log(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function getRemainingTime(dueDate) {
    const currentDate = new Date();
    let daysDifference;

    if (isToday(dueDate)) {
      return {
        message: "Expiring Today",
        className: "display-center-all oneday-due",
      };
    } else if (isPast(dueDate)) {
      return {
        message: "Expired",
        className: "display-center-all expired",
      };
    } else {
      daysDifference = differenceInDays(addDays(dueDate, 1), currentDate);
      let className;
      if (daysDifference === 1) {
        className = "display-center-all oneday-due";
      } else if (daysDifference === 2) {
        className = "display-center-all twodays-due";
      } else if (daysDifference === 3) {
        className = "display-center-all threedays-due";
      } else {
        className = "display-center-all green-color";
      }
      return {
        message: `${daysDifference} day${daysDifference === 1 ? "" : "s"} left`,
        className: className,
      };
    }
  }

  const columns = [
    {
      title: "Quote Number",
      dataIndex: "quoteNumber",
      key: "quoteNumber",
      align: "left",
      sorter: true,
      width: "156px",
      className: "table-sorter font",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      align: "left",
      sorter: true,
      width: "190px",
      className: "table-sorter font",
    },
    {
      title: "DMS ID",
      dataIndex: "customerId_FK",
      key: "customerId_FK",
      align: "left",
      sorter: true,
      width: "190px",
      className: "table-sorter font",
    },
    {
      title: "Rebate Amount",
      dataIndex: "rebateAmount",
      key: "rebateAmount",
      align: "right",
      sorter: true,
      width: "160px",
      className: "table-sorter font",
      render: formatCurrency,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      align: "right",
      sorter: true,
      width: "206px",
      className: "table-sorter font",
      render: (text, record) => {
        if (!record.dueDate) {
          return "-";
        }
        const formattedDueDate = record.dueDate
          ? formatDate(record.dueDate)
          : "-";
        const remainingTimeInfo = record.dueDate
          ? getRemainingTime(record.dueDate)
          : { message: null, className: "" };
        return (
          <div style={{ display: "flex" }}>
            <Tag bordered className="display-duedate-tag">
              <span className={remainingTimeInfo.className}>
                {remainingTimeInfo.message}
              </span>
            </Tag>
            <div>{formattedDueDate}</div>
          </div>
        );
      },
    },
    {
      title: "Quote Creator",
      dataIndex: "createdBy",
      key: "createdBy",
      align: "left",
      sorter: true,
      width: "152px",
      className: "table-sorter font",
    },
    {
      title: "Quote Status",
      key: "quote_Status",
      dataIndex: "quote_Status",
      align: "center",
      width: "116px",
      render: (text, record) => {
        const getStatusName = (statusId) => {
          const status = quoteStatus.find((status) => status.id === statusId);
          return status ? status.name : "Unknown";
        };
        const quoteStatusId = record.quote_Status;
        const quoteStatusName = getStatusName(quoteStatusId);
        return (
          <div>
            <Tag
              bordered
              className={`display-all-status ${getQuoteStatusClassName(
                quoteStatusId
              )}`}
            >
              <span className="display-center-all"> {quoteStatusName}</span>
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "92px",
      render: (text, record) => (
        <span style={{ whiteSpace: "nowrap" }}>
          <CustomizedButton
            type="danger"
            icon={<EyeOutlined className="quote-edit-btn quote-edit-mar" />}
            onClick={() => handleViewIconClick(record)}
          ></CustomizedButton>
          <CustomizedButton
            type="danger"
            icon={
              <DownloadOutlined className="quote-edit-btn quote-delete-mar" />
            }
            onClick={() => handleExportQuotesItems(record)}
          ></CustomizedButton>
        </span>
      ),
    },
  ];
  const onShowSizeChange = (current, pageSize) => {
    onPageChange(current, pageSize);
  };

  const onChange = (page, pageSize) => {
    onPageChange(page, pageSize);
  };

  return (
    <>
      {Array.isArray(dataSource) && dataSource.length >= 0 ? (
        <CommonTable
          columns={columns.map((column) => ({
            ...column,
            onHeaderCell: () => ({ onClick: () => handleColumnSort(column) }),
            sortOrder: columnSortDirections[column.dataIndex],
          }))}
          dataSource={dataSource.map((item) => ({
            ...item,
            key: item.id,
            quoteNumber: item.quoteNumber ? item.quoteNumber : "-",
            customerName: item.customer ? item.customer.name : "-",
            customerId_FK: item.customer ? item.customer.id : "-",
            rebateAmount: item.rebateAmount ? item.rebateAmount : "-",
            createdBy: item.createdBy ? item.createdBy.name : "-",
            quote_Status: item.quoteStatus ? item.quoteStatus : "-",
          }))}
          className="all-quote-table"
        />
      ) : (
        <p>No data to display</p>
      )}
      {/* {totalRecords > paginationConfig?.defaultPageSize && ( */}
        <div className="quote-pagination">
          <Pagination
            onShowSizeChange={onShowSizeChange}
            onChange={onChange}
            total={totalRecords}
            className="custom-pagination"
            current={currentPage}
            {...paginationConfig}
          />
        </div>
      {/* )} */}
    </>
  );
};
export default AllQuoteList;
