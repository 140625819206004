export const customerUnApprovalStatusList = [
  {
    id: 1,
    reason: "By default",
  },
  {
    id: 2,
    reason: "No reason yet",
  },
  {
    id: 3,
    reason: "No ABN provided",
  },
  {
    id: 4,
    reason: "Not an approved smash repair",
  },
  {
    id: 5,
    reason: "Details are incorrect",
  },
  {
    id: 6,
    reason: "Other reasons",
  },
];

export const customerStatusList = [
  {
    id: "0",
    status: "Pending",
  },
  {
    id: "1",
    status: "Approved",
  },
  {
    id: "2",
    status: "Unapproved",
  },
];
