import { Navigate, Outlet } from "react-router-dom";
import { auth } from "../Services/auth.service";
// Please remove this comment

const AuthRoute = () => {
  if (!auth.check()) {
    return <Navigate to="/unauthorized" />;
  }
  return <Outlet />;
};

export default AuthRoute;
