import { Outlet } from "react-router-dom";
import { useIsTradeDealer } from "../shared/hooks/role";

const IsTradeDealer = () => {

  const isTradeDealer = useIsTradeDealer();

  if (!isTradeDealer) {
    return <></>
  }
  return <Outlet />;
};

export default IsTradeDealer;
