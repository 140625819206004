import React from "react";
import { Modal } from "antd";
import CustomizedButton from "../Button";
import "../../Assets/Styles/global-styles.css";

const CommonModal = ({
  title,
  visible,
  onCancel,
  onConfirm,
  children,
  showExportButton,
  showDiscardButton,
  showBackButton,
  showSaveButton,
  showUpdateButton,
  showUnable,
  showDeleteButton,
}) => {
  const isPrimaryType =
    showExportButton ||
    showDiscardButton ||
    showUpdateButton ||
    showSaveButton ||
    showDeleteButton;
  return (
    <Modal
      title={title}
      className={` ${
        showUnable
          ? "unable-delete-modal custom-modal centered-modal"
          : "custom-modal centered-modal"
      } `}
      open={visible}
      onCancel={onCancel}
      closable={false}
      okButtonProps={{ style: { display: "none" } }}
      footer={[
        <CustomizedButton
          key="cancel"
          type={showBackButton && "primary"}
          onClick={onCancel}
          className={
            showBackButton
              ? "apply-btn export-btn discard-btn"
              : showUnable
              ? "unable-cancel-hide"
              : "modal-cancel-btn"
          }
        >
          {showBackButton ? "No" : " Cancel"}
        </CustomizedButton>,
        <CustomizedButton
          key="update"
          type={(isPrimaryType || showUnable) && "primary"}
          onClick={onConfirm}
          className={`${
            showUnable
              ? "unable-confirm apply-btn export-btn discard-btn"
              : isPrimaryType
              ? "apply-btn export-btn discard-btn"
              : "modal-cancel-btn"
          }`}
        >
          {showExportButton
            ? "Export"
            : showDiscardButton
            ? "Discard"
            : showBackButton
            ? "Yes"
            : showUpdateButton
            ? "Update"
            : showSaveButton
            ? "Save"
            : showUnable
            ? "Ok"
            : "Delete"}
        </CustomizedButton>,
      ]}
    >
      {children}
    </Modal>
  );
};
export default CommonModal;
