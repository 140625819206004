import React, { useEffect, useState } from "react";
import { Table, Modal, Row, Col } from "antd";
import "../../Assets/Styles/fonts.css";
import "../../Assets/Styles/global-styles.css";
import Export from "../../Assets/Images/Download.svg";
import CustomizedButton from "../../Components/Button";
import {
  rebateCustomerInvoices,
  rebateCustomerInvoiceExport,
} from "../../Services/userEndpoints";
import { message } from "../../Components/Message";
import CommonModal from "../../Components/Modal";
import { saveAs } from "file-saver";
import { webService } from "../../Services/web.service";

const RebateManagementView = ({
  isViewModalVisible,
  handleViewModalCancel,
  modalCustomerId,
}) => {
  const [customerDetails, setCustomerDetails] = useState({});
  const [businessDetails, setBusinessDetails] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [quotePartsDatails, setQuotePartsDetails] = useState([]);
  const [invoiceDatasource, setInvoiceDatasource] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState();
  const quotePartsColumns = [
    {
      title: "#",
      dataIndex: "sNo",
      key: "sNo",
      align: "center",
      width: "9px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Parts Name",
      dataIndex: "description",
      key: "description",
      align: "left",
      width: "200px",
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "Quoted Price",
      dataIndex: "requiredSellPrice",
      key: "requiredSellPrice",
      align: "center",
      render: (text) => `$${text}`,
    },
    {
      title: "Total Quoted Price",
      dataIndex: "totalQuotedPrice",
      key: "totalQuotedPrice",
      align: "center",
      render: (text) => `$${text}`,
    },

    {
      title: "Total Rebate",
      dataIndex: "totalRebate",
      key: "totalRebate",
      align: "center",
      render: (text) => `$${text}`,
    },
  ];
  const invoiceColumns = [
    {
      title: "Invoice Date",
      dataIndex: "invoicedDate",
      key: "invoicedDate",
      align: "center",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      align: "center",
    },
    {
      title: "Total Rebate",
      dataIndex: "totalRebate",
      key: "totalRebate",
      align: "center",
      render: (text) => `$${text}`,
    },
  ];
  useEffect(() => {
    if (modalCustomerId) {
      getCustomerModalList();
    }
  }, [isViewModalVisible]);


  const getCustomerModalList = async () => {
    try {
      webService
        .get(`${rebateCustomerInvoices}/${modalCustomerId}`)
        .then((response) => {
          const responseData = response?.data || [];
          const customerDetailsData = responseData?.customer || {};
          const invoicesDetailsData = responseData?.invoices || [];

          setCustomerDetails(customerDetailsData);
          setInvoiceDetails(invoicesDetailsData);
          const processedInvoices = [];

          invoicesDetailsData.forEach(invoice => {
            
            const totalRebate = invoice.partsInvoiceItems.reduce(
              (sum, item) => sum + parseFloat(item.rebate),
              0
            );

            //process Invoice Data
            processedInvoices.push({
              invoicedDate: formatDate(invoice.invoiceDate),
              invoiceNumber: invoice.invoiceNumber,
              totalRebate: totalRebate.toFixed(2),
            });
          });

          // const quoteParts = invoicesDetailsData[0].quoteParts.map((part) => {
          //   return {
          //     description: part.part.description,
          //     quantity: part.quantity,
          //     requiredSellPrice: part.requiredSellPrice,
          //     totalQuotedPrice:
          //       parseInt(part.quantity) * parseInt(part.requiredSellPrice),
          //     totalRebate: part.totalRebate,
          //   };
          // });
          // setQuotePartsDetails(quoteParts);
          setInvoiceDatasource(processedInvoices);
          // setInvoiceDate(formatDate(invoicesDetailsData[0].invoicedDate));
        })
        .catch(() => {
          setCustomerDetails({});
          setQuotePartsDetails([]);
        });
    } catch (error) {
      console.log("Error View Invoices:", error);
    }
  };
  const handleClickExport = () => {
    setExportModalVisible(true);
  };
  const onCancelModal = () => {
    setExportModalVisible(false);
  };
  const formatDate = (isoString) => {
    if (!isoString) return "-";
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const onInvoiceRowClick = (record, index) => {
    const invoiceNumber = record?.invoiceNumber;
    const selectedQuotePartes = invoiceDetails[index]?.partsInvoiceItems;
    const processedQuoteParts = selectedQuotePartes.map((item) => {
      return {
        description: item?.part?.description,
        quantity: item?.quantity,
        requiredSellPrice: item?.unitSalePrice,
        totalQuotedPrice:
          parseInt(item?.quantity) * parseInt(item?.unitSalePrice),
        totalRebate: item?.rebate,
      };
    });
    setSelectedRowIndex(index);
    setInvoiceDate(record?.invoicedDate);
    setQuotePartsDetails(processedQuoteParts);
    setSelectedInvoiceNumber(invoiceNumber);
  };

  //export api
  const handleConfirmExportReports = async () => {
    try {
      webService
        .get(`${rebateCustomerInvoiceExport}/${selectedInvoiceNumber}`)
        .then((response) => {
          const { fileName, buffer } = response.data;
          const arrayBuffer = new Uint8Array(buffer.data).buffer;
          const blob = new Blob([arrayBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, fileName);
          message.success(`Invoice exported.`);
          setExportModalVisible(false);
        });
    } catch (error) {
      console.error("Error exporting quote:", error);
      throw error;
    }
  };

  useEffect(() => {
    if(invoiceDatasource.length > 0) {
      onInvoiceRowClick(invoiceDatasource[0], 0);
    }else {
      setSelectedRowIndex(null);
      setInvoiceDate(null);
      setQuotePartsDetails(null);
      setSelectedInvoiceNumber(null);
    }
  }, [invoiceDatasource])

  return (
    <>
      <Modal
        title={
          <>
            View Invoices
            <div
              style={{ borderBottom: "1px solid #e8e8e8", margin: "16px 0" }}
            />
          </>
        }
        centered
        visible={isViewModalVisible}
        onCancel={handleViewModalCancel}
        footer={null}
        className="modal-border"
      >
        <div>
          <Row gutter={16}>
            <Col span={10}>
              <div className="container">
                <div className="names-container-another">
                  <div style={{width:"120px"}}>
                    <span className="name-heading">Customer Name</span>
                    <br />
                    <span className="name-sub-heading">
                      {" "}
                      {customerDetails.name || "-"}
                    </span>
                  </div>
                  <div >
                    <span className="name-heading">DMS ID</span>
                    <br />
                    <span className="name-sub-heading">
                      {" "}
                      {customerDetails.id || "-"}
                    </span>
                  </div>
                </div>
                <div className="names-container-another">
                  <div style={{width:"120px"}}>
                    <span className="name-heading">Customer ABN</span>
                    <br />
                    <span className="name-sub-heading">
                      {" "}
                      {customerDetails.ABN || "-"}
                    </span>
                  </div>
                  <div >
                    <span className="name-heading">Business Name</span>
                    <br />
                    <span className="name-sub-heading">
                      {customerDetails.businessName || "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="text">
                Total:
                <span className="item">{`${invoiceDatasource.length} Invoices`}</span>
              </div>
              <Table
                columns={invoiceColumns}
                dataSource={invoiceDatasource}
                pagination={false}
                scroll={{
                  y: 240,
                }}
                onRow={(record, index) => ({
                  onClick: () => onInvoiceRowClick(record, index),
                  className: selectedRowIndex === index ? "selected-row" : "",
                })}
                className="custom-table custom-scrollbar"
              />
            </Col>

            <Col span={14}>
              <div>
                <div className="invoice-container">
                  <div>
                    <div className="sub-total-container">
                      <div className="total-export-container ">
                        <div className="total-text">
                          Invoice Date:
                          <span className="item">{invoiceDate}</span>
                        </div>
                        <div className="apply-btn-container">
                          {
                            invoiceDate &&
                            <CustomizedButton
                            // invoiceDatasource.length
                            // className={
                            //   rebateRetailList?.length > 0
                            //     ? "import-file-btn export-button summary-exportbtn"
                            //     : "apply-btn-disabled summary-exportbtn"
                            // }
                              className="import-file-btn export-button"
                              type="Outlined"
                              icon={Export}
                              onClick={handleClickExport}
                            >
                              Export
                            </CustomizedButton>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rebate-container">
                    <div className="rebate-sub-container">
                      <Table
                        dataSource={quotePartsDatails}
                        columns={quotePartsColumns}
                        pagination={false}
                        style={{ margin: "15px" }}
                        className="custom-header"
                        summary={(pageData) => {
                          let totalUnitPrice = 0;
                          let totalRebates = 0;
                          let totalQty = 0;
                          let quotedPrice = 0;
                          pageData.forEach(
                            ({
                              requiredSellPrice,
                              totalQuotedPrice,
                              totalRebate,
                              quantity,
                            }) => {
                              totalUnitPrice =
                                parseInt(requiredSellPrice) +
                                parseInt(totalUnitPrice);
                              totalRebates =
                                parseInt(totalRebate) + parseInt(totalRebates);
                              totalQty =
                                parseInt(quantity) + parseInt(totalQty);
                              quotedPrice =
                                parseInt(totalQuotedPrice) +
                                parseInt(quotedPrice);
                            }
                          );
                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} />
                                <Table.Summary.Cell index={1}>
                                  <strong>Grand Total</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>
                                  <strong className="invoices-grand-total" >
                                    {totalQty}
                                  </strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} >
                                  <strong className="invoices-grand-total">{`$${totalUnitPrice}`}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} >
                                  <strong className="invoices-grand-total">
                                    {`$${quotedPrice}`}
                                  </strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} >
                                  <strong className="invoices-grand-total">{`$${totalRebates}`}</strong>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <CommonModal
                    id=":rh:"
                    visible={exportModalVisible}
                    onCancel={onCancelModal}
                    onConfirm={handleConfirmExportReports}
                    title="Export Invoice"
                    showExportButton={exportModalVisible}
                  >
                    <p className="export-message">
                      Export the selected invoice in CSV format
                    </p>
                  </CommonModal>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default RebateManagementView;
