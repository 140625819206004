import moment from "moment/moment";

export const columns = [
  {
    title: "Dealer Code",
    dataIndex: "dealerCode",
    key: "dealerCode",
    align: "left",
    width: "212px",
    className: "table-sorter font",
    render: (value, row) => {
      return row?.dealership?.code;
    },
  },
  {
    title: "Dealership Name",
    dataIndex: "dealershipName",
    key: "dealershipName",
    align: "left",
    width: "212px",
    className: "table-sorter font",
    render: (value, row) => {
      return row?.dealership?.name;
    },
  },
  {
    title: "Month",
    dataIndex: "month",
    key: "month",
    align: "left",
    width: "212px",
    className: "table-sorter font",
    render: (value, row) => {
      return moment()
        .month(row?.allowanceMonth - 1)
        .format("MMMM");
    },
  },
  {
    title: "Year",
    dataIndex: "allowanceYear",
    key: "allowanceYear",
    align: "left",
    width: "212px",
    className: "table-sorter font",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: "left",
    width: "212px",
    className: "table-sorter font",
  },
  {
    title: "Receipt ID",
    dataIndex: "receiptId",
    key: "receiptId",
    align: "left",
    width: "212px",
    className: "table-sorter font",
  },
];
