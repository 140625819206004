import React from "react";
import "./style.scss";
import Sidebar from "../../Layouts/Sidebar";
// Please remove this comment

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <Sidebar />
    </div>
  );
};

export default Dashboard;
