const usePartsRebateCustomerTableColumns = () => {
  const columns = [
    {
      title: "Dealer Code",
      key: "dealershipCode",
      isDefault: true,
      isSelected: true,
    },
    {
      title: "Dealership Name",
      key: "dealershipName",
      isDefault: true,
      isSelected: true,
    },
    {
      title: "Customer Name",
      key: "customerName",
      isDefault: true,
      isSelected: true,
    },
    {
      title: "DMS ID",
      key: "DMSId",
      isDefault: true,
      isSelected: true,
    },
    {
      title: "Business Name",
      key: "businessName",
      isDefault: true,
      isSelected: true,
    },
// Not Default
    {
      title: "Business ABN",
      key: "businessABN",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Customer Type",
      key: "customerType",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Approval Status",
      key: "approvalStatus",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Reason",
      key: "reason",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Contact",
      key: "contact",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Email",
      key: "email",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Invoice Count",
      key: "invoiceCount",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Total Rebate ($)",
      key: "totalRebate",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Total Sales ($)",
      key: "totalSales",
      isDefault: false,
      isSelected: true,
    },
    {
      title: "Date Updated",
      key: "dateUpdated",
      isDefault: false,
      isSelected: true,
    },
  ];

  let defaultColumns = [];
  let additionalColumns = [];

  columns.forEach((column) => {
    if (column.isDefault) {
      defaultColumns.push(column);
    } else {
      additionalColumns.push(column);
    }
  });

  let selectedTableColumns = [];

  columns.forEach((column) => {
    if (column.isSelected && !column.isDefault) {
      selectedTableColumns.push(column.key);
    }
  });

  return [defaultColumns, additionalColumns, selectedTableColumns];
};

export default usePartsRebateCustomerTableColumns;
