import React, { useState, useEffect, useRef } from "react";
import CustomizedButton from "../../Components/Button";
import { useLocation } from "react-router-dom";
import { Typography, Input, Form, Col, Select, Row, Space } from "antd";
import "../../Assets/Styles/global-styles.css";
import Upload from "../../Assets/Images/Upload.svg";
import ArrowLeft from "../../Assets/Images/leftArrowIcon.svg";
import { useNavigate } from "react-router-dom";
import "../../Assets/Styles/fonts.css";
import ImportParts from "../../Components/Modal/ImportParts/ImportParts";
import CustomBreadcrumb from "../../Components/Breadcrumb";
import AddPart from "./addPart";
import EditableTable from "./editableTable";
import { useDispatch, useSelector } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import {
  add,
  remove,
  removeAll,
  edit,
  insertBulk,
} from "../../store/quoteItemsSlice";
import CommonModal from "../../Components/Modal";
import CommonMessage from "../../Components/Message";
import CustomerSelect from "../../Components/CustomerSelect/CustomerSelect";
import CustomerIDSelect from "../../Components/CustomerSelect/CustomerIDSelect";
import { useIsAdmin } from "../../shared/hooks/role";
import DealerSelect from "../../Components/DealerSelect/DealerSelect";
import useGetUser from "../../shared/hooks/useGetUser";

const { Title } = Typography;
const { Option } = Select;

const CreateNewQuote = () => {
  const {dealer} = useGetUser()
  const isAdmin = useIsAdmin();
  const quoteItemsStore = useSelector((state) => state.quoteItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const location = useLocation();
  const { customerFilters } = location.state || {};
  const [isImportPartsModalVisible, setIsImportPartsModalVisible] =
    useState(false);
  const [importedFile, setImportedFile] = useState(null);
  const [quoteItemsData, setquoteItemsData] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [discardModalVisible, setDiscardModalVisible] = useState(false);
  const [customerSingleRecord, setCustomerSingleRecord] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [formData, setFormData] = useState({
    customerId: undefined,
    dealercode: undefined,
    quoteNumber: undefined,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [customerForm] = Form.useForm();

  const handleGeneratePage = () => {
    navigate("/quote/review", {
      state: {
        quoteItemsData,
        customerSingleRecord,
        selectedCustomerID: formData.customerId,
        quoteFormData: formData,
      },
    });
  };

  const handleAddPartModalData = ({ values, selectedPart }) => {
    dispatch(add({ data: values, selectedPart }));
  };

  useEffect(() => {
    setquoteItemsData(quoteItemsStore);
  }, [quoteItemsStore]);

  const handleEditQuoteItem = (editedData, index) => {
    dispatch(edit({ data: editedData, index }));
  };

  const handleonChange = (fieldName, value) => {
    const data = {
      ...formData,
      [fieldName]: value,
    };

    if (fieldName === "customerId") {
      const customer = customerList.find((record) => record.id == value);
      if (customer) {
        data["selectedCustomer"] = customer;
        setCustomerSingleRecord(customer);
      }
    }

    setFormData(data);
    // Trigger form validation for both 'name' and 'customerId' fields
    customerForm.setFieldsValue({
      name: data.customerId,
      customerId: data.customerId,
      dealershipId: data.dealershipId,
    });
    customerForm.validateFields(["name", "customerId"]).catch((errorInfo) => {
      console.log("Validation Failed:", errorInfo);
    });
    setFormData(data);
  };

  const validateForm = () => {
    if (formData?.customerId === undefined) {
      setErrorMsg("This field is required");
    } else {
      setErrorMsg("");
    }
  };

  const handleDeleteRow = (index) => {
    dispatch(remove(index));
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome to show the warning
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleImportFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setImportedFile(e.target.files[0]);
    setIsImportPartsModalVisible(true);
  };

  const handleHideImportPartsModal = () => {
    setIsImportPartsModalVisible(false);
    fileInputRef.current.value = "";
  };

  const handleGetImportedRecords = (data) => {
    dispatch(insertBulk(data));
  };
  const handleClickLeftArrow = () => {
    if (quoteItemsStore.length > 0) {
      setDiscardModalVisible(true);
    } else {
      navigate("/quote/list");
    }
  };
  //breadcrumb
  const items = [
    {
      title: (
        <span className="bread-crumb-main" onClick={handleClickLeftArrow}>
          All Quotes List
        </span>
      ),
    },
    {
      title: <span className="bread-crumb-currentpage">Create New Quote</span>,
    },
  ];

  const success = (message) => {
    CommonMessage(
      "success",
      message,
      2,
      () => {
        setShowSuccess(false);
      },
      "custom-success-message"
    );
  };

  const handleConfirmDiscard = () => {
    dispatch(removeAll());
    setDiscardModalVisible(false);
    success("Quote discarded ");
    setTimeout(() => {
      setDiscardModalVisible(false);
      navigate("/quote/list");
    }, 1000);
  };

  const onCancelModal = () => {
    setDiscardModalVisible(false);
  };

  return (
    <div>
      <div className="quote-container create-quote">
        <span>
          <CustomBreadcrumb items={items} />
          <div className="headerforButtons">
            <Space className="header-buttons-export-save" size={16}>
              <img
                className="left-arrow"
                src={ArrowLeft}
                alt="Left Arrow"
                onClick={handleClickLeftArrow}
              />
              <span className="quote-genrate-number">Quote Calculator</span>
            </Space>
          </div>
        </span>
        <div className="generate-container">
          <CustomizedButton
            type="primary"
            className={
              quoteItemsData?.length > 0 &&
              formData.customerId &&
              (isAdmin ? formData.dealershipId : true)
                ? "generate-btn"
                : "generate-btn-disabled"
            }
            onClick={formData.customerId ? handleGeneratePage : undefined}
          >
            Generate
          </CustomizedButton>
        </div>
      </div>
      <div className="main-container create-new-quote-container">
        <div className="box">
          <Title className="general-info" level={5}>
            CUSTOMER INFORMATION
          </Title>
          <div className="row-col-container">
            <Form form={customerForm}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={6}>
                  {isAdmin ? (
                    <>
                      <Title className="dealer-code" level={5}>
                        Dealer <span className="required-star">*</span>
                      </Title>
                      <Form.Item
                        name="dealership"
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <DealerSelect
                          name="dealership"
                          form={customerForm}
                          handleSelectChange={(value) =>
                            handleonChange("dealershipId", value)
                          }
                          selectedValue={formData.dealershipId}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Title className="dealer-code" level={5}>
                        Dealer Code
                      </Title>
                      <Form.Item>
                        <Input
                          className="input-style"
                          disabled
                          value = {dealer.dlCode}
                          placeholder="Dealer Code"
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col className="gutter-row" span={6}>
                  {!isAdmin || formData.dealershipId ? (
                    <>
                      <Title className="customer-id" level={5}>
                        Customer Name <span className="required-star">*</span>
                      </Title>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <CustomerSelect
                          name="name"
                          form={customerForm}
                          records={customerList}
                          setCustomerList={setCustomerList}
                          handleSelectChange={(value) =>
                            handleonChange("customerId", value)
                          }
                          selectedValue={formData.customerId}
                          dealershipId={formData.dealershipId}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Title className="customer-id" level={5}>
                        Customer Name <span className="required-star">*</span>
                      </Title>
                      <Form.Item>
                        <Input
                          className="input-style"
                          disabled
                          placeholder="Customer Name"
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col className="gutter-row" span={6}>
                  {!isAdmin || formData.dealershipId ? (
                    <>
                      <Title className="customer-id" level={5}>
                        DMS ID<span className="required-star">*</span>
                      </Title>
                      <Form.Item
                        name={"customerId"}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <CustomerIDSelect
                          form={customerForm}
                          name={"customerId"}
                          records={customerList}
                          setCustomerList={setCustomerList}
                          handleSelectChange={(value) =>
                            handleonChange("customerId", value)
                          }
                          selectedValue={formData.customerId}
                          dealershipId={formData.dealershipId}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Title className="customer-id" level={5}>
                        DMS Id <span className="required-star">*</span>
                      </Title>
                      <Form.Item>
                        <Input
                          className="input-style"
                          disabled
                          placeholder="DMS Id"
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
          <div className="hr-container">
            <hr className="hr-line" />
          </div>

          <AddPart
            onAddPartData={handleAddPartModalData}
            validateForm={validateForm}
            customerData={formData}
          />

          <div className="hr-container">
            <hr className="hr-line" />
          </div>

          <div>
            <Title className="quote-items all-quote-list" level={4}>
              QUOTE ITEMS
            </Title>
            <div className="total-container">
              <div className="total-text">
                Total:
                <span className="item">
                  {quoteItemsData?.length} item
                  {quoteItemsData.length >= 2 ? "s" : ""}
                </span>
              </div>
              <div className="apply-btn-container">
                <CustomizedButton
                  className="import-file-btn"
                  icon={Upload}
                  onClick={handleImportFile}
                >
                  Import File
                </CustomizedButton>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".xlsx,.xls"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="table-container">
              <EditableTable
                onAddPartData={handleAddPartModalData}
                quoteItemsData={quoteItemsData}
                onDeleteRow={handleDeleteRow}
                onEditQuoteItem={handleEditQuoteItem}
              />
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        id=":rh:"
        visible={discardModalVisible}
        onCancel={onCancelModal}
        onConfirm={handleConfirmDiscard}
        title="Discard Quote"
        showDiscardButton={discardModalVisible}
      >
        <p className="export-message">Would you like to discard the Quote.</p>
      </CommonModal>
      <ImportParts
        isVisible={isImportPartsModalVisible}
        handleClose={handleHideImportPartsModal}
        file={importedFile}
        handleGetImportedRecords={handleGetImportedRecords}
      />
      {showSuccess && <CommonMessage type="success" />}
    </div>
  );
};
export default CreateNewQuote;
