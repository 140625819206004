import { useEffect, useState } from "react";
import { webService } from "../../../Services/web.service";
import moment from "moment";
import store from "../../../store/store";
import { isExecuting } from "../../../store/isHTTPRequestSlice";

const useGetPartsRebateDetails = (initialFilters = {}) => {
  const [partsRebateDetails, setPartsRebateDetails] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const fetch = () => {
    store.dispatch(isExecuting(true));
    webService
      .post("reporting/parts/rebate-detail", filters)
      .then((response) => {
        setPartsRebateDetails(response);
      }).finally(()=>{
        store.dispatch(isExecuting(false));
      })
  };

  const setListFilters = (data) => {
    // if (data.dates) {
    //   data.monthYearStart = moment(new Date(data.dates[0])).format("MM/YYYY");
    //   data.monthYearEnd = moment(new Date(data.dates[1])).format("MM/YYYY");
    // } else {
    //   data.monthYearStart = data.monthYearEnd = undefined;
    // }

    setFilters(data);
  };

  useEffect(() => {
    setPartsRebateDetails(null);
    if(filters.loadRecords) {
      fetch();
    }
  }, [filters]);

  return { partsRebateDetails, setListFilters };
};

export default useGetPartsRebateDetails;
