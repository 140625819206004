import { useEffect, useState } from "react";
import {
  commonSorter,
  render,
  renderPercentage,
  } from "../../../Constants/constants";
import {absolute, formatCurrency} from "../../../Constants/calculations"
import { monthYear } from "../../../Constants/dateConverter";

const useGetPartsRebateSummaryTableColumns = (selectedTableColumns = [],rebateDetail) => {
  const [sortedInfo, setSortedInfo] = useState({});
  
  const additionalColumns = [
    {
      title: "Total Rebate Sales",
      dataIndex: "totalRebateSales",
      key: "totalRebateSales",
      align: "right",
      width: "141px",
      sorter: commonSorter("totalRebateSales"),
      sortOrder: sortedInfo.columnKey === 'totalRebateSales' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.totalRebateSales));
      },
    },
    {
      title: "Quantity Sold",
      dataIndex: "quantitySold",
      key: "quantitySold",
      align: "right",
      width: "114px",
      sorter: commonSorter("quantitySold"),
      sortOrder: sortedInfo.columnKey === 'quantitySold' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: render,
    },
    {
      title: "Dealer Cost",
      dataIndex: "dealerCost",
      key: "dealerCost",
      align: "right",
      width: "130px",
      sorter: commonSorter("dealerCost"),
      sortOrder: sortedInfo.columnKey === 'dealerCost' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.dealerCost));
      },
    },
    {
      title: "Gross Profit",
      dataIndex: "grossProfit",
      key: "grossProfit",
      align: "right",
      width: "136px",
      sorter: commonSorter("grossProfit"),
      sortOrder: sortedInfo.columnKey === 'grossProfit' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.grossProfit));
      },
    },
    {
      title: "Gross Profit %",
      dataIndex: "grossProfitPercentage",
      key: "grossProfitPercentage",
      align: "right",
      width: "136px",
      sorter: commonSorter("grossProfitPercentage"),
      sortOrder: sortedInfo.columnKey === 'grossProfitPercentage' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return renderPercentage(absolute(record.grossProfitPercentage));
      },
    },
    {
      title: "Monthly Allowance",
      dataIndex: "monthlyAllowance",
      key: "monthlyAllowance",
      align: "center",
      width: "114px",
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.monthlyAllowance));
      },
    },
    {
      title: "Rebate Usage ($)",
      dataIndex: "rebateUsage",
      key: "rebateUsage",
      align: "right",
      width: "124px",
      sorter: commonSorter("rebateUsage"),
      sortOrder: sortedInfo.columnKey === 'rebateUsage' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.rebateUsage));
      },
    },
    {
      title: "Unused Allowance",
      dataIndex: "unusedAllowance",
      key: "unusedAllowance",
      align: "right",
      width: "124px",
      sorter: commonSorter("unusedAllowance"),
      sortOrder: sortedInfo.columnKey === 'unusedAllowance' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.unusedAllowance));
      },
    },
    {
      title: "Rebate Paid Amount",
      dataIndex: "rebatePaidAmount",
      key: "rebatePaidAmount",
      align: "right",
      width: "126px",
      sorter: commonSorter("rebatePaidAmount"),
      sortOrder: sortedInfo.columnKey === 'rebatePaidAmount' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.rebatePaidAmount));
      },
    },
    {
      title: "GP Recovery",
      dataIndex: "gpRecovery",
      key: "gpRecovery",
      align: "right",
      width: "111px",
      sorter: commonSorter("gpRecovery"),
      sortOrder: sortedInfo.columnKey === 'gpRecovery' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.gpRecovery));
      },
    },
    {
      title: "Handling Fee",
      dataIndex: "handlingFee",
      key: "handlingFee",
      align: "right",
      width: "111px",
      sorter: commonSorter("handlingFee"),
      sortOrder: sortedInfo.columnKey === 'handlingFee' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.handlingFee));
      },
    },
  ];

  const defaultColumns = [
    {
      title: "Month",
      dataIndex: "monthYear",
      key: "month",
      align: "left",
      width: "122px",
      fixed: "left",
      sorter: commonSorter("month"),
      
      className: "table-sorter font",
      render: (e, record) => {
        return render(monthYear(record.monthYear))
      },
    },
    ...(!rebateDetail ? [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        key: "dealerCode",
        align: "left",
        width: "95px",
        fixed: "left",
        sorter: commonSorter("dealerCode"),
        sortOrder: sortedInfo.columnKey === 'dealerCode' ? sortedInfo.order : null,
        className: "table-sorter font",
        render: render,
      },
      {
        title: "Dealership Name",
        dataIndex: "dealershipName",
        key: "dealershipName",
        align: "left",
        width: "176px",
        fixed: "left",
        sorter: commonSorter("dealershipName"),
        sortOrder: sortedInfo.columnKey === 'dealershipName' ? sortedInfo.order : null,
        className: "table-sorter font",
        render: render,
      },
    ]:[]),
    {
      title: "Working Days",
      dataIndex: "workingDays",
      key: "workingDays",
      align: "left",
      width: "112px",
      fixed: "left",
      sorter: commonSorter("workingDays"),
      sortOrder: sortedInfo.columnKey === 'workingDays' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: render,
    },
    {
      title: "Working Days %",
      dataIndex: "workingDaysPercentage",
      key: "workingDaysPercentage",
      align: "left",
      width: "87px",
      fixed: "left",
      className: "table-sorter font",
      render: renderPercentage,
    },
    {
      title: "Total Sales (Quoted Price)",
      dataIndex: "totalSales",
      key: "totalSales",
      align: "right",
      width: "149px",
      fixed: "left",
      sorter: commonSorter("totalSales"),
      sortOrder: sortedInfo.columnKey === 'totalSales' ? sortedInfo.order : null,
      className: "table-sorter font",
      render: (e, record) => {
        return formatCurrency(absolute(record.totalSales));
      },
    },
  ];

  const [columns, setColumns] = useState([...defaultColumns,...additionalColumns]);

  const setSelectedTableColumns = (updatedColumns = []) => {
    const values = [
      ...defaultColumns,
      ...additionalColumns.filter((addColumn) =>
        updatedColumns.includes(addColumn.key)
      ),
    ];

    setColumns(values);
  };

  useEffect(() => {
    console.log('sort', sortedInfo);
    
  }, [sortedInfo])

  return { columns, setSelectedTableColumns, setSortedInfo };
};

export default useGetPartsRebateSummaryTableColumns;
