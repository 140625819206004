import React, { useMemo } from "react";
import "../../Assets/Styles/global-styles.css";
import { Card, Typography } from "antd";
import rebateCalculaterSvg from "../../Assets/Icons/Quote Calculator.svg";
import partsrebateSummarySvg from "../../Assets/Icons/Parts Rebate Summary.svg";
import rebateReportDetailedIllustration from "../../Assets/Icons/Parts Rebate Detailed Report.svg";
import managePartsRebateCustomers from "../../Assets/Icons/Manage Parts Rebate Customers.svg";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import "../../Assets/Styles/fonts.css";
import { useIsTradeDealer } from "../../shared/hooks/role";
import useGetUser from "../../shared/hooks/useGetUser";

const { Title } = Typography;

const HomePage = () => {
  const isTradeDealer = useIsTradeDealer();
  const {user} = useGetUser();

  const homeCardsData = useMemo(
    () => [
      {
        id: 0,
        label: "Quote Calculator ",
        description:
          "Create and review quotes for trade customers by  manually entering data or importing a file.",
        route: "/quote/list",
        icon: rebateCalculaterSvg,
      },
      ...(isTradeDealer
        ? [
            {
              id: 1,
              label: "Parts Rebate Summary",
              description:
                "A summary report of Trade Parts Sales, Trade Program Sales, Rebate Usage, and Allowances for all dealers",
              route: "/parts/rebate/summary",
              icon: partsrebateSummarySvg,
            },
          ]
        : []),
    ],
    [isTradeDealer]
  );

  const cardsData = useMemo(() => [
    ...(isTradeDealer
      ? [
          {
            id: 0,
            label: "Parts Rebate Detailed Report",
            description:
              "Every trade sale is recorded at the part number level, including and excluding rebates",
            route: "/parts/rebate/detail",
            icon: rebateReportDetailedIllustration,
          },
          {
            id: 1,
            label: "Manage Parts Rebate Customers",
            description:
              "Display a list of customers who are approved or rejected for the parts rebate",
            route: "/parts/rebate/customer",
            icon: managePartsRebateCustomers,
          },
        ]
      : []),
  ]);

  return (
    <div>
      <div className="name-container">
        <Title level={4} className="name-header">
          Hi, {user?.name || 'User'}
        </Title>
        <p className="name-info">Welcome to Dealer Parts Portal</p>
      </div>

      <div style={{ padding: "0px 6px" }}>
        <Row gutter={[12, 12]} className="row-gutter">
          {homeCardsData.map((eachObject) => (
            <Col xs={24} sm={12} lg={12} xl={12} key={eachObject.id}>
              <Link to={eachObject.route}>
                <Card className="config-container">
                  <div>
                    <h2 className="cards-label">{eachObject.label}</h2>
                    <p className="config-cards-description">
                      {eachObject.description}
                    </p>
                  </div>
                  <img
                    src={eachObject.icon}
                    alt="icon"
                    className="style-icons"
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <Row
          gutter={[12, 12]}
          justify="space-between"
          style={{ marginTop: "12px", cursor: "pointer" }}
        >
          {cardsData.map((eachObject) => (
            <Col xs={24} sm={12} lg={12} xl={12} key={eachObject.id}>
              <Link to={eachObject.route}>
                <Card className="config-container">
                  <div>
                    <h2 className="cards-label">{eachObject.label}</h2>
                    <p className="config-cards-description">
                      {eachObject.description}
                    </p>
                  </div>
                  <img
                    src={eachObject.icon}
                    alt="icon"
                    className="style-icons"
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default HomePage;
