import { useContext, useEffect, useState } from "react";
import { webService } from "../../../Services/web.service";
import store from "../../../store/store";
import { isExecuting } from "../../../store/isHTTPRequestSlice";
import { rebateCustomersApi } from "../../../Services/userEndpoints";
import { QuoteContext } from "../../../Pages/Quote-Calculator/quoteContext";

const useGetPartsRebateCustomer = (initialFilters = {}, rebateDetail) => {
  const [partsRebateDetails, setPartsRebateDetails] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const {
    setTotalInvoices,
    setRebateCustomer,
    setManagepartTotalInvoice,
    setManagepartTotalRebate,
  } = useContext(QuoteContext);


  const fetch = () => {
    store.dispatch(isExecuting(true));
    webService
      .post(rebateCustomersApi, filters)
      .then((response) => {
        const totalInvoices = response?.data?.totalInvoices;
        const totalRebates = response?.data?.totalRebateSales;
        // setTotalInvoices(totalInvoices);
        // setRebateCustomer(totalRebates);
        setManagepartTotalRebate(
          totalRebates ? parseFloat(totalRebates).toFixed(2) : "-"
          );
          setManagepartTotalInvoice(
            totalInvoices ? totalInvoices : "-"
          );
        setPartsRebateDetails(response);
      }).finally(()=>{
        store.dispatch(isExecuting(false));
      })
  };

  const setListFilters = (data) => {
    const updatedData = {
      ...data,
      ...(!rebateDetail ? { customerStatus: parseInt(data.customerStatus, 10) } : {}),
    };
    setFilters(updatedData);
  };

  useEffect(() => {
    setPartsRebateDetails(null);
    if(filters.loadRecords) {
      fetch();
    }
  }, [filters]);

  return { partsRebateDetails, setListFilters };
};

export default useGetPartsRebateCustomer;
